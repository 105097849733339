import React, {useEffect, useState} from "react";
import AdvisorSignupStep1 from "./AdvisorSignupStep1";
import AdvisorSignupStep2 from "./AdvisorSignupStep2";
import AdvisorSignupStep3 from "./AdvisorSignupStep3";
import AdvisorSignupStep4 from "./AdvisorSignupStep4";
import { useNavigate } from "react-router-dom";
import AdvisorSignupConfirm from "./AdvisorSignupConfirm";
import { useDispatch } from "react-redux";
import { removeChat } from "../../../Store/Reducer/chatReducer";
import { removeAlarms } from "../../../Store/Reducer/alarmReducer";

const AdvisorSignupStep = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const signup_aId = localStorage.getItem('signup_aId');
    const signup_email = localStorage.getItem('signup_email');

    if(!signup_aId || !signup_email) {
        navigate('/');
    }
    
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        personalData: {
            legalname: '',
            mobile: '',
            birth: '',
            country: '',
            state: '',
            city: '',
            address:'',
            zipcode: ''
        },
        skillServiceData: {
            username: '',
            avatar: null,
            rate_per_min_chat: 0,
            aboutme: '',
            service_name: '',
            service_description: '',
        },
        videoData: {
            new_video: null
        }
    })

    useEffect(()=>{
        dispatch(removeChat());
        dispatch(removeAlarms());
        
        if(!localStorage.getItem('signup_aId') || !localStorage.getItem('signup_email')) {
            navigate('/');
        }
    }, [])

    const onNext = (data) => {
        processStepData(data);
        setStep(step + 1);
    }
    const onPrev = (data) => {
        processStepData(data);
        setStep(step - 1);
    }

    const processStepData = (data) => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

        if(step == 2) {
            setFormData({
                ...formData,
                personalData: {...data}
            })
        } else if(step == 3) {
            setFormData({
                ...formData,
                skillServiceData: {...data}
            })
        } else if(step == 4) {
            setFormData({
                ...formData,
                videoData: {...data}
            })
        }
    }

    return <section className="ad-auth-signup-step">
    {step == 1 && <AdvisorSignupStep1 onNext={onNext} />}
    {step == 2 && <AdvisorSignupStep2 data={formData.personalData} onNext={onNext} onPrev={onPrev} />}
    {step == 3 && <AdvisorSignupStep3 data={formData.skillServiceData} onNext={onNext} onPrev={onPrev} />}
    {step == 4 && <AdvisorSignupStep4 data={formData.videoData} onNext={onNext} onPrev={onPrev} />}
    {step == 5 && <AdvisorSignupConfirm data={formData} onPrev={onPrev} /> }
    </section>
}

export default AdvisorSignupStep;
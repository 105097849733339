import React, { useEffect, useState } from "react";
import AdminTable from "../../../Components/AdminTable/AdminTable";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { MDBIcon, MDBInput, MDBSpinner } from "mdb-react-ui-kit";
import Paginator from "../../../Components/Paginator/Paginator";
import { toast } from "react-toastify";
import { getAvatarUrl, text2html } from "../../../Utils/utils";
import Moment from "react-moment";
import styles from './Review.scss';
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const AdminReviewList = React.memo((props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [removing, setRemoving] = useState(false);
    const [reviews, setReviews] = useState([]);
    const [searchClient, setSearchClient] = useState('');
    const [searchAdvisor, setSearchAdvisor] = useState('');
    const [formData, setFormData] = useState({
        searchClient: '',
        searchAdvisor: '',
        perPage: 10,
        pageNum: 1
    });
    const perPageOpts = [
        { value: 10,label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" }
    ];
    const [curPerPageOpt, setCurPerPageOpt] = useState(perPageOpts[0]);
    const [total, setTotal] = useState(0);
    const columns = [
        {name: 'date', title: 'Date'},
        {name: 'advisor', title: 'Advisor'},
        {name: 'client', title: 'Client'},
        {name: 'type', title: 'Type'},
        {name: 'content', title: 'Content'},
        {name: 'action', title: 'Action'}
    ];

    useEffect(()=>{
        getReviews();
    }, [formData]);

    const getReviews = () => {
        setLoading(true);

        ApiService.post('/admin/review/index', formData).then(result=>{
            setReviews(result.data.reviews);
            setTotal(result.data.total);
        })
        .finally(()=>{
            setLoading(false);
        })
    }
    const onSearchClientChange = (e) => {
        setSearchClient(e.target.value);
    }
    const onSearchAdvisorChange = (e) => {
        setSearchAdvisor(e.target.value);
    }
    const onSearchBtnClicked = (e) => {
        e.preventDefault();
        setFormData({
            ...formData,
            searchClient,
            searchAdvisor,
            pageNum: 1
        })
    }
    const onSearchClientKeyPress = (e) => {
        if(e.key == 'Enter') {
            e.preventDefault();
            setFormData({
                ...formData,
                pageNum: 1,
                searchClient
            });
        }
    }
    const onSearchAdvisorKeyPress = (e) => {
        if(e.key == 'Enter') {
            e.preventDefault();
            setFormData({
                ...formData,
                pageNum: 1,
                searchAdvisor
            });
        }
    }
    const onPageChanged = (pageNum) => {
        setFormData({
            ...formData,
            pageNum
        })
    }
    const onPerPageChanged = (opt) => {
        setFormData({
            ...formData,
            perPage: opt.value,
            pageNum: 1
        })
        setCurPerPageOpt(opt);
    }
    const handleEditReview = (e, review) => {
        e.preventDefault();
        navigate('/admin/review/edit/' + review._id)
    }
    const handleDeleteReview = (e, review) => {
        e.preventDefault();
        if(window.confirm('Are you sure to delete?') == false) return;

        setRemoving(true);
        ApiService.get('/admin/review/remove/' + review._id).then(result=>{
            getReviews();
        })
        .finally(()=>{
            setRemoving(false);
        })
    }

    const breadcrumb=[{title: 'Review'}, {title: 'List'}];
    return (<>
        <Breadcrumb breadcrumb={breadcrumb} />

        <div className="admin-reviews-table">
            <div className="d-flex">
                <div className="px-2">
                    <label>Count : </label>
                    <span>{total}</span>
                </div>
                <div className="flex-fluid d-flex justify-content-end mb-2">
                    <div className="d-flex align-items-center">
                        <div className="me-2">
                            <Select className="per-page"
                                        options={perPageOpts} 
                                        onChange={(opt) => onPerPageChanged(opt)} 
                                        defaultValue={curPerPageOpt}
                            />
                        </div>
                        <div className="me-2">
                            <MDBInput   type='text' 
                                        label='Search Client' 
                                        value={searchClient}
                                        onChange={onSearchClientChange}
                                        onKeyPress={onSearchClientKeyPress}
                            />
                        </div>
                        <div className="me-2">
                            <MDBInput   type='text' 
                                        label='Search Advisor' 
                                        value={searchAdvisor}
                                        onChange={onSearchAdvisorChange}
                                        onKeyPress={onSearchAdvisorKeyPress}
                            />
                        </div>
                        <span className='cursor-pointer' onClick={onSearchBtnClicked}>
                            <MDBIcon fas icon='search' />
                        </span>
                    </div>
                </div>
            </div>

            <AdminTable columns={columns} rows={reviews}>
                {(row, colname, rowIndex, colIndex) => {
                    switch(colname) {
                        case 'date':
                            return <Moment format="YYYY-MM-DD">{row['createdAt']}</Moment>
                        case 'advisor':
                            return  (
                                <div className="d-flex align-items-center">
                                    <img src={getAvatarUrl(row.aId?.avatar)} className="round-avatar-40 me-1" />
                                    {row.aId?.username} 
                                </div>
                            )
                        case 'client':
                            return  (
                                <div className="d-flex align-items-center">
                                    <img src={getAvatarUrl(row.cId?.avatar)} className="round-avatar-40 me-1" />
                                    {row.cId?.username} 
                                </div>
                            )
                        case 'type':
                            return (
                                <div className={`review-type ${row.type}`}>{row.type}</div>
                            )
                        case 'content':
                            return <div className="review-content" dangerouslySetInnerHTML={{ __html: text2html(row.content)}}></div>
                        case 'action':
                            return <div className="d-flex action">
                                <div className="edit cursor-pointer" onClick={(e)=>handleEditReview(e, row)}><MDBIcon far icon="edit" size='lg' color="warning" /></div>
                                <div className="ms-2 delete cursor-pointer" onClick={(e)=>handleDeleteReview(e, row)}>
                                    {removing && <MDBSpinner size="sm" role='status' />}
                                    {!removing && <MDBIcon far icon="trash-alt" size='lg' color="danger" />}
                                </div>
                            </div>
                    }
                    return null;
                }}
            </AdminTable>

            <div className='d-flex justify-content-center mt-4'>
                <Paginator total={total} perPage={formData.perPage} onPageChanged={onPageChanged} />
            </div>

            {loading && <LoadingIndicator />}
        </div>
    </>)
});

export default AdminReviewList;
import React, { useState, useEffect } from "react";
import { MDBContainer, MDBIcon, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from "mdb-react-ui-kit";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { floatV } from "../../../Utils/utils";
import './CheckOut.scss';
import { getAuthUser, updateAuth } from "../../../Store/Reducer/authReducer";
import JwtService from "../../../Core/Service/JwtService";
import { useDispatch, useSelector } from "react-redux";
import { getChatSocket } from "../../../Store/Reducer/socketReducer";
import { PaymentKind, SendMessages } from "../../../Constants/constants";
import PaypalCheckOut from "../../../Components/Paypal/PaypalCheckOut";
import CreditCardCheckOut from "../../../Components/CreditCard/CreditCardCheckOut";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import ApiService from "../../../Core/Service/ApiService";

const CheckOut = React.memo((props) => {
    const params = useParams();
    const navigate = useNavigate();
    const {amount} = params;
    const dispatch = useDispatch();
    const chatSocket = useSelector(getChatSocket);
    const authUser = useSelector(getAuthUser);
    const [activePayment, setActivePayment] = useState(PaymentKind.PAYPAL);
    const [paymentTokens, setPaymentTokens] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        setLoading(true);
        ApiService.get('/client/paymentTokens').then((response) => {
            setPaymentTokens(response.data);
        })
        .finally(()=>{
            setLoading(false);
        });
    }, []);

    const onPaymentSuccess = (balance) => {
        toast.success("Payment was processed successfully.");

        // To update authUser's balance
        dispatch(updateAuth({
            balance: floatV(parseFloat(balance))
        }));

        const paymentRequest = JwtService.getPaymentRequest();
        JwtService.destroyPaymentRequest();

        switch(paymentRequest?.purpose) {
            case 'request_chat':
                chatSocket.emit(SendMessages.REQUEST_CHAT, {
                    aId: paymentRequest.advisor._id
                });
                navigate('/advisor/' + paymentRequest.advisor._id);
                break;
            case 'charge_wallet':
                navigate('/client/mywallet');
                break;
            case 'continue_chat':
                chatSocket.emit(SendMessages.RESUME_CHAT, {chatId: paymentRequest.chatId})
                break;
        }
    }

    return (
        <MDBContainer breakpoint="lg" className="client-checkout-container d-flex flex-column justify-content-center align-items-center p-4">
            <div className="w-100 mt-4" style={{maxWidth: 500}}>
                <div className="you-will-be-chared d-flex align-items-center justify-content-center p-2">
                    Additional funds required
                    <span className="mx-2">:</span>
                    <span className="checkout-amount text-center mb-1">$ {amount}</span>
                </div>
                <div className="mt-4">
                    <MDBTabs>
                        <MDBTabsItem>
                            <MDBTabsLink
                                onClick={e => setActivePayment(PaymentKind.PAYPAL)}
                                active={activePayment===PaymentKind.PAYPAL}
                            >
                                <div className="d-flex align-items-center">
                                    <MDBIcon fab icon="paypal" size="2x" color="primary" />
                                    <span className="ms-2">PayPal</span>
                                </div>
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <MDBTabsItem>
                            <MDBTabsLink
                                onClick={e => setActivePayment(PaymentKind.CREDIT_CARD)}
                                active={activePayment===PaymentKind.CREDIT_CARD}
                            >
                                <div className="d-flex align-items-center">
                                    <MDBIcon far icon="credit-card" color="success" size="2x"/>
                                    <span className="ms-2">Credit Card</span>
                                </div>
                            </MDBTabsLink>
                        </MDBTabsItem>
                    </MDBTabs>

                    <PayPalScriptProvider options={{
                        'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
                        'components': ['card-fields', 'buttons'],
                        'currency': 'USD',
                        'data-usercentrics': `PayPal`,
                    }}>
                        <MDBTabsContent>
                            <MDBTabsPane open={activePayment === PaymentKind.PAYPAL} className="pt-4">
                                <PaypalCheckOut amount={amount} onPaymentSuccess={onPaymentSuccess} />
                            </MDBTabsPane>
                            <MDBTabsPane open={activePayment === PaymentKind.CREDIT_CARD} className="pt-4">
                                <CreditCardCheckOut 
                                    amount={amount} 
                                    onPaymentSuccess={onPaymentSuccess} 
                                    paymentTokenExist={paymentTokens?.credit_token} 
                                /> 
                            </MDBTabsPane>
                        </MDBTabsContent>
                    </PayPalScriptProvider>
                </div>
            </div>

            {loading && <LoadingIndicator />}
        </MDBContainer>
    )
})

export default CheckOut;
import React, {useEffect, useRef, useState} from "react";
import classname from 'classnames';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBCol, MDBContainer,
        MDBFile,
        MDBIcon,
        MDBInput, MDBRow,MDBTextArea} from "mdb-react-ui-kit";
import { toast } from "react-toastify";
import { getAvatarUrl, getBackendAssetUrl } from "../../../../Utils/utils";

const ApplicantDocument = (props) => {
    const {advisor, compareData} = props;

    const updatedClass = (field) => {
        if(compareData && compareData[field] != advisor[field]) {
            return 'updated';
        } else {
            return ''
        }
    }

    const renderIdCards = () => {
        return (
            <MDBCard className='id-card'>
                <MDBCardHeader className="text-center">
                    <strong>Government Identification </strong>
                </MDBCardHeader>
                <MDBCardBody className="p-2">
                    <MDBRow className="w-100 m-0">
                        {(()=>{
                            if(advisor.gov_photo_id_front || advisor.gov_photo_id_backend) {
                                return (
                                <>
                                    {advisor.gov_photo_id_front && (
                                        <MDBCol md='6' className="d-flex flex-column justify-content-center align-items-center">
                                            <a href={getBackendAssetUrl(advisor.gov_photo_id_front)} target="_blank">
                                                <MDBIcon far icon='id-card' className="me-1" color="none" size="lg" />
                                                Front File
                                            </a>
                                        </MDBCol>
                                   )}
                                   {advisor.gov_photo_id_backend && (
                                        <MDBCol md='6' className="d-flex flex-column justify-content-center align-items-center">
                                            <a href={getBackendAssetUrl(advisor.gov_photo_id_backend)} target="_blank">
                                                <MDBIcon far icon='id-card' className="me-1" color="none" size="lg" />
                                                Back File
                                            </a>
                                        </MDBCol>                                    
                                   )}
                                </>
                                )
                            } else {
                                return <div className="text-center">No uploaded</div>
                            }
                        })()}
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
        )
    }

    const renderCertificates = () => {
        if(advisor?.cert_files?.length > 0) {
            return (
                <MDBCard className="cert-files mt-4">
                    <MDBCardHeader className="text-center">
                        <strong>Certificates</strong>
                    </MDBCardHeader>
                    <MDBCardBody className="p-2">
                        <MDBRow className="w-100 m-0">
                            {advisor.cert_files.map((fileUrl, index) => (
                                <MDBCol md='6' key={index} className="d-flex justify-content-center align-items-center mt-2 cert-file">
                                    <div className="img-container">
                                        <a href={getBackendAssetUrl(fileUrl)} target="_blank">
                                            <MDBIcon far icon='file-archive' size="lg" className="me-1" />
                                            Certificate {index+1}
                                        </a>
                                    </div>
                                </MDBCol>
                            ))}
                        </MDBRow>
                    </MDBCardBody>
                </MDBCard>
            )
        } else {
            return <></>
        }
    }

    const renderTaxes = () => {
        return (
            <MDBCard className="tax-file mt-4">
                <MDBCardHeader className="text-center">
                    <strong>Tax Form</strong>
                </MDBCardHeader>
                <MDBCardBody className="d-flex justify-content-center p-2">
                    {(()=>{
                        if(advisor.us_tax_norms) {
                            return <a href={getBackendAssetUrl(advisor.us_tax_norms)} target="_blank">
                                <MDBIcon far icon='file-alt' size="lg" className="me-1" />
                                Tax File
                            </a>
                        } else {
                            return <>No uploaded</>
                        }
                    })()}
                </MDBCardBody>
            </MDBCard>
        )        
    }

    return <MDBContainer breakpoint="lg" className="d-flex flex-column justify-content-center application-document-container">
        {renderIdCards()}
        {renderCertificates()}
        {renderTaxes()}
    </MDBContainer>
}

export default ApplicantDocument;
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBContainer, MDBIcon, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import React, {useEffect, useState} from "react";
import SVG from 'react-inlinesvg';
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import Moment from "react-moment";
import Select from "react-select";
import { shallowEqual, useSelector } from "react-redux";
import { memoizedGetAuthUser } from "../../../Store/Reducer/authReducer";
import ApiService from "../../../Core/Service/ApiService";
import Paginator from "../../../Components/Paginator/Paginator";
import { PaymentKind, PaymentMethod, TransactionType } from "../../../Constants/constants";
import { useRef } from "react";
import ScrollHorizon from "../../../Components/ScrollHorizon/ScrollHorizon";
import SearchBar from "../../../Components/SearchBar/SearchBar";

const ClientTransactions = React.memo((props) => {
    const [loading, setLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [total, setTotal] = useState(0);
    const authUser = useSelector(memoizedGetAuthUser);
    const [searchFormData, setSearchFormData] = useState({
        pageNum: 1,
        perPage: 10,
        transaction_type: '',
        payMethod: ''
    });

    const perPageOpts = [
        { value: 10,label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" }
    ];
    const [curPerPageOpt, setCurPerPageOpt] = useState(perPageOpts[0])
    const onPerPageChanged = (opt) => {
        setSearchFormData({
            ...searchFormData,
            perPage: opt.value,
            pageNum: 1
        });
        setCurPerPageOpt(opt);
    }
    const onPageChanged = (pageNum) => {
        setSearchFormData({
            ...searchFormData,
            pageNum
        });
    }

    const tranTypeOpts = [
        { value: '', label: 'All' },
        { value: TransactionType.CHARGE, label: 'Deposit' },
        { value: TransactionType.CHAT_PAY, label: 'Paid Session' },
        { value: TransactionType.CREDIT_BACK, label: 'Credit'}
    ];
    const [curTranTypeOpt, setCurTranTypeOpt] = useState(tranTypeOpts[0]);
    const onTransTypeChanged = (opt) => {
        setSearchFormData({
            ...searchFormData,
            transaction_type: opt.value,
            pageNum: 1
        });
        setCurTranTypeOpt(opt);
    }

    const payMethodOpts = [
        {value: PaymentKind.STRIPE, label: 'Stripe'}
    ]
    const [curPayMethodOpt, setCurPayMethodOpt] = useState(payMethodOpts[0]);
    const onPayMethodChanged = (opt) => {
        setSearchFormData({
            ...searchFormData,
            payMethod: opt.value,
            pageNum: 1
        });
        setCurPayMethodOpt(opt);
    }

    useEffect(()=>{
        getTransactions();
    }, [searchFormData]);

    const getTransactions = () => {
        setLoading(true);
        ApiService.post('/client/mytransactions', searchFormData).then(result=>{
            setTransactions(result.data.transactions);
            setTotal(result.data.total);
        })
        .catch(error=>{

        })
        .finally(()=>{
            setLoading(false);
        })
    }

    const renderTransactions = () => {
        if(total === 0) {
            return <tr>
                <td colSpan={3} className="text-center">
                    {loading ? '' : <div className="d-flex justify-content-center align-items-center">
                        <SVG src='/img/icons/no_found.svg' style={{height:40, width: 40}} /><div className="ms-2 mt-1">No Transactions</div>
                    </div>}
                </td>
            </tr>
        } else {
            return transactions.map((t, index)=>{
                let paytype = "";
                if( t.transaction_type === TransactionType.CHARGE || t.transaction_type === TransactionType.CREDIT_BACK) {
                    paytype = "pay-in"
                } else if(t.transaction_type === TransactionType.CHAT_PAY) {
                    paytype = "withdraw"
                }

                return (
                    <tr key={index}>
                        <td className="time"><Moment format="YYYY-MM-DD">{t.createdAt}</Moment></td>
                        <td>
                            {(()=>{
                                switch(t.transaction_type) {
                                    case TransactionType.CHARGE:
                                        return <span>Add Balance</span>;
                                    case TransactionType.CREDIT_BACK:
                                        return <span>Credit</span>;
                                    case TransactionType.CHAT_PAY:
                                        return <div className="d-block d-sm-flex">
                                            <div className="me-1">{t.detail?.advisor?.username}</div>
                                            <div>(live chat)</div>
                                        </div>
                                }
                            })()}
                        </td>
                        <td className={`${paytype}`}>$ { t.amount }</td>
                    </tr>
                )
            })
        }
    }

    return <MDBCard className="transactions-card mt-4">
        <MDBCardHeader className="d-flex align-items-center">
            <MDBIcon fas icon='exchange-alt' color="info" size='2x' />
            <span className="px-2"><strong>My Transactions</strong></span>
        </MDBCardHeader>
        <MDBCardBody style={{overflow: 'auto'}}>
            <div className="confideas-table-header">
                <SearchBar>
                    <div className="search-item me-2">
                        <Select className=""
                                options={perPageOpts} 
                                onChange={(opts) => onPerPageChanged(opts)} 
                                defaultValue={curPerPageOpt} />
                    </div>
                    <div className="search-item me-2" style={{width: 150}}>
                        <Select className=""
                                options={tranTypeOpts} 
                                onChange={(opts) => onTransTypeChanged(opts)} 
                                defaultValue={curTranTypeOpt} />
                    </div>
                </SearchBar>
            </div>
            
            <ScrollHorizon>
                <MDBTable>
                    <MDBTableHead>
                        <tr>
                            <th>Date</th>
                            <th>Transaction</th>
                            <th>Amount</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {renderTransactions()}
                    </MDBTableBody>
                </MDBTable>
            </ScrollHorizon>

            <div className='d-flex justify-content-center mt-4'>
                <Paginator total={total} perPage={searchFormData.perPage} onPageChanged={onPageChanged} />
            </div>
            {loading && <LoadingIndicator />}
        </MDBCardBody>
    </MDBCard>
});

export default ClientTransactions;
import React, { useEffect, useState } from "react";
import styles from './Dashboard.scss'
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import ApiService from "../../../Core/Service/ApiService";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import { useNavigate } from "react-router-dom";
import AdminTodayPerformance from "./TodayPerformance";
import StatisticCard from "./StatisticCard";
import AdminPaymentChart from "./PaymentChart";



const AdminDashboard = React.memo((props)=>{
    const [loading, setLoading] = useState(false);
    const [statisticData, setStatisticData] = useState({});

    useEffect(() => {
        setLoading(true);

        ApiService.get('/admin/getStatisticData').then(result=>{
            setStatisticData(result.data);
        })
        .catch(error=>{

        })
        .finally(()=>{
            setLoading(false);
        })
    }, []);

    const breadcrumb=[{title: 'Dashboard'}];
    return <div className="w-100">
        <Breadcrumb breadcrumb={breadcrumb} />

        <MDBRow className="mt-4">
            <StatisticCard title='Payment' 
                customeStyle={{
                    cardHeaderBackgroundColor: '#8b3a9f',
                    cardHeaderColor: 'white',
                    cardBodyBackgroundColor: '#c95be3',
                    cardBodyColor: 'white'
                }} 
                data={[
                {
                    label: 'Today',
                    value: statisticData.payment?.today,
                    url: '/admin/transaction/payment'
                }
            ]} />
            <StatisticCard title='Clients'
                customeStyle={{
                    cardHeaderBackgroundColor: '#0e8295',
                    cardHeaderColor: 'white',
                    cardBodyBackgroundColor: '#17a2b8',
                    cardBodyColor: 'white'
                }} 
                data={[{
                    label: 'Total',
                    value: statisticData.client?.total,
                    url: '/admin/client/list'
                },{
                    label: 'Today',
                    value: statisticData.client?.today,
                    url: '/admin/client/list'
                }]} 
            />
            <StatisticCard title='Advisors' 
                customeStyle={{
                    cardHeaderBackgroundColor: '#1f8937',
                    cardHeaderColor: 'white',
                    cardBodyBackgroundColor: '#28a745',
                    cardBodyColor: 'white'
                }} 
                data={[
                {
                    label: 'Total',
                    value: statisticData.advisor?.total,
                    url: '/admin/advisor/list'
                },
                {
                    label: 'Pending',
                    value: statisticData.advisor?.pending,
                    url: '/admin/advisor/pending/list'
                }
            ]} />
            <StatisticCard title='Opinion' 
                customeStyle={{
                    cardHeaderBackgroundColor: '#4d65df',
                    cardHeaderColor: 'white',
                    cardBodyBackgroundColor: '#5b7fe9',
                    cardBodyColor: 'white'
                }} 
                data={[
                {
                    label: 'Total',
                    value: statisticData.opinion?.total,
                    url: '/admin/opinion/list'
                },
                {
                    label: 'Waiting',
                    value: statisticData.opinion?.waiting,
                    url: '/admin/opinion/list'
                }
            ]} />
        </MDBRow>

        <div className="w-100 mt-4">
            <AdminPaymentChart />
        </div>
    </div>
});

export default AdminDashboard;
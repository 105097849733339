import React, {useEffect, useState} from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { MDBContainer, MDBIcon } from 'mdb-react-ui-kit';
import styles from './layout.scss'

const AdvisorAuthLayout = (props) => {
    const [scrollY, setScrollY] = useState(0);
    const [scrollHeight, setScrollHeight] = useState(0);

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

        // To add scroll event handler
        const handleScroll = () => {
            setScrollY(window.scrollY);
        }
        window.addEventListener('scroll', handleScroll);

        // To add document scrollHeight change event handler
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                if (entry.target === document.documentElement) {
                    setScrollHeight(document.documentElement.scrollHeight)
                }
            }
        });
        resizeObserver.observe(document.documentElement);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollUp = () => {
        window.scroll({
            top: window.scrollY - window.innerHeight,
            behavior: 'smooth'
        })
    }
    const scrollDown = () => {
        window.scroll({
            top: window.scrollY + window.innerHeight,
            behavior: 'smooth'
        })
    }

    const renderToTop = () => {
        if(window.scrollY > 100) {
            return <section className='goto-top-section'>
                <div className='d-flex align-items-center justify-content-center' onClick={()=>scrollUp()}>
                    <MDBIcon fas icon="arrow-up" size='lg' style={{color: '#367e34'}} />
                </div>
            </section>
        } else {
            return <></>
        }
    }
    const renderToBottom = () => {
        let scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
        let scrolled = window.scrollY;

        if (scrollableHeight - scrolled > 100 ) {
            return <section className='goto-bottom-section'>
                <div className='d-flex align-items-center justify-content-center' onClick={()=>scrollDown()}>
                    <MDBIcon fas icon="arrow-down" size='lg' style={{color: '#367e34'}} />
                </div>
            </section>
        }
    }

    return (
        <section className='ad_auth_container d-flex flex-column'>
            <Header />

            <section className='main-body-section'>
                <Outlet />
            </section>

            <Footer />

            {renderToBottom()}
            {renderToTop()}

        </section>
    )
}

export default AdvisorAuthLayout;
import React from "react";
import { text2html } from "../../../Utils/utils";
import { MDBIcon } from "mdb-react-ui-kit";

function AboutMe(props) {
    const {advisor} = props;

    return (
        <>
            <section className="about-me mt-4 p-4">
                <div className="d-flex align-items-center mb-2">
                    <MDBIcon fas icon="user-graduate" size="lg" />
                    <h5 className="m-0 ms-2">About Me:</h5>
                </div>
                <div style={{overflowWrap: 'break-word', fontSize:20}} dangerouslySetInnerHTML={{ __html: text2html(advisor?.aboutme)}} />
            </section>

            <section className="offer-services mt-4 p-4">
                <div className="d-flex align-items-center mb-2">
                    <MDBIcon fas icon="comment" size="lg" />
                    <h5 className="m-0 ms-2">Services Offered:</h5>
                </div>
                { advisor.service_description && 
                    <div style={{overflowWrap: 'break-word', fontSize:20}} dangerouslySetInnerHTML={{ __html: text2html(advisor?.service_description)}}></div>
                }
            </section>
        </>
    )
}

export default AboutMe;
import React, { useEffect, useState } from "react";
import Select from "react-select";
import AdminTable from "../../../Components/AdminTable/AdminTable";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import Paginator from "../../../Components/Paginator/Paginator";
import Moment from "react-moment";
import './Transaction.scss';
import { useNavigate, useParams } from "react-router-dom";
import { MDBBtn, MDBCard, MDBCardBody, MDBContainer, MDBSpinner } from "mdb-react-ui-kit";
import { getAvatarUrl } from "../../../Utils/utils";
import SearchBar from "../../../Components/SearchBar/SearchBar";
import { toast } from "react-toastify";

const AdminPendingPayoutDetail = React.memo((props) => {
    const params = useParams();
    const { aId, endDate } = params;
    const [payingout, setPayingout] = useState(false);
    const [loading, setLoading] = useState(false);
    const [detailData, setDetailData] = useState();
    const perPageOpts = [
        { value: 10,label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" }
    ];
    const navigate = useNavigate();
    
    const [formData, setFormData] = useState({
        aId,
        endDate,
        perPage: 10,
        pageNum: 1,
    });

    useEffect(()=>{
        setLoading(true);
        ApiService.get('/admin/transaction/pending_payout/detail', formData).then(response=>{
            setDetailData(response.data);
        })
        .finally(()=>{
            setLoading(false);
        })
    }, [formData]);

    const onPageChanged = (pageNum) => {
        setFormData({
            ...formData,
            pageNum
        })
    }
    const onPerPageChanged = (opt) => {
        setFormData({
            ...formData,
            perPage: opt.value,
            pageNum: 1
        })
        setCurPerPageOpt(opt);
    }

    const onRowSelected = (row, rowIndex) => {

    }

    const columns = [
        {name: 'startedAt', title: 'Date'},
        {name: 'username', title: 'Client'},
        {name: 'ad_earning', title: 'Earning'},
    ]

    const handlePayout = () => {
        if(window.confirm("Are you sure to make payout to the advisor?") == false) return;
        setPayingout(true);
        const data = {
            aId,
            endDate
        };
        ApiService.post('/admin/paypal/make_payout', data).then(response => {
            toast.success("Paypal is processing payout.");
            navigate('/admin/transaction/pending_payout');
        })
        .finally(()=>{
            setPayingout(false);
        })
    }

    const goBack = () => {
        navigate(-1)
    }

    const renderChats = () => {
        return (
        <MDBCard className="mt-4">
            <MDBCardBody>
                <div className="d-flex mb-3">
                    <div className="flex-fluid">Total: {detailData?.total_chat_count}</div>
                    <SearchBar>
                        <div className="search-item me-2">
                            <Select 
                                options={perPageOpts} 
                                onChange={(opt) => onPerPageChanged(opt)} 
                                value={perPageOpts.find(opt=>opt.value==formData.perPage)}
                            />
                        </div>
                    </SearchBar> 
                </div>
                <AdminTable columns={columns} rows={detailData?.chats} onRowSelected={onRowSelected}>
                {(row, colname, rowIndex, colIndex) => {
                    switch(colname) {
                        case 'startedAt':
                            return <Moment format="YYYY-MM-DD">{row.startedAt}</Moment>
                        case 'username':
                            return <div className="d-flex align-items-center">
                                <img src={getAvatarUrl(row.cId.avatar)} className="round-avatar-40 me-2" />
                                <span>{row.cId.username}</span>
                            </div>
                        case 'ad_earning':
                            return <div>
                                $ {row.ad_earning}
                            </div>
                    }
                }}
                </AdminTable>

                <div className='d-flex justify-content-center mt-4'>
                    <Paginator total={detailData?.total_chat_count} perPage={formData.perPage} onPageChanged={onPageChanged} />
                </div>

            </MDBCardBody>
        </MDBCard>
        )
    }

    return (<MDBContainer breakpoint="lg" className="admin-pending-payout-detail p-2">
        <MDBCard>
            <MDBCardBody>
                <div className="d-flex align-items-center justify-content-between mt-2">
                    <div className="d-flex align-items-center">
                        <img src={getAvatarUrl(detailData?.advisor.avatar)} className="round-avatar-50" />
                        <div className="ms-2">{detailData?.advisor.username}</div>
                        {detailData?.advisor.is_withdrawing && 
                        <div className="payout-processing ms-4">Processing</div>}
                    </div>
                    <div className="ms-4 total-amount">
                        $ {detailData?.total_amount}
                    </div>
                </div>
            </MDBCardBody>
        </MDBCard>

        {!detailData?.advisor.is_withdrawing && (
        <div className="d-flex justify-content-end mt-3 px-3">
            <MDBBtn className="confideas-primary-btn" onClick={handlePayout} disabled={payingout}>
                {payingout ? 
                (<div className="d-flex align-items-center"><MDBSpinner size="sm me-2" />Payout</div>) : 
                (<>Payout</>)
                }
            </MDBBtn>
        </div>
        )}

        { renderChats() }

        <div className="d-none d-lg-flex mt-4 justify-content-end">
            <MDBBtn color="default" onClick={goBack}>Back</MDBBtn>
        </div>
        
        {loading && <LoadingIndicator />}
    </MDBContainer>)
});

export default AdminPendingPayoutDetail;

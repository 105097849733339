import { MDBIcon } from 'mdb-react-ui-kit';
import React, {useState} from 'react';
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses } from 'react-pro-sidebar';
import { Link, useNavigate } from 'react-router-dom';
import AdminContext from '../../Context/AdminContext';
import { useContext } from 'react';

const sideMenus = [
    {
        title: 'Dashboard',
        url: '/admin/dashboard',
        icon: 'home'
    },
    {
        title: 'Clients',
        url: '/admin/client/list',
        icon: 'user-friends'
    },
    {
        title: 'Advisors',
        icon: 'user-graduate',
        subMenus : [
          {
              title: 'Advisors',
              url: '/admin/advisor/list'
          },
          {
            title: 'Pending Advisors',
            url: '/admin/advisor/pending/list'
          }
        ]
    },
    {
      title: 'Reviews',
      url: '/admin/review/list',
      icon: 'comment-dots'
    },
    {
        title: 'Chats',
        url: '/admin/chat/list',
        icon: 'comment'
    },
    {
        title: 'Transactions',
        icon: 'money-bill',
        subMenus : [
            {
              title: 'Payments',
              url: '/admin/transaction/payment'
            },
            {
              title: 'Pending Payouts',
              url: '/admin/transaction/pending_payout'
            },
            {
              title: 'Payouts',
              url: '/admin/transaction/payout'
            }
        ]
    },
    {
      title: 'Support Tickets',
      url: '/admin/opinion/list',
      icon: 'envelope'
    },
    {
      title: 'Actions',
      url: '/admin/action/list',
      icon: 'file-code'
    },
    {
      title: 'Settings',
      icon: 'cog',
      subMenus: [
        {
          title: 'Prices',
          url: '/admin/setting/price',
        },
        {
          title: 'Payments',
          url: '/admin/setting/payment'
        },
        {
          title: 'VerifyItems',
          url: '/admin/verifyitem/list'
        }
      ]
    },
    {
      title: 'Track Words',
      icon: 'exclamation-triangle',
      subMenus: [
        {
          title: 'Track Words',
          url: '/admin/trackword/list',
        },
        {
          title: 'Banned Chats',
          url: '/admin/bannedchat/list',
        }
      ]
    }
]

const themes = {
  light: {
    sidebar: {
      backgroundColor: '#ffffff',
      color: '#607489',
    },
    menu: {
      menuContent: '#e6e8eb',
      icon: '#555',
      hover: {
        backgroundColor: '#c5e4ff',
        color: '#44596e',
      },
      active: {
        backgroundColor: 'red',
        color: 'white'
      },
      disabled: {
        color: '#9fb6cf',
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: '#343a40',
      color: '#fefefe',
    },
    menu: {
      menuContent: '#3c4248',
      icon: '#59d0ff',
      hover: {
        backgroundColor: '#444a50',
        color: '#b6c8d9',
      },
      active: {
        backgroundColor: '#13395e',
        color: '#000',
      },
      disabled: {
        color: '#3e5e7e',
      },
    },
  },
};

// hex to rgba converter
const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
  
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const AdminSidebar = React.memo((props) => {
    const navigate = useNavigate();
    const adminContext = useContext(AdminContext);
    const collapsed = adminContext.sidebarCollapsed;
    const setCollapsed = adminContext.setSidebarCollapsed;
    const [theme, setTheme] = useState('light');
    const [hasImage, setHasImage] = useState(false);
    const menuItemStyles = {
        root: {
          fontSize: '16px',
          fontWeight: 400
        },
        icon: {
          color: themes[theme].menu.icon,
          [`&.${menuClasses.disabled}`]: {
            color: themes[theme].menu.disabled.color,
          },
        },
        SubMenuExpandIcon: {
          color: '#b6b7b9',
        },
        subMenuContent: ({ level }) => ({
          backgroundColor:
            level === 0
              ? hexToRgba(themes[theme].menu.menuContent, hasImage && !collapsed ? 0.4 : 1)
              : 'transparent',
        }),
        button: {
          [`&.${menuClasses.disabled}`]: {
            color: themes[theme].menu.disabled.color,
          },
          '&:hover': {
            backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, hasImage ? 0.8 : 1),
            color: themes[theme].menu.hover.color,
          },
          color: '#333'
        },
        label: ({ open }) => ({
          fontWeight: open ? 600 : undefined,
        }),
    };

    const gotoHome = (e) => {
        e.preventDefault();
        navigate('/');
    }

    const onMenuItemClicked = (menu) => {
      if(menu.url) navigate(menu.url);
      adminContext.setSidebarCollapsed(true);
    }

    const renderMenus = () => {
        return (
            sideMenus.map((menu, index) => {
                if(menu.subMenus?.length > 0) {
                    return (
                        <SubMenu label={menu.title} icon={<MDBIcon fas icon={menu.icon} />} key={index}>
                            {menu.subMenus.map((sm, j)=>{
                                return (
                                    <MenuItem key={j} onClick={e=>onMenuItemClicked(sm)}> 
                                        {sm.title}
                                    </MenuItem>                
                                )
                            })}
                        </SubMenu>
                    )
                } else {
                    return (
                        <MenuItem   icon={<MDBIcon fas icon={menu.icon} />} 
                                    key={index}
                                    className='cursor-pointer'
                                    onClick={e=>onMenuItemClicked(menu)}
                        > 
                            {menu.title}
                        </MenuItem>
                    )
                }
            })
        )
    }

    return (
        <div className='sidebar'>
            <div className='w-100 d-flex justify-content-start align-items-center sidebar-logo px-2'>
                <div className='d-flex align-items-center px-4' onClick={gotoHome}>
                  Admin Panel
                </div>
                { !collapsed &&
                  <div className='flex-fluid text-right pe-2' onClick={(e)=>setCollapsed(true)}>
                    <MDBIcon fas icon="angle-left" />
                  </div>
                }
            </div>

            <Menu menuItemStyles={menuItemStyles}>
            { renderMenus() }
            </Menu>
        </div>
    )
})

export default AdminSidebar;
import React from "react";
import styles from './Setting.scss'
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBCardTitle, MDBInput } from "mdb-react-ui-kit";
import { useState } from "react";
import { useEffect } from "react";
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { toast } from "react-toastify";

const AdminSettingPrice = React.memo((props) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        chat_rate_min: 3.9,
        chat_rate_max: 13.99,
        basic_commission : 40,
        top_nth: 20,
        top_nth_commission: 35
    })

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const savePrice = (e) => {
        e.preventDefault();

        setLoading(true);

        ApiService.post('/admin/setting/price/save', formData).then(resutl=>{
            toast.success('Saved successfully');
        })
        .finally(()=>{
            setLoading(false);
        })
    }

    useEffect(()=>{
        setLoading(true);

        ApiService.get('/admin/setting/price/index').then(result=>{
            setFormData(result.data);
        })
        .finally(()=>{
            setLoading(false);
        })
    }, []);

    const breadcrumb=[{title: 'Setting'}, {title: 'Price'}];
    return (<>
        <Breadcrumb breadcrumb={breadcrumb} />
        <div className="advisor-rate-limit">
            <MDBCard>
                <MDBCardHeader>
                    <MDBCardTitle>Advisor Rate Limit</MDBCardTitle>
                </MDBCardHeader>
                <MDBCardBody>
                    <div className="d-flex align-items-center">
                        <div className="me-2"><label>Chat Rate Limit : </label></div>
                        <div className="d-flex align-items-center">
                            <div style={{width: 150}}>
                                <MDBInput   type="number" 
                                            label='minimum value'
                                            className="text-center" 
                                            name="chat_rate_min" 
                                            value={formData.chat_rate_min} 
                                            onChange={ handleInputChange } />
                            </div>
                            <span className="mx-2">~</span>
                            <div style={{width: 150}} className="d-flex align-items-center">
                                <MDBInput   type="number" 
                                            label='maximum value' 
                                            name="chat_rate_max" 
                                            className="text-center" 
                                            value={formData.chat_rate_max} 
                                            onChange={ handleInputChange } />
                                <span className="ps-1">($)</span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <MDBBtn onClick={savePrice}>Save</MDBBtn>
                    </div>
                </MDBCardBody>
            </MDBCard>
        </div>

        <div className="advisor-commission mt-4">
            <MDBCard>
                <MDBCardHeader>
                    <MDBCardTitle>Commission</MDBCardTitle>
                </MDBCardHeader>
                <MDBCardBody>
                    <div className="basic-commission d-flex align-items-center">
                        <div className="me-2"><label>Basic Commission : </label></div>
                        <div className="d-flex align-items-center" style={{width:100}}>
                            <MDBInput   type='number' 
                                        name='basic_commission' 
                                        className="text-center" 
                                        value={formData.basic_commission} 
                                        onChange={handleInputChange} />
                        </div>
                        <span className="ps-1">(%)</span>
                    </div>
                    <div className="first-nth-advisor-commission mt-4">
                        <div className=" d-flex align-items-center">
                            <label className="me-2">First </label>
                            <div style={{width: 100}}>
                                <MDBInput   type='number'
                                            name='top_nth' 
                                            className="text-center" 
                                            value={formData.top_nth} 
                                            onChange={handleInputChange} />
                            </div>
                            <span className="mx-2">th Advisor{`'`} Commission : </span>

                            <div style={{width: 100}} className="d-flex align-items-center">
                                <MDBInput type='number'
                                            name='top_nth_commission'
                                            className="text-center" 
                                            value={formData.top_nth_commission}
                                            onChange={handleInputChange} />
                            </div>
                            <span className="ms-2">(%)</span>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end">
                        <MDBBtn onClick={savePrice}>Save</MDBBtn>
                    </div>
                </MDBCardBody>
            </MDBCard>

            {loading && <LoadingIndicator />}
        </div>
    </>)
});

export default AdminSettingPrice;
import { Outlet } from "react-router";
import { Navigate } from 'react-router-dom';
import { shallowEqual, useSelector } from "react-redux";
import { IsAuthenticated, memoizedGetAuthUser } from "../../Store/Reducer/authReducer";

const useClientAuth = () => {
  const isAuthenticated = useSelector(IsAuthenticated);
  const authUser = useSelector(memoizedGetAuthUser);

  return isAuthenticated && authUser?.role === 'client';
}

const ClientProtectedRoutes = () => {
  const isAuth = useClientAuth();
  return isAuth ? <Outlet /> : <Navigate replace to="/login" />
}

export default ClientProtectedRoutes


import React from 'react';
import styles from './ClientDetail.scss';
import { MDBBtn, MDBContainer } from 'mdb-react-ui-kit';
import AdvisorClientKeyNote from './ClientKeyNote';
import AdvisorClientChatHistory from './ClientChatHistory';
import { useEffect } from 'react';
import { scrollUpTop } from '../../../Utils/utils';
import { useNavigate, useParams } from 'react-router-dom';

const AdvisorClientDetail = React.memo(props=>{
    const params = useParams();
    const cId = params.cId;
    const navigate = useNavigate();

    useEffect(()=>{
        scrollUpTop();
    }, [])

    const goBack = (e) => {
        e.preventDefault();
        navigate(-1);
    }

    return <MDBContainer breakpoint='lg' className='advisor-client-detail p-4'>
        <AdvisorClientKeyNote cId={cId} />
        <AdvisorClientChatHistory cId={cId} />

        <div className="d-flex justify-content-end mt-4">
            <MDBBtn onClick={goBack} color="second">Back</MDBBtn>
        </div>
    </MDBContainer>
});

export default AdvisorClientDetail;
import { createSlice } from '@reduxjs/toolkit';
import JwtService from '../../Core/Service/JwtService';

let initialAlarms = JwtService.getAlarms();
if(!initialAlarms) {
    initialAlarms = [];
} else {
    initialAlarms = initialAlarms.filter(alarm=>alarm?.content);
}

const alarmSlice = createSlice({
    name: 'alarm',
    initialState: {
        alarms: initialAlarms,
    },
    reducers: {
        addAlarms: (state, action) => {
            console.log('addAlarms', action.payload);
            state.alarms.push(...action.payload);
            JwtService.setAlarms(state.alarms);
        },
        ackAlarm: (state, action) => {
            console.log('ackAlarm', action.payload);
            const index = state.alarms.findIndex(a=>a._id === action.payload);
            if(index >= 0) {
                state.alarms.splice(index, 1);
                if(state.alarms.length > 0) JwtService.setAlarms(state.alarms);
                else JwtService.destroyAlarms();
            }
        },
        removeAlarms: (state, action) => {
            console.log('removeAlarms');
            state.alarms = [];
            JwtService.destroyAlarms();
        }
    }
});

export const {addAlarms, ackAlarm, removeAlarms} = alarmSlice.actions;

export const getAlarmCount = (state) => state.alarm.alarms.length;
export const getAlarms = (state) => state.alarm.alarms;

export default alarmSlice.reducer;
import { MDBContainer, MDBTable, MDBTableBody, MDBTableHead, MDBInput, MDBIcon, MDBBtn, MDBSwitch, MDBTooltip } from "mdb-react-ui-kit";
import React from "react";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select, { defaultTheme } from "react-select";
import Moment from "react-moment";
import Paginator from "../../../Components/Paginator/Paginator"
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import SVG from 'react-inlinesvg';
import { getAvatarUrl, text2html } from "../../../Utils/utils";
import { useContext } from "react";
import ModalContext from "../../../Context/ModalContext";
import { memoizedGetAuthUser } from "../../../Store/Reducer/authReducer";
import ScrollHorizon from "../../../Components/ScrollHorizon/ScrollHorizon";
import Advisor2 from "../../../Components/Advisor/Advisor2";
import SearchBar from "../../../Components/SearchBar/SearchBar";

const ClientAllBuzzes = React.memo((props) => {
    const navigate = useNavigate();
    const modalContext = useContext(ModalContext);
    const authUser = useSelector(memoizedGetAuthUser);
    const [loading, setLoading] = useState(false);
    const [buzzes, setBuzzes] = useState(null);
    const [total, setTotal] = useState(0);
    const perPageOpts = [
        { value: 10,label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" }
    ];
    const [curPerPageOpt, setCurPerPageOpt] = useState(perPageOpts[0]);
    const [search, setSearch] = useState('');
    const [searchFormData, setSearchFormData] = useState({
        search: '',
        pageNum: 1,
        perPage: 10,
    });
    const onPerPageChanged = (opt) => {
        setSearchFormData({
            ...searchFormData,
            perPage: opt.value,
            pageNum: 1
        });
        setCurPerPageOpt(opt);
    }
    const onPageChanged = (pageNum) => {
        setSearchFormData({
            ...searchFormData,
            pageNum
        });
    }
    const onSearchChanged = (e) => {
        setSearch(e.target.value);
    }
    const onSearchKeyPress = (e) => {
        if(e.key == 'Enter') {
            e.preventDefault();
            setSearchFormData({
                ...searchFormData,
                pageNum: 1,
                search
            });
        }
    }
    const onSearchBtnClicked = () => {
        setSearchFormData({
            ...searchFormData,
            pageNum: 1,
            search
        });
    }
    useEffect(()=>{
        getBuzzes();
    }, [searchFormData.perPage, searchFormData.pageNum, searchFormData.search]);

    const getBuzzes = () => {
        setLoading(true);

        ApiService.post(`/client/buzz/index`, searchFormData).then(result=>{
            setBuzzes(result.data.buzzes);
            setTotal(result.data.total);
        })
        .finally(error=>{
            setLoading(false);
        })
    }
    const goBack = (e) => {
        e.preventDefault();
        navigate(-1);
    }

    const renderBuzzes = (buzzes) => {
        if(buzzes?.length > 0) {
            return buzzes.filter(buzz=>buzz.aId ? true : false).map((buzz, index) => (
                <tr key={index} className={`${buzz.status === 1? 'status-checked' : ''}`}>
                    <td><Moment format="YYYY-MM-DD">{buzz.createdAt}</Moment></td>
                    <td><Advisor2 advisor={buzz.aId} /></td>
                    <td className="message">
                        <MDBTooltip
                            wrapperProps={{ color: 'link' }}
                            wrapperClass="p-0"
                            title={<div className="text-left">
                                <div className="buzz-message">
                                    <div style={{color: '#31ff00'}}>I wrote</div>
                                    <div className="font-italic" dangerouslySetInnerHTML={{ __html: text2html(buzz.message ?? '')}}></div>
                                </div>
                                {buzz.coupon_id && <div className="buzz-coupon-message mt-2">
                                    <div style={{color: '#31ff00'}}>Advisor replied</div>
                                    {buzz.coupon_id.freeMinutes > 0 && <div className="buzz-coupon-freeMinutes">
                                        Free {buzz.coupon_id.freeMinutes} mins
                                    </div>}
                                    <div className="font-italic" dangerouslySetInnerHTML={{ __html: text2html(buzz.coupon_id.message ?? '')}}></div>
                                </div>}
                            </div>}
                        >
                            {!!buzz.coupon_id && <SVG src='/img/icons/envelop_checked.svg' width={30} height={25} />}
                            {!buzz.coupon_id && <SVG src='/img/icons/envelop_unchecked.svg' width={30} height={25} />}
                        </MDBTooltip>
                    </td>
                </tr>
            ))
        } else {
            return <tr><td colSpan={4} className="text-center">
                {loading ? '' : <div className="d-flex justify-content-center align-items-center">
                    <SVG src='/img/icons/no_found.svg' style={{height:40, width: 40}} /><div className="ms-2 mt-1">No Buzzes</div>
                </div>}
            </td></tr>
        }
    }

    return (
        <MDBContainer breakpoint="lg" className="client-all-buzzes py-4">
            <div className="confideas-table-header">
                <SearchBar>
                    <div className="search-item">
                        <Select className="me-2"
                                options={perPageOpts} 
                                onChange={(opts) => onPerPageChanged(opts)} 
                                defaultValue={curPerPageOpt} />
                    </div>
                    <div className='search-item search-term'>
                        <MDBInput type="text" 
                                label="Search Advisor"
                                value={search}
                                onChange={onSearchChanged}
                                onKeyDown={onSearchKeyPress}
                        />
                        <span className='mx-2 cursor-pointer' onClick={onSearchBtnClicked}>
                            <MDBIcon fas icon='search' />
                        </span>
                    </div>
                </SearchBar>
            </div>

            <ScrollHorizon className='buzz-list'>
                <MDBTable align='middle' className="confideas-table">
                    <MDBTableHead>
                        <tr>
                            <th scope="col">Sent At</th>
                            <th scope='col'>Advisor</th>
                            <th scope='col'>Message</th>
                        </tr>    
                    </MDBTableHead>
                    <MDBTableBody>
                        { renderBuzzes(buzzes) }
                    </MDBTableBody>
                </MDBTable>
                {loading && <LoadingIndicator /> }
            </ScrollHorizon>

            <div className='d-flex justify-content-center mt-4'>
                <Paginator curPage={searchFormData.pageNum} total={total} perPage={searchFormData.perPage} onPageChanged={onPageChanged} />
            </div>

            <div className="d-flex justify-content-end mt-4">
                <MDBBtn onClick={goBack} color="second">Back</MDBBtn>
            </div>
        </MDBContainer>
    )
});

export default ClientAllBuzzes;
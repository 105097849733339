import { createSlice } from '@reduxjs/toolkit';

const socketSlice = createSlice({
    name: 'socket',
    initialState: {
        nfSocket: null,
        chatSocket: null,
        isNfSocketConnected: false,
        isChatSocketConnected: false
    },
    reducers: {
        setNfSocket: (state, action) => {
            console.log('socketReducer.setNfSocket was called.');
            state.nfSocket = action.payload;
        },
        setChatSocket: (state, action) => {
            console.log('socketReducer.setChatSocket was called. chatSocket=' + action.payload);
            state.chatSocket = action.payload;
        },
        setChatSocketConnected: (state, action) => {
            console.log(`setChatSocketConnected, old=${state.isChatSocketConnected}, new=${action.payload}`);
            state.isChatSocketConnected = action.payload;
        },
        setNfSocketConnected: (state, action) => {
            state.isNfSocketConnected = action.payload;
        }
    }
});

export const { setNfSocket, setChatSocket, setNfSocketConnected, setChatSocketConnected} = socketSlice.actions;
export const getNfSocket = (state) => state.socket.nfSocket;
export const getChatSocket = (state) => state.socket.chatSocket;
export const IsNfSocketConnected = (state) => state.socket.isNfSocketConnected;
export const IsChatSocketConnected = (state) => state.socket.isChatSocketConnected;

export default socketSlice.reducer;
import React, { useEffect, useRef, useState} from "react";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { memoizedGetAuthUser, setAuth } from '../../Store/Reducer/authReducer';
import { format_time_mm_ss, getAvatarUrl, validateForm } from '../../Utils/utils';
import ApiService from "../../Core/Service/ApiService";
import { 
    MDBModal,
    MDBModalDialog,
    MDBModalBody,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBBtn,
} from "mdb-react-ui-kit";
import { IsChatCreated, memoizedGetChat, removeChat } from "../../Store/Reducer/chatReducer";
import { IsChatSocketConnected, getChatSocket } from "../../Store/Reducer/socketReducer";
import { CHAT_WAIT_TIMEOUT, ChatStatus, RecvMessages, SendMessages, UserRole } from "../../Constants/constants";
import { useContext } from "react";
import ModalContext from "../../Context/ModalContext";

const WaitChatModal = React.memo((props) => {
    const sockMsgHandlerKey = 'wait_chat_modal';
    const modalContext = useContext(ModalContext);
    const setOpen = modalContext.setOpenWaitChatModal;
    const authUser = useSelector(memoizedGetAuthUser);
    const isChatCreated = useSelector(IsChatCreated);
    const chat = useSelector(memoizedGetChat);
    const {advisor} = chat ?? {};
    const dispatch = useDispatch();
    const chatSocket = useSelector(getChatSocket);
    const [remainTime, setRemainTime] = useState(0);
    const [errorMsg, setErrorMsg] = useState('');
    const timerRef = useRef(null);
    const [cancelling, setCancelling] = useState(false);

    useEffect(()=>{
        const open = isChatCreated && chat.status === ChatStatus.WAITING && authUser.role === UserRole.CLIENT;
        if(open) {
            setTimeout(()=>{
                setCancelling(false);
                setOpen(true);
            }, 500)
        } else {
            setOpen(false);
            setErrorMsg('');
            setRemainTime(CHAT_WAIT_TIMEOUT);
        }
    }, [isChatCreated, chat?.status])

    useEffect(()=> {
        if(modalContext.openWaitChatModal) {
            setRemainTime(Math.max(0, CHAT_WAIT_TIMEOUT - Math.floor((new Date().getTime() - chat?.createdAt) / 1000)));

            if(timerRef.current) clearInterval(timerRef.current);
            timerRef.current = setInterval(() => {
                const now = new Date().getTime();
                const remainTime = Math.max(0, CHAT_WAIT_TIMEOUT - Math.floor((now - chat?.createdAt) / 1000));
                setRemainTime(remainTime);

                if(remainTime == 0) {
                    clearInterval(timerRef.current);
                    timerRef.current = null;
                    dispatch(removeChat());
                }
            }, 1000);

            if(chatSocket) {
                chatSocket.on(RecvMessages.CHAT_INFO, sockMsgHandlerKey, (chatInfo) => {
                });
            
                return () => {
                    chatSocket.off(RecvMessages.CHAT_INFO, sockMsgHandlerKey);
                    if(timerRef.current) clearInterval(timerRef.current);
                };
            }
        }
    }, [modalContext.openWaitChatModal, chatSocket]);

    const closeModal = () => {
        if(chatSocket) {
            chatSocket.emit(SendMessages.CANCEL_CHAT, {
                chatId: chat?.chatId
            });
            setCancelling(true);
        }
        // don't remove chat here, we would remove it upon receiving CANCEL_CHAT response from the server. 
        // dispatch(removeChat({
        //     reason: 'cancelled by client.'
        // }));
    };

    return (
        <MDBModal   className="wait-chat-modal" 
                    open={modalContext.openWaitChatModal} 
                    setOpen={setOpen} 
                    closeOnEsc={false}
                    staticBackdrop 
                    tabIndex='-1'
        >
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader className='d-flex justify-content-center'>
                        <MDBModalTitle className="">

                        </MDBModalTitle>
                        <MDBBtn tabIndex='-1' className='btn-close' color='none' onClick={closeModal}></MDBBtn>
                    </MDBModalHeader>

                    <MDBModalBody className='d-flex p-4 flex-column justify-content-center align-items-center'>
                        { advisor && <>
                            <img src={getAvatarUrl(advisor?.avatar)} className="advisor-avatar" />
                            <h5 className="mt-2">{ advisor?.username }</h5>
                            { errorMsg === '' && <>
                                <p className="text-center align-items-center">
                                    {cancelling ? 'We are cancelling the chat...' : 'We are connecting you with your advisor now...'}
                                </p>
                            </>}
                            { errorMsg != '' && <>
                                <p className="d-flex justify-content-center align-items-center error">
                                    {errorMsg}
                                </p>
                            </>}
                            <div className="spent-time">{format_time_mm_ss(remainTime)}</div>
                        </>}

                        {chat?.freeMinutes > 0 &&
                        <div className="coupon d-flex justify-content-center align-items-center" title="Free minutes">
                            {chat?.freeMinutes}
                        </div>
                        }
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
});

export default WaitChatModal;
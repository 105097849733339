import React from "react";
import styles from './AdminTable.scss'

const AdminTable = React.memo((props) => {
    const {columns, rows, children} = props;

    const onRowSelected = (rowIndex, row) => {
        props.onRowSelected && props.onRowSelected(rowIndex, row);
    }
    const onTdClicked = (row, colname) => {
        props.onTdClicked && props.onTdClicked(row, colname);
    }

    const renderHeader = () => {
        return (
            <thead><tr>
                {columns.map((col, index) => (
                    <th key={index} style={col.style}>{col.title}</th>
                ))}
            </tr></thead>
        )
    }

    const renderBody = () => {
        if(rows?.length > 0) {
            return (
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr key={rowIndex} onClick={(e)=>onRowSelected(rowIndex, row)}>
                            { columns.map((col, colIndex) => {
                                if(children) {
                                    const el = children(row, col.name, rowIndex, colIndex)
                                    if(el) return <td key={colIndex} onClick={(e)=>onTdClicked(row, col.name)} >{el}</td>
                                }
                                return <td key={colIndex} onClick={(e)=>onTdClicked(row, col.name)}>{row[col.name]}</td>
                            })}
                        </tr>
                    ))}
                </tbody>
            )
        } else {
            return (
                <tbody>
                    <tr><td colSpan={columns.length} className="text-center">
                        No Data
                    </td></tr>
                </tbody>
            )
        }
    }

    return (
        <div className="admin-table-container">
            <table className="w-100 admin-table" style={props.style}>
                {renderHeader()}
                {renderBody()}
            </table>
        </div>
    )
});

export default AdminTable;
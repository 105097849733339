import React, {useEffect, useRef, useState} from "react";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBCol, MDBContainer,
        MDBFile,
        MDBIcon,
        MDBInput, MDBRow,MDBTextArea} from "mdb-react-ui-kit";
import { toast } from "react-toastify";
import SVG from 'react-inlinesvg';
import ImageSelector from "../../../Components/ImageSelector/ImageSelector";
import { getAvatarUrl, getBackendAssetUrl } from "../../../Utils/utils";
import FileSelector from "../../../Components/FileSelector/FileSelector";

const ApplicantDocument = (props) => {
    const {formData, setFormData, inputErrors} = props;

    const onIdCardFrontSelected = (file) => {
        setFormData({
            ...formData,
            new_id_front_file: file
        });
        return true;
    }
    const onIdCardBackSelected = (file) => {
        setFormData({
            ...formData,
            new_id_back_file: file
        });
        return true;
    }
    const onNewCertSelected = (file) => {
        setFormData({
            ...formData,
            new_cert_files: [
                ...formData.new_cert_files,
                file
            ]
        });
        return false;
    }
    const onCertDelete = (e, cert_index) => {
        e.preventDefault();
        formData.cert_files.splice(cert_index, 1);
        setFormData({
            ...formData,
        })
    }
    const onNewCertDelete = (e, index) => {
        e.preventDefault();
        formData.new_cert_files.splice(index, 1);
        setFormData({
            ...formData,
        })
    }
    const onTaxSelected = (file) => {
        setFormData({
            ...formData,
            new_tax_file: file
        })
        return true;
    }

    const renderGovId = () => {
        return (
        <MDBCard className='id-card'>
            <MDBCardHeader className="text-center">
                <strong>Government Identification </strong>
            </MDBCardHeader>
            <MDBCardBody className="py-0">
                <MDBRow className="w-100 m-0">
                    <MDBCol md='6' className="d-flex flex-column justify-content-center align-items-center" id='gov_photo_id_front'>
                        <div className="label d-flex align-items-center mt-1">
                            <span className="required">Front</span>
                            {inputErrors?.gov_photo_id_front && <span className="error ms-2">{ inputErrors?.gov_photo_id_front }</span>}
                        </div>
                        <div style={{position:'relative'}}>
                            {formData.gov_photo_id_front && !formData.new_id_front_file && (
                                <div className="submitted-icon text-center">
                                    <span className="ms-2 fw-bold">Submitted</span>
                                </div>
                            )}
                            {(()=>{
                                if(!props.readonly) {
                                    return (
                                        <FileSelector 
                                            selectorImage={'/img/certs/id_card.png' }
                                            onFileSelected={onIdCardFrontSelected} 
                                        />
                                    )
                                } else {
                                    return <img src='/img/certs/id_card.png' />
                                }
                            })()}
                        </div>
                    </MDBCol>
                    <MDBCol md='6' className="d-flex flex-column justify-content-center align-items-center" id='gov_photo_id_backend'>
                        <div className="label d-flex align-items-center mt-1">
                            <span className="required">Back</span>
                            {inputErrors?.gov_photo_id_backend && <span className="error ms-2">{ inputErrors?.gov_photo_id_backend }</span>}
                        </div>
                        <div style={{position:'relative'}}>
                            {formData.gov_photo_id_backend && !formData.new_id_back_file && (
                                <div className="submitted-icon text-center">
                                    <span className="ms-2 fw-bold">Submitted</span>
                                </div>
                            )}
                            {(()=>{
                                if(!props.readonly) {
                                    return (
                                        <FileSelector 
                                            selectorImage={'/img/certs/id_card.png'}
                                            onFileSelected={onIdCardBackSelected} 
                                        />
                                    )
                                } else {
                                    return <img src='/img/certs/id_card.png' />
                                }
                            })()}
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
        )
    }
    const renderCerts = () => {
        return (
        <MDBCard className="cert-files mt-4">
            <MDBCardHeader className="text-center">
                <strong>Certificates</strong>
            </MDBCardHeader>
            <MDBCardBody className="py-0">
                <MDBRow className="w-100 m-0">
                    {(()=>{
                        if(!props.readonly) {
                            return (
                                <MDBCol md='6' className="d-flex justify-content-center align-items-center mt-3 add-cert-file">
                                    <FileSelector selectorImage='/img/certs/cert.png' onFileSelected={onNewCertSelected} selectorImageReactive={false} />
                                </MDBCol>
                            )
                        } else {
                            if(formData.cert_files.length == 0) {
                                return <div className="d-flex justify-content-center align-items-center">
                                    <SVG src='/img/icons/no_found.svg' style={{height:40, width: 40}} /> 
                                    <span className="ms-2">No Uploaded Certificates</span>
                                </div>
                            }
                        }
                    })()}

                    {formData.cert_files.map((fileUrl, index) => (
                        <MDBCol md='6' key={index} className="d-flex justify-content-center align-items-center mt-3 cert-file">
                            <div className="img-container position-relative">
                                <a><img key={index} src='/img/certs/document.png' alt='Certificate' /></a>
                                <div className="cert-delete cursor-pointer" onClick={(e) => onCertDelete(e, index)} title='Remove'>
                                    <MDBIcon color="danger" size="lg" far icon="trash-alt" />
                                </div>
                                <div className="submitted-icon text-center">
                                    <span className="ms-2 fw-bold">Submitted</span>
                                </div>
                            </div>
                        </MDBCol>
                    ))}
                    {!props.readonly && formData.new_cert_files?.map((file, index) => (
                        <MDBCol md='6' key={index} className="d-flex justify-content-center align-items-center mt-3 cert-file">
                            <div className="img-container d-flex flex-column align-items-center">
                                <div className="position-relative">
                                    <img 
                                        key={index} 
                                        src={file.type.startsWith('image/') ? URL.createObjectURL(file) : '/img/certs/cert.png'} 
                                        alt='Certificate' 
                                    />
                                    <div className="cert-delete cursor-pointer" onClick={(e) => onNewCertDelete(e, index)}>
                                        <MDBIcon far icon="trash-alt" color="danger" size="lg" />
                                    </div>
                                </div>
                                <div className="mt-1">File: {file.name}</div>
                            </div>
                        </MDBCol>
                    ))}
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
        )
    }
    const renderTax = () => {
        return (
        <MDBCard className="tax-file mt-4" id="us_tax_norms">
            <MDBCardHeader>
                <div className="d-flex justify-content-center align-items-center w-100">
                    <strong className="required">Tax Form</strong>
                    {inputErrors?.us_tax_norms && <span className="error ms-2">{ inputErrors?.us_tax_norms }</span>}
                </div>
            </MDBCardHeader>
            <MDBCardBody className="d-flex flex-column justify-content-center align-items-center py-0">
                <div className="mb-2">
                    Please download 
                    <a href="/applicant/fw8ben.pdf" className="text-underline mx-1" target="_blank">W-8BEN</a>or 
                    <a href="/applicant/fw9.pdf" className="text-underline mx-1" target="_blank">W-9</a> 
                    forms to comply with U.S. tax. Print out, fill in, sign and upload.
                </div>
                <div style={{position:'relative'}}>
                    {formData.us_tax_norms && !formData.new_tax_file && (
                        <div className="submitted-icon text-center">
                            <span className="ms-2 fw-bold">Submitted</span>
                        </div>
                    )}
                    {(()=>{
                        if(!props.readonly) {
                            return <FileSelector selectorImage='/img/certs/tax.png' onFileSelected={onTaxSelected} />
                        } else {
                            return <img src='/img/certs/tax.png' />
                        }
                    })()}
                </div>
            </MDBCardBody>
        </MDBCard>
        )
    }

    return <MDBContainer breakpoint="lg" className="d-flex justify-content-center">
        {formData &&
            <div className="application-document-form w-100">
                {renderGovId()}
                {renderTax()}
                {renderCerts()}
            </div>
        }
    </MDBContainer>
}

export default ApplicantDocument;
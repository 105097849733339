import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBContainer, MDBIcon } from "mdb-react-ui-kit";
import React from "react";
import styles from './AdvisorEdit.scss';
import AdvisorPersonalData from "./MyPersonalData";
import { useSelector } from "react-redux";
import { IsAuthenticated, memoizedGetAuthUser } from "../../../Store/Reducer/authReducer";
import { useEffect } from "react";
import ApiService from "../../../Core/Service/ApiService";
import { useState } from "react";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import ApplicantDocument from "./ApplicantDocument";
import { toast } from "react-toastify";
import { PendingStatus, UserRole, UserStatus } from "../../../Constants/constants";
import { useNavigate } from "react-router-dom";
import { validateForm } from "../../../Utils/utils";

const AdvisorContactEdit = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const isAuthenticated = useSelector(IsAuthenticated);
    const authUser = useSelector(memoizedGetAuthUser);
    const [formData, setFormData] = useState(null);
    const rules = {
        legalname: {required: 'string'},
        mobile: {required: 'string'},
        birth: {required: 'date'},
        country: {required: 'string'},
        state: {required: 'string'},
        city: {required: 'string'},
        address: {required: 'string'},
    }
    const [inputErrors, setInputErrors] = useState({});

    useEffect(()=>{
        if(isAuthenticated && authUser) {
            setLoading(true);

            ApiService.get('/advisor/auth/' + authUser._id).then(result=>{
                setFormData({
                    ...result.data.advisor.mirror,
                    cert_files: result.data.advisor.mirror.cert_files?.length > 0 ? [...result.data.advisor.mirror.cert_files] : [],       // it not use this way, instead copy directly, then go with reference.
                    new_cert_files: []
                });
            })
            .finally(()=>{
                setLoading(false);
            })
        }
    }, [isAuthenticated]);

    const onSave = () => {
        const valid_ret = validateForm(formData, rules);

        if(!valid_ret.result) {
            setInputErrors(valid_ret.errors);
        } else {
            setInputErrors({});
            setLoading(true);

            const postData = new FormData();
            Object.keys(formData).forEach(k=>{
                if(k == 'new_cert_files') {
                    if(formData[k]?.length > 0) {
                        formData[k].forEach(ncf=>{
                            postData.append('new_cert_files', ncf);
                        })
                    }
                } else {
                    postData.append(k, formData[k]);
                }
            })
            const headers = {'Content-Type': 'multipart/form-data'};
            ApiService.post('/advisor/auth/' + authUser._id, postData, headers).then(result => {
                setFormData({
                    ...result.data.advisor.mirror,
                    cert_files: result.data.advisor.mirror.cert_files?.length > 0 ? [...result.data.advisor.mirror.cert_files] : [],       // it not use this way, instead copy directly, then go with reference.
                    new_cert_files: []
                });
                toast.success(result.data.message)
            })
            .finally(()=>
            {
                setLoading(false);
            });
        }
    }

    const goBack = (e) => {
        e.preventDefault();
        navigate(-1);
    }

    const renderPersonalInfo = () => {
        return (
            <MDBCard>
                <MDBCardHeader>
                    <MDBCardTitle>
                        <MDBIcon fas icon="user-circle" size="lg" />
                        <span className="accordion-item-title">My Personal Information</span>    
                        <span className="notify">(Not public to clients)</span>
                    </MDBCardTitle>
                </MDBCardHeader>
                <MDBCardBody>
                    <AdvisorPersonalData formData={formData} setFormData={setFormData} inputErrors={inputErrors} />
                </MDBCardBody>
            </MDBCard>
        )
    }
    const renderDocuments = () => {
        return (
            <MDBCard className="mt-4">
                <MDBCardHeader>
                    <MDBIcon fas icon="id-card" size="lg" />
                    <span className="accordion-item-title">My Application Documents</span>    
                    <span className="notify">(Not public to clients)</span>
                </MDBCardHeader>
                <MDBCardBody>
                    <ApplicantDocument 
                        formData={formData} 
                        readonly={true}
                    />
                </MDBCardBody>
            </MDBCard>
        )
    }

    return <MDBContainer breakpoint="lg" className="advisor-edit-container">
        {renderPersonalInfo()}
        { authUser.status === UserStatus.APPROVED && renderDocuments() }

        <div className="fixed-action-bar mt-4">
            <div className="d-flex justify-content-center align-items-center">
                <MDBBtn onClick={goBack} color="default" style={{background: '#fefefe'}}>Back</MDBBtn>
                <MDBBtn className="ms-4 confideas-primary-btn" tabIndex={-1} onClick={onSave}>Save</MDBBtn>
            </div>
        </div>

        {loading && <LoadingIndicator />}
    </MDBContainer>
}

export default AdvisorContactEdit;
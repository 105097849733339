import React, { useEffect } from 'react';
import styles from './PrivacyPolicy.scss';
import { MDBContainer, MDBIcon } from 'mdb-react-ui-kit';
import { scrollUpTop } from '../../../Utils/utils';
import InfoCenterNavBar from '../NavBar/NavBar';

const PrivacyPolicy = React.memo((props) => {
    useEffect(()=>{
        scrollUpTop();
    }, []);

    return <MDBContainer breakpoint='lg' className='privacy-policy p-4'>
        <h3 className='text-center title'>Privacy Policy</h3>
        <p>
            Welcome to Confideas (“us”, “we”, or “our”), where your privacy and trust
            are of utmost importance to us. 
            This Privacy Policy outlines how we collect,
            use, and safeguard your personal information when you use our platform.
        </p>

        <h5 className='sub-title'>Information Collection:</h5>
        <h6 className='sub-title-2'>For Users:</h6>
        <div className='policy-item'>
            Users have the option for anonymity during registration to protect
            their privacy. We may collect certain personally identifiable
            information from users, such as email addresses, for registration
            purposes. If users choose to update their user contact profile, this
            information will be considered as user data with their consent.
        </div>
        <div className='policy-item'>
            Additional information may be requested from users during
            one-on-one chat sessions, but disclosure is optional and at the user's
            discretion. The information exchanged during these live chat sessions
            between users and advisors will be logged in the live chat transcripts.
        </div>
        <div className='policy-item'>
            Kindly be aware that although you may supply us with credit card or
            financial particulars to access our services, we do not manage,
            process, or store such information. Transactions for our services are
            conducted through carefully selected third-party payment processors
            such as PayPal or Stripe. Any personal data shared with these
            third-party payment services is strictly governed by their respective
            terms of service. We retain the exclusive discretion to select
            alternative third-party payment services.
        </div>

        <h6 className='sub-title-2'>For Advisors:</h6>
        <div className='policy-item'>
            Advisors are required to disclose their identity for verification
            purposes. This may include providing photo identification or other
            identity-verifying documents, which will be securely stored in our
            databases.
        </div>
        <div className='policy-item'>
            Advisors are also required to provide tax-related documents, such as
            a Form W-9 or Form W-8BEN, in compliance with legal requirements.
        </div>
        <div className='policy-item'>
            If necessary, we might gather a range of details, including your email
            address, login name, full name, PayPal p`ayer ID, PayPal email
            address, Stripe ID, IP address, geographic location, profile photo,
            favorite advisors list, reviews, live chat transcripts, and information
            from your customer service interactions.`
        </div>
        <div className='policy-item'>
            It is the responsibility of advisors to provide us with the most current
            and accurate information.
        </div>

        <h5 className='sub-title'>Information Usage:</h5>
        <p>
            The information collected serves various purposes aimed at improving our
            platform. All communications on Confideas are stored and used for
            analytics purposes, quality assurance, and to maintain long-term
            relationships between clients and advisors. For advisors, it ensures
            compliance with regulations and accurate identity verification. Similarly,
            user data is utilized to personalize services, provide timely updates, and
            enhance satisfaction. User information is also employed to study consumer
            behavior for marketing purposes, such as tailoring promotional offers or
            newsletters.
        </p>

        <h5 className='sub-title'>Information Sharing:</h5>
        <p>
            We prioritize the protection of your personal information and do not sell,
            trade, or transfer it to third parties without your explicit consent. However,
            we may disclose information to trusted third-party service providers who
            assist us in operating our platform, subject to strict confidentiality
            agreements.
        </p>

        <h5 className='sub-title'>Data Security:</h5>
        <p>
            We implement a variety of security measures to maintain the safety of your
            personal information. All sensitive information transmitted on ConfIdeas is
            encrypted using secure socket layer technology (SSL). While we strive to
            implement comprehensive security measures, it is crucial to acknowledge
            that no security system is entirely infallible. Therefore, we cannot guarantee
            the absolute security of your information.
        </p>

        <h5 className='sub-title'>Rights under GDPR for EU Users:</h5>
        <p>
            EU users can protect their data under GDPR (General Data Protection
            Regulation) by exercising their rights. They can request access to their
            personal data, seek corrections or updates as needed, and request the
            deletion of their data if necessary. Additionally, EU users can opt to limit or
            restrict the processing of their data and request its transfer to another
            entity. These rights can be exercised by contacting us.
        </p>

        <h5 className='sub-title'>Rights under CCPA for California Residents:</h5>
        <p>
            If you are a California resident, as of January 2020, you have specific rights
            regarding your personal information under the California Consumer Privacy
            Act of 2018 (“CCPA”). Please note that we do not sell your information, and
            we are committed to upholding your rights under the CCPA.
        </p>

        <h5 className='sub-title'>Log Data:</h5>
        <p>
            We may collect log data when you access our services. This data may
            include informat`ion such as your IP address, browser type, operating
            system, pages visited, and timestamps. We use this information for
            analytical purposes and to improve the functionality of our services.`
        </p>

        <h5 className='sub-title'>Cookies:</h5>
        <p>
            Our services may use cookies to enhance your browsing experience.
            Cookies are small text files stored on your device that contain data about
            your browsing behavior. These cookies may be used for purposes such as
            authentication, preferences, and analytics. You can manage your cookie
            preferences through your browser settings.
        </p>

        <h5 className='sub-title'>Third-Party Links:</h5>
        <p>
            Our services may contain links to third-party websites or services that are
            not operated by us. We are not responsible for the privacy practices or
            content of these third-party sites. We encourage you to review the privacy
            policies of these sites before providing any personal information.
        </p>

        <h5 className='sub-title'>Communication and Opt-Out Option:</h5>
        <p>
            We may contact you via email with newsletters, marketing materials,
            promotional offers, and other relevant information that may interest you.If
            you no longer wish to receive communications from us or prefer not to
            communicate with us, you have the option to opt out by clicking on the
            unsubscribe link provided in our email communications.
        </p>

        <h5 className='sub-title'>Do Not Track:</h5>
        <p>
            We do not currently support Do Not Track (DNT). Users have the option to
            enable or disable DNT in their browser settings.
        </p>

        <h5 className='sub-title'>International Data Transfer:</h5>
        <p>
            Your personal information may be transferred to and processed in countries
            outside of your home country, including the United States, where our
            servers, service providers, or advisors are located. This means that data
            protection and privacy laws in these countries may differ from those in your
            jurisdiction. By using our services, you consent to such transfer.
        </p>

        <h5 className='sub-title'>Children’s Privacy:</h5>
        <p>
            We do not knowingly collect personal information from children under the
            age of 13. 
            If we become aware that we have inadvertently collected
            personal information from a child under 13, we will take steps to delete it as
            soon as possible. 
            If you believe that we may have collected personal
            information from a child under 13, please contact us immediately.
        </p>

        <h5 className='sub-title'>Changes to This Policy:</h5>
        <p>
            Any changes or updates to this Privacy Policy will be posted on this page. 
            If you have any questions or concerns about our Privacy Policy, please
            contact us at contact@confideas.com.
        </p>

        <InfoCenterNavBar />
    </MDBContainer>
});

export default PrivacyPolicy;

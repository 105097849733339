import React, {useContext, useEffect, useRef} from "react";
import { toast } from "react-toastify";
import ModalContext from "../../Context/ModalContext";
import EventContext from "../../Context/EventContext";
import { useState } from "react";
import './FileSelector.scss';

const FileSelector = React.memo((props) => {
    const photoRef = useRef(null);
    const fileInputRef = useRef(null);
    const [file, setFile] = useState();

    const onImageClick = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    }

    const onFileSelected = (e) => {
        const file = e.target.files[0];
        if(!file) return;

        if (file.type.startsWith('image/') && props.selectorImageReactive != false) {
            let reader = new FileReader();
            reader.onload = function() {
                const img = new Image();
                img.onload = function() {
                    photoRef.current.src = reader.result;
                }
                img.src = reader.result;
            }
            reader.readAsDataURL(file);
        } else {
            photoRef.current.src = props.selectorImage;
        }

        if(props.onFileSelected && !props.onFileSelected(file)) {
            return;
        }

        setFile(file);
    }

    return <div className="file-selector d-flex flex-column align-items-center justify-content-center">
        {file && (
            <div>
                <span className="ms-1">{file.name}</span>
            </div>
        )}
        <img    src={props.selectorImage} 
                onClick={onImageClick} 
                ref={photoRef} 
                style={{cursor: 'pointer'}} 
        />
        <input  type="file" 
                className="hidden" 
                ref={fileInputRef} 
                onChange={onFileSelected}
                accept={props.file_filter ?? ".*"}
                style={{ display: 'none' }}  
        />
    </div>
})

export default FileSelector;
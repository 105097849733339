import { MDBBtn } from "mdb-react-ui-kit";
import React from "react";
import { useNavigate } from "react-router-dom";

const AdvisorSignupThanks = React.memo((props) => {
    const navigate = useNavigate();

    return (
        <div className="d-flex justify-content-center w-100">
            <div className="advisor-signup-thanks w-90 d-flex flex-column align-items-center">
                <div className="thank-you">Thank You !</div>
                <div className="message">
                    <div>
                        Thank you very much for taking your time to complete your application.
                        You will hear from us about the results shortly.
                    </div>
                </div>
                <div>
                    <img src='/img/icons/green_check.png' style={{width:200, height: 200}}/>
                </div>
                <div className="mt-4">
                    <MDBBtn className="confideas-primary-btn" onClick={e=>navigate('/')}>Go to Home Page</MDBBtn>
                </div>
            </div>
        </div>        
    )
});

export default AdvisorSignupThanks;
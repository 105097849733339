import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdb-react-ui-kit";
import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = React.memo((props) => {
    const { breadcrumb } = props;
    const style = {
        backgroundColor: 'rgb(241,250,255)',
        borderRadius: 5,
        border: '1px dashed #0077ff'
    }

    if(breadcrumb?.length > 0) {
        return <div className="mb-4 d-flex px-4 pt-3" style={style}>
            <label className="me-2">Position : </label>
            <MDBBreadcrumb>
                {breadcrumb.map((bc, index) => (
                    <MDBBreadcrumbItem active={index == breadcrumb.length - 1} key={index}>
                        {bc.url && <Link to={bc.url}>{bc.title}</Link> }
                        {!bc.url && <span>{bc.title}</span>}
                    </MDBBreadcrumbItem>
                ))}
            </MDBBreadcrumb>
        </div>
    } else {
        return <></>
    }
})

export default Breadcrumb;
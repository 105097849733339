import React, { useEffect, useState } from "react";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBIcon, MDBInput, MDBRow, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from 'mdb-react-ui-kit';
import SVG from 'react-inlinesvg';
import { PaymentKind } from "../../../Constants/constants";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { IsAuthenticated, memoizedGetAuthUser, updateAuth } from "../../../Store/Reducer/authReducer";

const AdvisorPayoutMethodEdit = React.memo(props=>{
    const {formData, setFormData, inputErrors} = props;
    const [activeTab, setActiveTab] = useState(PaymentKind.PAYPAL);

    const handlePaypalInputChange = (e) => {
        setFormData({
            ...formData,
            paypal_email: e.target.value
        })
    }

    const payoutKinds = [
        // {
        //     name:PaymentKind.STRIPE,
        //     title: 'Stripe',
        //     icon: 'stripe-s'
        // },
        {
            name: PaymentKind.PAYPAL,
            title: 'Paypal',
            icon: 'paypal'
        }
    ]

    return <>
    <MDBCard className="advisor-payment-method-edit-card">
        <MDBCardHeader className="d-flex align-items-center">
            <div className="d-flex">
                <SVG src='/img/icons/debit-card.svg' fill="#00c4ff" height={25} />
                <span className="px-2"><strong>My Payout Method</strong></span>
            </div>
        </MDBCardHeader>
        <MDBCardBody>
            <MDBTabs>
                <MDBTabsItem>
                    {payoutKinds.map((pk, index)=>(
                        <MDBTabsLink key={index} 
                            active={activeTab === pk.name} 
                            className="d-flex align-items-center"
                        >
                            <MDBIcon fab icon={pk.icon} size="lg" className="me-2" />{pk.title}
                        </MDBTabsLink>
                    ))}
                </MDBTabsItem>
            </MDBTabs>

            <MDBTabsContent>
                <MDBTabsPane open={activeTab === PaymentKind.PAYPAL} className="p-4">
                    <div style={{maxWidth:400}} id="paypal_email">
                        <MDBInput 
                            name='paypal_email' 
                            label='Paypal Email' 
                            value={formData?.paypal_email} 
                            onChange={handlePaypalInputChange}
                        />
                        {inputErrors?.paypal_email && <div className="error">{ inputErrors?.paypal_email }</div>}
                    </div>
                </MDBTabsPane>
            </MDBTabsContent>
        </MDBCardBody>
    </MDBCard>
    </>
})

export default AdvisorPayoutMethodEdit;
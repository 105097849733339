import React, { useContext, useEffect, useRef, useState} from "react";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle, MDBRow, MDBSpinner } from "mdb-react-ui-kit";
import { IsAuthenticated, memoizedGetAuthUser, updateAuth } from "../../Store/Reducer/authReducer";
import LoadingIndicator from "../../Components/LoadingIndicator/LoadingIndicator";
import ApiService from "../../Core/Service/ApiService";
import { floatV, format_time_mm_ss, getFirstDateOfNextMonth, scrollUpTop } from "../../Utils/utils";
import { IsChatSocketConnected, getChatSocket } from "../../Store/Reducer/socketReducer";
import { ChatStatus, SendMessages, UserRole } from "../../Constants/constants";
import { IsChatCreated, memoizedGetChat } from "../../Store/Reducer/chatReducer";
import { setPaymentRequest } from "../../Core/Service/JwtService";
import ChatContext from "./ChatContext";
import PaypalCheckOut from "../../Components/Paypal/PaypalCheckOut";

const LackBalanceClientModal = React.memo((props)=>{
    const chatContext = useContext(ChatContext);
    const endChatSession = chatContext.endChatSession;
    const [loading, setLoading] = useState(false);
    const [paying, setPaying] = useState(false);
    const [paid, setPaid] = useState(false);
    const [topup, setTopUp] = useState(false);
    const chattimes = [5, 10, 20];
    const [selTime, setSelTime] = useState(10);
    const [lastPayment, setLastPayment] = useState(null);
    const isAuthenticated = useSelector(IsAuthenticated);
    const authUser = useSelector(memoizedGetAuthUser);
    const dispatch = useDispatch();
    const chatSocket = useSelector(getChatSocket);
    const isChatCreated = useSelector(IsChatCreated);
    const chat = useSelector(memoizedGetChat);
    const [remainTime, setRemainTime] = useState(0);
    const navigate = useNavigate();
    const setOpen = chatContext.setOpenLackBalanceModal;
    const timerRef = useRef(null);
    const [paymentTokens, setPaymentTokens] = useState();
    const openTimerRef = useRef(null);

    useEffect(()=>{
        const open = authUser.role === UserRole.CLIENT && chat?.status === ChatStatus.PAUSED && !paying;

        openTimerRef.current && clearTimeout(openTimerRef.current);
        openTimerRef.current = setTimeout(()=>{
            openTimerRef.current = null;

            scrollUpTop();
            setOpen(open);

            if(open) {
                setLoading(true);
                ApiService.get('/client/paymentTokens').then((response) => {
                    setPaymentTokens(response.data);
                })
                .finally(()=>{
                    setLoading(false);
                });
            }
        }, 500);
    }, [chat?.status])

    useEffect(()=>{
        if(chatContext.openLackBalanceModal) {
            setTopUp(false);
            setPaid(false);
        }
    }, [chatContext.openLackBalanceModal]);

    useEffect(()=>{
        if(chatContext.openLackBalanceModal) {
            if(timerRef.current) clearInterval(timerRef.current);
            setRemainTime(Math.max(0, 120 - Math.floor((new Date().getTime() - chat.current_session_pausedAt) / 1000)));

            timerRef.current = setInterval(() => {
                if(chat.status === ChatStatus.PAUSED && !paid) {
                    const now = new Date().getTime();
                    const remainTime = Math.max(0, 120 - Math.floor((now - chat.current_session_pausedAt) / 1000));
                    setRemainTime(remainTime);

                    if(remainTime == 0) {
                        console.log('lack_balance_pending_timeout');
                        endChatSession();
                        clearInterval(timerRef.current);
                    } 
                }
            }, 1000);

            return () => {
                if(timerRef.current) clearInterval(timerRef.current);
            }
        }
    }, [chatContext.openLackBalanceModal, paid]);

    const topUp = (e) => {
        e.preventDefault();
        setTopUp(true);
    }

    const closeModal = () => {
        setOpen(false);
    }

    const onChatTimeSelected = (e, time) => {
        e.preventDefault();
        setSelTime(time);
    }

    const renderChatTime = () => {
        return chattimes.map((time, index) => (
            <MDBCol size='4' key={index}>
                <MDBCard className={`chat-duration-card ${time === selTime ? 'selected' : ''}`} onClick={(e) => onChatTimeSelected(e, time)}>
                    <MDBCardBody>
                        <div className="d-flex flex-column justify-content-center align-items-center duration-info">
                            <div className="chat-time">{time}</div>
                            <div className="unit-minute">minutes</div>
                            <div className="money mt-4">${floatV(time * chat?.advisor?.rate_per_min_chat)}</div>
                        </div>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        ))
    }

    const onPaymentSuccess = (balance) => {
        setPaid(true);
        dispatch(updateAuth({
            balance: floatV(parseFloat(balance))
        }));
        chatSocket.emit(SendMessages.RESUME_CHAT, {chatId: chat?.chatId});
    }

    const handleCheckout = async () => {
        const amount = chat?.advisor?.rate_per_min_chat * selTime;

        if(paymentTokens.credit_token) {
            setPaying(true);
            ApiService.post('/client/paypal/create_payment_creditcard_token', {amount}).then(response => {
                onPaymentSuccess(response.data.balance)
            })
            .finally(()=>{
                setPaying(false);
            });
        } else {
            closeModal();
            setPaymentRequest({
                purpose: 'continue_chat',
                chatId: chat.chatId
            });
            navigate('/client/checkout/' + amount);
        }
    }

    const renderPayment = () => {
        return (
            <MDBBtn className="confideas-primary-btn w-100" onClick={handleCheckout}>
                {(() => {
                    if(paying) {
                        return <div className="d-flex align-items-center justify-content-center">
                            <MDBSpinner size="sm" />
                            <span className="ms-2">Checking out...</span>
                        </div>
                    } else {
                        return <span>Checkout</span>
                    }
                })()}
            </MDBBtn>
        )
    }

    return <MDBModal className="lack-balance-modal" 
                    open={chatContext.openLackBalanceModal} 
                    setOpen={setOpen} 
                    closeOnEsc={false} 
                    staticBackdrop 
                    tabIndex='-1'>
        <MDBModalDialog>
            <MDBModalContent>
                <MDBModalHeader>
                    <div className="text-center w-100" style={{fontSize: '110%', color:'purple'}}><strong>Keep Chatting?</strong></div>
                </MDBModalHeader>
                <MDBModalBody className='d-flex p-4 flex-column justify-content-center align-items-center modal-body'>
                    { topup && <>
                        <div className="w-100 mt-3 amount-select-card p-2">
                            <div className="duration-select">
                                <MDBRow>
                                    {renderChatTime()}
                                </MDBRow>
                            </div>
                            {loading && <LoadingIndicator />}
                        </div>
                    </>}

                    <div className="action d-flex justify-content-center align-items-center mt-2 w-100">
                        {!topup && !paid && <div className="d-flex w-100 px-2">
                            <MDBBtn color="secondary" className="w-100"
                                    onClick={() => endChatSession()} 
                                    disabled={paying}
                            >
                                End Chat
                            </MDBBtn>
                            <MDBBtn className="w-100 confideas-primary-btn ms-2" 
                                    onClick={topUp} 
                            >
                                Top up
                            </MDBBtn>
                        </div>}

                        {topup && <div className="flex-fluid px-2">
                            { renderPayment() }
                        </div> }
                    </div>

                    <div className="spent-time mt-4">
                        {format_time_mm_ss(remainTime)}
                    </div>

                    {loading && <LoadingIndicator />}
                </MDBModalBody>
            </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
});

export default LackBalanceClientModal;
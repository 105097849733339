import { MDBSpinner } from 'mdb-react-ui-kit';
import React from 'react';
import styles from './LoadingIndicator.scss';

function LoadingIndicator(props){
    return <div className='loading-indicator d-flex justify-content-center align-items-center'>
        <MDBSpinner role='status'>
        </MDBSpinner>
        {props.message && <span role='status' className='ms-2'>{props.message}</span>}
    </div>
}

export default LoadingIndicator;
import React, { useEffect, useState } from "react";
import AdminTable from "../../../Components/AdminTable/AdminTable";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { Link, useNavigate } from "react-router-dom";
import styles from './Opinion.scss';
import { MDBBtn, MDBCheckbox, MDBIcon, MDBInput, MDBSwitch } from "mdb-react-ui-kit";
import Paginator from "../../../Components/Paginator/Paginator";
import { OpinionStatus, UserStatus } from "../../../Constants/constants";
import { toast } from "react-toastify";
import { getAvatarUrl, text2html } from "../../../Utils/utils";
import Moment from "react-moment";
import Select from "react-select";

const AdminOpinionList = React.memo((props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [opinions, setOpinions] = useState(null);
    const [search, setSearch] = useState('');
    const [formData, setFormData] = useState({
        search: '',
        perPage: 10,
        pageNum: 1,
        status: ''
    });
    const [total, setTotal] = useState(0);
    const columns = [
        {name: 'createdAt', title: 'Received At'},
        {name: 'username', title: 'Username'},
        {name: 'role', title: 'Role'},
        {name: 'content', title: 'Content'},
        {name: 'repliedBy', title: 'Who replied'},
        {name: 'repliedAt', title: 'Replied At'},
        {name: 'action', title: 'Action'}
    ];
    const perPageOpts = [
        { value: 10,label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" }
    ];
    const [curPerPageOpt, setCurPerPageOpt] = useState(perPageOpts[0]);
    const statusOpts = [
        {value: '', label: 'All'},
        {value: OpinionStatus.WAITING, label: 'Waiting'},
        {value: OpinionStatus.REPLIED, label: 'Replied'}
    ]
    const [curStatusOpt, setCurStatusOpt] = useState(statusOpts[0]);

    useEffect(()=>{
        getOpinions();
    }, [formData]);

    const getOpinions = () => {
        setLoading(true);

        ApiService.post('/admin/opinion/index', formData).then(result=>{
            setOpinions(result.data.opinions);
            setTotal(result.data.total);
        })
        .finally(()=>{
            setLoading(false);
        })
    }

    const onSearchChange = (e) => {
        setSearch(e.target.value);
    }
    const onSearchBtnClicked = (e) => {
        e.preventDefault();
        setFormData({
            ...formData,
            search,
            pageNum: 1
        })
    }
    const onSearchKeyDown = (e) => {
        if(e.key == 'Enter') {
            e.preventDefault();
            setFormData({
                ...formData,
                pageNum: 1,
                search
            });
        }
    }
    const onPageChanged = (pageNum) => {
        setFormData({
            ...formData,
            pageNum
        })
    }
    const onPerPageChanged = (opt) => {
        setFormData({
            ...formData,
            perPage: opt.value,
            pageNum: 1
        })
        setCurPerPageOpt(opt);
    }
    const onStatusChanged = (opt) => {
        setFormData({
            ...formData,
            status: opt.value,
            pageNum: 1
        })
        setCurStatusOpt(opt);
    }

    const handleEdit = (e, opinion) => {
        e.preventDefault();
        navigate('/admin/opinion/edit/' + opinion._id);
    }

    const breadcrumb=[{title: 'Opinion'}, {title: 'List'}];
    return (<>
        <Breadcrumb breadcrumb={breadcrumb} />
        <div className="admin-opinion-table">
            <div className="d-flex">
                <div className="px-2">
                    <label>Count : </label>
                    <span>{total}</span>
                </div>
                <div className="flex-fluid d-flex justify-content-end mb-2">
                    <div className="me-2">
                        <Select className="per-page"
                                    options={perPageOpts} 
                                    onChange={(opt) => onPerPageChanged(opt)} 
                                    defaultValue={curPerPageOpt}
                        />
                    </div>
                    <div className="me-2" style={{width:100}}>
                        <Select     options={statusOpts} 
                                    onChange={(opt) => onStatusChanged(opt)} 
                                    defaultValue={curStatusOpt}
                        />
                    </div>
                    <div className="d-flex align-items-center">
                        <MDBInput   type='text' 
                                    label='Search' 
                                    value={search}
                                    onChange={onSearchChange}
                                    onKeyDown={onSearchKeyDown}
                        />
                        <span className='mx-2 cursor-pointer' onClick={onSearchBtnClicked}>
                            <MDBIcon fas icon='search' />
                        </span>
                    </div>
                </div>
            </div>

            <AdminTable columns={columns} rows={opinions} style={{minWidth: 800}}>
                {(row, colname, rowIndex, colIndex) => {
                    switch(colname) {
                        case 'createdAt':
                            return <Moment format="YYYY-MM-DD">{row['createdAt']}</Moment>
                        case 'username':
                            return (
                                <div className="d-flex align-items-center">
                                    <img src={getAvatarUrl(row.uId.avatar)} className="round-avatar-50 me-2" />
                                    {row.uId.username} 
                                </div>
                            )
                        case 'role':
                            return row.uId.role;
                        case 'content':
                            return <div dangerouslySetInnerHTML={{ __html: text2html(row.content)}} className="opinion-content"></div>
                        case 'repliedBy':
                            return row.repliedBy?.username;
                        case 'repliedAt':
                            return row.repliedAt ? <Moment format="YYYY-MM-DD">{row.repliedAt}</Moment> : <></>
                        case 'action':
                            return (<>
                                <MDBIcon far icon="edit" color="warning" size="lg" className="cursor-pointer" onClick={(e)=>handleEdit(e, row)} />
                            </>)
                    }
                    return null;
                }}
            </AdminTable>

            <div className='d-flex justify-content-center mt-4'>
                <Paginator total={total} perPage={formData.perPage} onPageChanged={onPageChanged} />
            </div>
            {loading && <LoadingIndicator />}
        </div>
        </>
    )
});

export default AdminOpinionList;
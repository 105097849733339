import { MDBContainer } from "mdb-react-ui-kit";
import React from "react";
import ClientAdvisorChatHistory from "./AdvisorChatHistory";
import { useParams } from "react-router-dom";
import './AdvisorDetail.scss'
import ClientAdvisorStatistics from "./Statistics";

const ClientAdvisorDetail = React.memo((props) => {
    const params = useParams();
    const aId = params.aId;

    return (
        <MDBContainer breakpoint="lg" className="client-advisor-detail p-4">
            <ClientAdvisorStatistics aId={aId} />
            <ClientAdvisorChatHistory aId={aId} />
        </MDBContainer>
    )
});

export default ClientAdvisorDetail;
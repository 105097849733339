import { MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React, {useEffect} from "react";
import SVG from 'react-inlinesvg';
import MainInfo from "./MainInfo";
import AboutMe from "./AboutMe";
import Reviews from "./Reviews";
import ApiService from "../../../Core/Service/ApiService";
import { useState } from "react";
import { useParams } from "react-router-dom";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import { useSelector } from "react-redux";
import { getChangedAdvisors, getChangedAdvisorsHash256 } from "../../../Store/Reducer/changedAdvisorReducer";
import { getBackendAssetUrl } from "../../../Utils/utils";
import { memoizedGetAuthUser } from "../../../Store/Reducer/authReducer";
import { UserRole } from "../../../Constants/constants";

const AdvisorShow = React.memo((props) => {
    const params = useParams();
    const aId = params._id;
    const uName = params.uName;
    const [loading, setLoading] = useState(false);
    const [advisor, setAdvisor] = useState(null);
    const changedAdvisorHash = useSelector(getChangedAdvisorsHash256);
    const changedAdvisors = useSelector(getChangedAdvisors);
    const authUser = useSelector(memoizedGetAuthUser);

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

        setLoading(true);

        let url = "";
        if(aId) url = '/client/advisor/' + aId;
        else if(uName) url = '/client/advisor/uname/' + uName.replaceAll('-', ' ');
        ApiService.get(url).then(result=>{
            updateAdvisorInfo(result.data.advisor);
        })
        .finally(()=>{
            setLoading(false);
        });
    }, []);

    useEffect(()=>{
        if(changedAdvisorHash && advisor) {
            const chgAdvisor = changedAdvisors.find(a=>a._id === aId);
            if(chgAdvisor) updateAdvisorInfo({
                ...advisor,
                rate_per_min_chat: chgAdvisor.rate_per_min_chat,
                online: chgAdvisor.isLoggedIn,
                isBusy: chgAdvisor.isBusy,
                isAvailableChat:chgAdvisor.isAvailableChat,
                isAvailableCall:chgAdvisor.isAvailableCall,
                isAvailableVideo:chgAdvisor.isAvailableVideo,
            });
        }
    }, [changedAdvisorHash])

    const updateAdvisorInfo = (adInfo) => {
        const info = {...adInfo};
        if(info.isLoggedIn == 0) {
            info.serviceChatStatus = 'offline';
        } else {
            if(info.isBusy) {
                info.serviceChatStatus = 'busy';
            } else {
                info.serviceChatStatus = info.isAvailableChat ? 'online' : 'offline';
            }
        }
        setAdvisor(info);
    }

    const renderVideo = () => {
        return (
        <MDBCard className="w-100 h-100 mt-4">
            <MDBCardBody className="d-flex flex-column justify-content-center align-items-center">
                <div className="text-left w-100 d-flex align-items-center mb-2">
                    <SVG src='/img/icons/movie_videos.svg' style={{width:25, height: 25}} fill="#54b4d3" />
                    <h5 className="m-0 ms-2">Introduction Video:</h5>
                </div>
                <video 
                    controls 
                    className="w-90" 
                    src={getBackendAssetUrl(advisor.video)} 
                    style={{maxWidth:300, maxHeight:300}} 
                />
            </MDBCardBody>
        </MDBCard>
        )
    }

    return (
        <MDBContainer breakpoint="lg" className="advisor-show-container">
            { advisor && (
            <>
                <MainInfo advisor={advisor} />
                <AboutMe advisor={advisor} />
                {advisor.video && renderVideo()}
                <Reviews advisor={advisor} />
            </>)
            }
            { loading && <div className="d-flex justify-content-center align-items-center" style={{height: 200}}>
                <h4>Loading...</h4>
            </div>
            }
            {loading && <LoadingIndicator />}
        </MDBContainer>
    )
}, (oldProps, newProps)=>{
    return oldProps.aId == newProps.aId;
})

export default AdvisorShow;
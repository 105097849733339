import React, {useEffect, useRef, useState} from "react";
import classname from 'classnames';
import { MDBCol, MDBContainer, MDBInput, MDBRow,MDBTextArea} from "mdb-react-ui-kit";
import { getAvatarUrl, getBackendAssetUrl, validateForm } from "../../../../Utils/utils";
import Select from "react-select";
import ApiService from "../../../../Core/Service/ApiService";
import { toast } from "react-toastify";
import ImageSelector from "../../../../Components/ImageSelector/ImageSelector";
import { RateChatLimit, RateLimit } from "../../../../Constants/constants";
import LoadingIndicator from "../../../../Components/LoadingIndicator/LoadingIndicator";

const SkillServiceData = React.memo((props) => {
    const [loadingPrice, setLoadingPrice] = useState(false);
    const {advisor, compareData} = props;
    const [price, setPrice] = useState({
        chat_rate_min: RateLimit.CHAT_MIN,
        chat_rate_max: RateLimit.CHAT_MAX
    })

    useEffect(()=>{
        setLoadingPrice(true);
        ApiService.get('/admin/setting/price/index').then(result=>{
            setPrice(result.data);
        })
        .finally(()=>{
            setLoadingPrice(false);
        })
    }, [])

    const updatedClass = (field) => {
        if(compareData && compareData[field] != advisor[field]) {
            return 'updated';
        } else {
            return ''
        }
    }

    return <MDBContainer breakpoint="lg" className="d-flex justify-content-center advisor-service-container">
        <MDBRow className="w-100">
            <MDBCol md='6'>
                <div className="avatar d-flex flex-column justify-content-center align-items-center">
                    <div className={classname("label mb-2", updatedClass('avatar'))}>photo: </div>
                    <img  src={ getBackendAssetUrl(advisor.avatar) } />
                </div>
            </MDBCol>

            <MDBCol md='6'>
                <div className="">
                    <span className={classname('label', updatedClass('username'))}>Username</span>
                    <MDBInput value={advisor.username} readOnly />
                </div>

                <div className="aboutme mt-4">
                    <span className={classname("label", updatedClass('aboutme'))}>About Me: </span>
                    <MDBTextArea    className="form-control" 
                                    value={advisor.aboutme}
                                    rows={4} 
                                    readOnly
                                    />
                </div>

                <div className="chat-rate mt-4">
                    <div className="label">
                        Chat Rate:
                        <span className="notify">( ${price.chat_rate_min} ~ ${price.chat_rate_max} )</span>
                    </div>
                    <MDBInput   type='number' 
                                className="text-center" 
                                value={advisor.rate_per_min_chat} 
                                readOnly
                                />
                    {loadingPrice && <LoadingIndicator />}
                </div>

                <div className="servicename1 mt-4">
                    <span className={classname("label", updatedClass('service_name'))}>Service Name: </span>
                    <MDBInput   className="form-control"
                                value={advisor.service_name}
                                readOnly
                                />
                </div>
                <div className="serviceDesc mt-1">
                    <span className={classname("label", updatedClass('service_description'))}>Service Description: </span>
                    <MDBTextArea className="form-control"
                                value={advisor.service_description}
                                rows={4}
                                readOnly
                                />
                </div>
            </MDBCol>
        </MDBRow>
    </MDBContainer>
});

export default SkillServiceData;
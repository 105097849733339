import { MDBAccordion, MDBAccordionItem, MDBBtn, MDBCol, MDBRow, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import SVG from 'react-inlinesvg';
import React, { useEffect, useState } from "react";
import ApiService from "../../Core/Service/ApiService";
import { useSelector } from "react-redux";
import { memoizedGetChat } from "../../Store/Reducer/chatReducer";
import Moment from "react-moment";
import { formatSeconds, format_time_mm_ss, text2html } from "../../Utils/utils";
import { useContext } from "react";
import ChatContext from "./ChatContext";

const ClientNotes = React.memo(props=>{
    const chat = useSelector(memoizedGetChat);
    const [keyNote, setKeyNote] = useState(null);
    const [chatNotes, setChatNotes] = useState(null);
    const [recentChats, setRecentChats] = useState(null);
    const chatContext = useContext(ChatContext);

    useEffect(()=>{
        if(chat?.client) {
            ApiService.get('/advisor/note/client/' + chat.client._id).then(result=>{
                setKeyNote(result.data.keyNote);
                setChatNotes(result.data.chatNotes);
                setRecentChats(result.data.recentChats);
            })
        }
    }, [chat])

    const hide = () => {
        chatContext.setOpenClientNote(false);
    }
    const renderChatNotes = () => {
        if(chatNotes?.length > 0) {
            return chatNotes.map((note, index) => (
                <div className="mb-3 chat-note" key={index}>
                    <Moment className="fw-bold" style={{fontSize: '90%'}} format="YYYY-MM-DD">{note.chatId.createdAt}</Moment>
                    <div className="" dangerouslySetInnerHTML={{ __html: text2html(note.note)}}></div>
                </div>
            ))
        } else {
            return <div className="d-flex justify-content-center align-items-center">
                <SVG src='/img/icons/no_found.svg' style={{height:40, width: 40}} />
                <span className="ms-2 mt-1">You have not taken notes about the chat.</span>
            </div>
        }
    }
    const renderRecentChats = () => {
        return (
            <MDBTable striped>
                <MDBTableHead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Chat</th>
                        <th scope="col">Duration</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                {recentChats?.length > 0 && (
                    recentChats.map((chat, index) => (
                        <tr key={index}>
                            <td><Moment format="YYYY-MM-DD" style={{fontSize: '90%'}}>{chat.createdAt}</Moment></td>
                            <td>Live Chat</td>
                            <td><div>{ formatSeconds(chat.duration) }</div></td>
                        </tr>
                    ))
                )}
                {!recentChats || recentChats.length == 0 && (
                    <tr>
                        <td colSpan={3}>
                            <div className="d-flex justify-content-center align-items-center">
                                <SVG src='/img/icons/no_found.svg' style={{height:40, width: 40}} />
                                <span className="ms-2 mt-1">No Chats</span>
                            </div>
                        </td>
                    </tr>
                )}
                </MDBTableBody>
            </MDBTable>
        )
    }


    return <section className={`client-notes d-flex flex-column ${chatContext.openClientNote ? '' : 'd-none'}`}>
        <div className="d-flex justify-content-center p-2 pb-3">
            <MDBBtn className="confideas-primary-btn" onClick={hide}>Back to Chat</MDBBtn>
        </div>
        <div className="key-note-section mt-2 mb-2">
            <h6>Key Notes: </h6>
            <div dangerouslySetInnerHTML={{ __html: text2html(keyNote?.note)}}></div>
        </div>
        <div className="flex-fluid" style={{overflow: 'auto'}}>
            <MDBAccordion initialActive={1}>
                <MDBAccordionItem collapseId={1} className="chat-notes-section" headerTitle="Chat Notes:">
                    <div className="chat-note-list">
                        {renderChatNotes()}
                    </div>
                </MDBAccordionItem>
                <MDBAccordionItem collapseId={2} className="recent-chats-section" headerTitle="Recent Chats:">
                    <div className="px-2">
                        {renderRecentChats()}
                    </div>
                </MDBAccordionItem>
            </MDBAccordion>
        </div>
    </section>
});

export default ClientNotes;
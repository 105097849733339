import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBContainer, MDBIcon, MDBSpinner, MDBTextArea } from "mdb-react-ui-kit";
import React from "react";
import styles from './Advisor.scss';
import PersonalData from "./Partials/PersonalData";
import { useEffect } from "react";
import ApiService from "../../../Core/Service/ApiService";
import { useState } from "react";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import SkillServiceData from "./Partials/SkillServiceData";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PendingStatus, UserStatus } from "../../../Constants/constants";
import Select from "react-select";
import { toast } from "react-toastify";
import { getBackendAssetUrl } from "../../../Utils/utils";

const AdminAdvisorCheckSignUp = React.memo((props) => {
    const [loading, setLoading] = useState(false);
    const [advisor, setAdvisor] = useState(null);
    const params = useParams();
    const navigate = useNavigate();
    const aId = params.aId;
    const pendingStatusOpts = [
        { value: UserStatus.WAITING, label: "Waiting"},
        { value: UserStatus.APPROVED, label: "Approve" },
        { value: UserStatus.DECLINE_ADVISOR, label: "Decline"},
    ];
    const [curPendingStatusOpt, setPendingStatusOpt] = useState(null);
    const [updating, setUpdating] = useState(false);
    const [formData, setFormData] = useState({
        status: '',
    })

    useEffect(()=>{
        setLoading(true);

        const url = `/admin/advisor/pending/${aId}`;
        ApiService.get(url).then(result=>{
            const advisor = result.data.advisor;
            setAdvisor(advisor);
            setPendingStatusOpt(pendingStatusOpts.find(opt=>opt.value === advisor.pending_status));
            setFormData({
                ...formData,
                status: advisor.pending_status,
            })
        })
        .finally(()=>{
            setLoading(false);
        })
    }, []);

    const goBack = (e) => {
        e.preventDefault();
        navigate(-1);
    }

    const onPendingStatusChg = (opt) => {
        setPendingStatusOpt(opt);
        setFormData({
            ...formData,
            status: opt.value
        })
    }

    const handleApprove = (e) => {
        e.preventDefault();
        if(window.confirm("Are you sure to save?") == false) return;

        setUpdating(true);

        const data = {
            aId: aId,
            user_status: formData.status,
        }
        ApiService.post('/admin/advisor/approve', data).then(result => {
            toast.success('User status changed successfully');
            navigate('/admin/advisor/pending/list');
        })
        .finally(()=>{
            setUpdating(false);
        })
    }

    const breadcrumb = [];
    breadcrumb.push({title: 'Advisor Pending', url:'/admin/advisor/pending/list'});
    breadcrumb.push({title: 'Show'});

    return <MDBContainer breakpoint="lg" className="admin-advisor-show-container">
        <Breadcrumb breadcrumb={breadcrumb} className='mb-4' />
        <MDBCard>
            <MDBCardHeader>
                <MDBCardTitle>
                    <MDBIcon fas icon="user-circle" size="lg" className="me-1" />
                    <span className="accordion-item-title">Personal Information</span>
                </MDBCardTitle>
            </MDBCardHeader>
            <MDBCardBody>
                { advisor && <PersonalData advisor={advisor} /> }
            </MDBCardBody>
        </MDBCard>
            
        <MDBCard className="mt-4">
            <MDBCardHeader>
                <MDBCardTitle>
                    <MDBIcon fas icon="atom" size='lg' className="me-1" />
                    <span className="accordion-item-title">Services</span>
                </MDBCardTitle>
            </MDBCardHeader>
            <MDBCardBody>
                { advisor && <SkillServiceData advisor={advisor} /> }
            </MDBCardBody>
        </MDBCard>

        {advisor?.video && (
            <MDBCard className="mt-4">
                <MDBCardHeader>
                    <MDBCardTitle>
                        <MDBIcon fas icon="video" className="me-1" />
                        <span className="accordion-item-title">Introduction Video</span>
                    </MDBCardTitle>
                </MDBCardHeader>
                <MDBCardBody className="d-flex justify-content-center p-4">
                    <video 
                        src={getBackendAssetUrl(advisor.video)}
                        style={{width: 400, height: 300}}
                        controls
                    />
                </MDBCardBody>
            </MDBCard>
        )}

        <div className="mt-4 pending-type w-100">
            <div className="d-flex">
                <div className="me-2 d-flex justify-content-start align-items-center">Advisor Status : </div>
                <div className="">
                    { curPendingStatusOpt && <Select options={pendingStatusOpts} onChange={opt=>onPendingStatusChg(opt)} defaultValue={curPendingStatusOpt} /> }
                </div>
            </div>
        </div>

        <div className="d-flex justify-content-end mt-4">
            <MDBBtn onClick={handleApprove} className="mx-2">
                {updating && <>
                    <MDBSpinner size='sm' role='status' tag='span' className='me-2' />
                    Saving
                </>}
                {!updating && <span>Save</span>}
            </MDBBtn>
            <MDBBtn onClick={goBack} color="second">Back</MDBBtn>
        </div>
        {loading && <LoadingIndicator />}
    </MDBContainer>
});

export default AdminAdvisorCheckSignUp;
import { MDBInput } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Calendar, DateRangePicker } from "react-date-range";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { formatDate } from "../../Utils/utils";

const DateRangeSelector = React.memo((props) => {
    const [dateRange, setDateRange] = useState({
        startDate: props.startDate,
        endDate: props.endDate,
        key: 'selection',
    })
    const [showDateSelector, setShowDateSelector] = useState(false);
    const [dateRangeStr, setDateRangeStr] = useState(formatDate(props.startDate, 'YYYY/MM/DD') + " - " + formatDate(props.endDate, 'YYYY/MM/DD'));

    const handleMouseClick = (e) => {
        if(showDateSelector && !e.target.closest('.daterange-select')) {
            setShowDateSelector(false);
        }
    }

    useEffect(()=>{
        if(showDateSelector) {
            window.document.addEventListener('click', handleMouseClick);
        }

        return () => {
            window.document.removeEventListener('click', handleMouseClick);
        }
    }, [showDateSelector]);

    const handleDateRangeSelect = (ranges) => {
        const selection = ranges.selection;
        setDateRange(selection);
        setDateRangeStr(formatDate(selection.startDate, 'YYYY/MM/DD') + " - " + formatDate(selection.endDate, 'YYYY/MM/DD'));
        props.onDateRangeSelected && props.onDateRangeSelected(selection.startDate, selection.endDate);

        setShowDateSelector(false);
    }

    return (<div className="position-relative">
        <MDBInput 
            value={dateRangeStr}
            readOnly={true}
            onClick={e=>setShowDateSelector(true)}
        />
        {showDateSelector && <>
            <div 
                className="daterange-select position-absolute" 
                style={{top: 40, right: 0, zIndex: 10000}}
            >
                <DateRangePicker
                    ranges={[dateRange]}
                    onChange={handleDateRangeSelect}
                />
            </div>
        </>}
    </div>
    )
});

export default DateRangeSelector;
import React from 'react';
import { MDBFooter } from 'mdb-react-ui-kit';

const AdminFooter = React.memo((props) =>{
  return (
    <MDBFooter bgColor='light' className='text-center text-lg-left'>
      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        &copy; {new Date().getFullYear()} Copyright:{' '}
        <a className='text-dark' href={`${process.env.REACT_APP_SITE_URL}`}>
          Confideas.com
        </a>
      </div>
    </MDBFooter>
  );
});

export default AdminFooter;
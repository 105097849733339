import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { ChatStatus, EDIT_REVIEW_DAYS_OUT, ReviewType, UserRole } from "../../Constants/constants";
import { addDate, compareDates, formatSeconds, getAvatarUrl, text2html, validateForm } from "../../Utils/utils";
import Moment from "react-moment";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import { MDBBtn, MDBCard, MDBCardBody, MDBIcon, MDBRadio, MDBSpinner, MDBTextArea } from "mdb-react-ui-kit";
import ApiService from "../../Core/Service/ApiService";
import { useNavigate } from "react-router-dom";

const ChatMetaInfo = React.memo((props) => {
    const navigate = useNavigate();
    const authUser = useSelector(memoizedGetAuthUser);
    const [partner, setPartner] = useState();
    const { chat } = props;
    const [reviewFormData, setReviewFormData] = useState({
        reviewContent: chat.reviewId?.content,
        reviewType: chat.reviewId ? chat.reviewId.type : ReviewType.HAPPY
    })
    const reviewFormDataRules = {
        reviewContent: {
            required: 'string',
            label: 'Feedback'
        }
    };
    const [noteFormData, setNoteFormData] = useState({
        note: ''
    })
    const [inputErrors, setInputErrors] = useState({});
    const [savingReview, setSavingReview] = useState(false);
    const [savingNote, setSavingNote] = useState(false);

    useEffect(()=>{
        if(authUser && chat) {
            if(authUser.role === UserRole.CLIENT) setPartner(chat.aId);
            if(authUser.role === UserRole.ADVISOR) setPartner(chat.cId);

            if(chat.advisorNoteId) {
                setNoteFormData({
                    note: chat.advisorNoteId.note
                })
            }
        }
    }, [authUser, chat]);

    const onReviewContentChanged = (e) => {
        setReviewFormData({
            ...reviewFormData,
            reviewContent: e.target.value
        })
    }
    const onReviewTypeChanged = (e) => {
        setReviewFormData({
            ...reviewFormData,
            reviewType: e.target.value
        });
    }
    const handleSaveReview = (e) => {
        const valid_ret = validateForm(reviewFormData, reviewFormDataRules);
        if(valid_ret.result == true) {
            setInputErrors({});
            setSavingReview(true);

            const data = {...reviewFormData};
            let url = '';
            if(chat.reviewId) {
                url = '/client/review/update';
                data._id = chat.reviewId;
            } else {
                url = '/client/review/create';
                data.chatId = chat._id;
            };
            ApiService.post(url, data).then((result)=>{
            })
            .finally(()=>{
                setSavingReview(false);
            })
        } else {
            setInputErrors(valid_ret.errors);
        }
    }

    const handleSaveNote = (e) => {
        if(!noteFormData.note) return;
        const data = {
            cId: chat.cId._id,
            chatId: chat._id,
            note: noteFormData.note
        }

        setSavingNote(true);
        ApiService.post('/advisor/note', data).then(result=>{
        })
        .finally(()=>{
            setSavingNote(false);
        })
    }

    const renderChatMainInfo = () => {
        return (
        <div className="chat-maininfo d-flex align-items-center px-4 py-3">
            <img className="partner-avatar" src={getAvatarUrl(partner.avatar)} />
            <div className="chat-other-info ms-3">
                <div className="fw-bold" style={{fontSize: '120%'}}>{partner.username}</div>
                {chat.status >= ChatStatus.ENDED && <>
                    <div>
                        <span style={{color: "green"}}>Live Chat</span>
                        <span className="ms-3"><Moment format="YYYY-MM-DD">{chat.startedAt}</Moment></span>
                    </div>
                    <div className="d-flex align-items-center mt-2">
                        <div className="earning px-4">$ {authUser.role === UserRole.CLIENT ? chat.amount : chat.ad_earning}</div>
                        <div className="duration ms-2 px-3 d-flex align-items-center">
                            <MDBIcon far icon='clock' />
                            <span className="ms-2">{ formatSeconds(chat.duration) }</span>
                        </div>
                    </div>
                </>}
                {chat.status === ChatStatus.MISSED && <div className="comment">
                    Missed Chat
                </div>}
            </div>
        </div>
        )
    }

    const renderReview = () => {
        if(authUser.role === UserRole.CLIENT && chat.duration > 5*60) {
            const expireDate = addDate(new Date(chat.startedAt), EDIT_REVIEW_DAYS_OUT);
            if(compareDates(expireDate, new Date()) === 1) {
                return (
                    <div className="review mt-4">
                        <h5>Review:</h5>
                        <div>
                            <MDBTextArea rows={5} 
                                name='reviewContent' 
                                value={reviewFormData.reviewContent}
                                onChange={onReviewContentChanged} 
                            />
                            {inputErrors.reviewContent && <div className='error'>{inputErrors.reviewContent}</div>}
                        </div>
                        <div className="d-flex mt-3 justify-content-begin align-items-center w-100">
                            <div className="me-4 d-flex align-items-center">
                                <img 
                                    src='/img/icons/sad.png' 
                                    style={{opacity : reviewFormData.reviewType==ReviewType.UNHAPPY? 1: 0.3, width:30, height: 30}} 
                                    className="me-2"
                                />
                                <MDBRadio 
                                    name='reviewTypeRadio' 
                                    id='unhappy_review' 
                                    value={ReviewType.UNHAPPY}
                                    onChange={onReviewTypeChanged}
                                    checked={reviewFormData.reviewType === ReviewType.UNHAPPY}
                                    disabled={authUser?.role != UserRole.CLIENT}
                                />
                            </div>
                            <div className="ms-2">
                                <div className="d-flex align-items-center">
                                    <img 
                                        className="me-2"
                                        src='/img/icons/smile.png' 
                                        style={{opacity : reviewFormData.reviewType==ReviewType.HAPPY? 1: 0.3, width:30, height: 30}} 
                                    />
                                    <MDBRadio 
                                        value={ReviewType.HAPPY}
                                        name='reviewTypeRadio' 
                                        checked={reviewFormData.reviewType === ReviewType.HAPPY}
                                        onChange={onReviewTypeChanged}
                                    />
                                </div>
                            </div>
                            <div className="flex-fluid d-flex justify-content-end">
                                <MDBBtn className="confideas-primary-btn px-3 py-1" onClick={handleSaveReview}>
                                    {savingReview && <>
                                        <MDBSpinner size='sm' />
                                        <span className="ms-2">Saving</span>
                                    </>}
                                    {!savingReview && <span>Save</span>}
                                </MDBBtn>
                            </div>
                        </div>
                    </div>
                )
            }
        }

        return (
            <div className="review mt-4">
                <h5>
                    <span>Review:</span>
                    { chat.reviewId && 
                        <span>
                            <img 
                                src={`/img/icons/${chat.reviewId.type === ReviewType.HAPPY ? 'smile.png' : 'sad.png'}`}
                                style={{width:30, height: 30}} 
                                className="ms-3"
                            />
                        </span>
                    }
                </h5>
                <div 
                    className="p-3 py-2" 
                    style={{borderRadius: 5, background: '#eee', minHeight: 100}} 
                    dangerouslySetInnerHTML={{ __html: text2html(chat.reviewId?.content)}} 
                />
                
                {authUser.role === UserRole.CLIENT && chat.duration < 5*60 && <>
                    <div className="comment mt-1">
                        To ensure a meaningful review, chats must be at least 5 minute long before you can leave feedback.
                    </div>
                </>}

                {authUser.role === UserRole.ADVISOR && chat.reviewId?.type === ReviewType.UNHAPPY && (
                    <div className="mt-2">
                        <div className="text-red">Tips for Handling Negative Reviews!!</div>
                        <p className="comment">
                            Please clam down and respond to your client politely. A negative review won't affect your future orders. Even the best advisors received negative reviews.
                        </p>
                        <p className="comment">
                            We don't intervene in reviews. However, if you see derogatory language or complaints about technical glitches, please contact our support team for assistance.
                        </p>
                    </div>
                )}
            </div>
        )
    }

    const onNoteChanged = (e) => {
        setNoteFormData({
            ...noteFormData,
            note: e.target.value
        });
    }

    const viewTranscript = (e) => {
        navigate(`/chat/${chat._id}/messages`);
    }

    const renderChatNote = () => {
        if(authUser?.role === UserRole.ADVISOR) {
            return (
                <div className="chat-note mt-4">
                    <h5>Note about the chat:</h5>
                    <MDBTextArea
                        rows={5}
                        name='note'
                        value={noteFormData.note}
                        onChange={onNoteChanged}
                    />
                    <div className="d-flex justify-content-end mt-2">
                        <MDBBtn className="confideas-primary-btn px-3 py-1" onClick={handleSaveNote}>
                            {savingNote && <>
                                <MDBSpinner size='sm' />
                                <span className="ms-2">Saving</span>
                            </>}
                            {!savingNote && <span>Save</span>}
                        </MDBBtn>
                    </div>
                </div>
            )
        } else {
            return <></>
        }
    }

    if(partner) {
        return (
            <>
                {renderChatMainInfo()}

                <div className="mt-3">
                    <MDBBtn className="w-100" onClick={viewTranscript} style={{background: '#F2F7B7', color: '#333'}}>
                        <span className="me-4">View Transcript</span>
                        <MDBIcon fas icon="chevron-right" />
                    </MDBBtn>
                </div>

                {renderReview()}
                {renderChatNote()}
            </>
        )
    } else {
        return (
            <div className="d-none d-md-block">
                <LoadingIndicator />
            </div>
        )
    }

});

export default ChatMetaInfo;
import React, { useEffect, useState } from "react";
import AdminTable from "../../../Components/AdminTable/AdminTable";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { Link, useNavigate } from "react-router-dom";
import styles from './Advisor.scss';
import { MDBCheckbox, MDBIcon, MDBInput, MDBSpinner, MDBSwitch } from "mdb-react-ui-kit";
import Paginator from "../../../Components/Paginator/Paginator";
import { UserStatus } from "../../../Constants/constants";
import { toast } from "react-toastify";
import Moment from "react-moment";
import Select from "react-select";
import { getAvatarUrl } from "../../../Utils/utils";
import { useContext } from "react";
import ModalContext from "../../../Context/ModalContext";
import SearchBar from "../../../Components/SearchBar/SearchBar";

const AdminAdvisorList = React.memo((props) => {
    const navigate = useNavigate();
    const modalContext = useContext(ModalContext);
    const [loading, setLoading] = useState(false);
    const [advisors, setAdvisors] = useState([]);
    const [search, setSearch] = useState('');
    const [formData, setFormData] = useState({
        search: '',
        perPage: 10,
        pageNum: 1,
        listing: '',
        userStatus: ''
    });
    const perPageOpts = [
        { value: 10,label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" }
    ];
    const [curPerPageOpt, setCurPerPageOpt] = useState(perPageOpts[0]);
    const userStatusOpts = [
        {value: '', label: 'All'},
        {value: UserStatus.EMAIL_VERIFIED, label: 'Email Verified'},
        {value: UserStatus.WAITING, label: 'Pending'},
        {value: UserStatus.APPROVED, label: 'Approved'},
        {value: UserStatus.SUSPENDED, label: 'Suspended'},
        {value: UserStatus.TERMINATED, label: 'Terminated'}
    ]
    const [curUserStatusOpt, setCurUserStatusOpt] = useState(userStatusOpts[0]);
    const listingOpts = [
        {value: '', label: 'All'},
        {value: 'listing', label: 'Listing'},
        {value: 'not-listing', label: 'Not Listing'}
    ];
    const [curListingOpt, setCurListingOpt] = useState(listingOpts[0]);
    const [total, setTotal] = useState(0);
    const columns = [
        {name: 'username', title: 'Username'},
        {name: 'user_status', title: 'Status'},
        {name: 'listing', title: 'Listing'},
        {name: 'show_video', title: 'Video'},
        {name: 'action', title: 'Action'},
        {name: 'legalname', title: 'Legal Name'},
        // {name: 'email', title: 'Email'},
        // {name: 'mobile', title: 'Mobile'},
        // {name: 'birth', title: 'Birthday'},
        {name: 'rate_per_min_chat', title: 'Chat Rate'},
        {name: 'commission', title: 'Commission'},
        {name: 'total_chats', title: 'Total Chats'},
        {name: 'total_earning', title: 'Total Earning'},
        {name: 'balance', title: 'Balance'},
        {name: 'reviews', title: 'Reviews'},
    ];
    const [editTarget, setEditTarget] = useState(null);
    const [action, setAction] = useState('');


    useEffect(()=>{
        getAdvisors();
    }, [formData]);

    const getAdvisors = () => {
        setLoading(true);

        ApiService.post('/admin/advisor/index', formData).then(result=>{
            setAdvisors(result.data.advisors);
            setTotal(result.data.total);
        })
        .catch(error=>{

        })
        .finally(()=>{
            setLoading(false);
        })
    }

    const onSearchChange = (e) => {
        setSearch(e.target.value);
    }
    const onSearchBtnClicked = (e) => {
        e.preventDefault();
        setFormData({
            ...formData,
            search,
            pageNum: 1
        })
    }
    const onSearchKeyPress = (e) => {
        if(e.key == 'Enter') {
            e.preventDefault();
            setFormData({
                ...formData,
                pageNum: 1,
                search
            });
        }
    }
    const onPageChanged = (pageNum) => {
        setFormData({
            ...formData,
            pageNum
        })
    }
    const onPerPageChanged = (opt) => {
        setFormData({
            ...formData,
            perPage: opt.value,
            pageNum: 1
        })
        setCurPerPageOpt(opt);
    }
    const onUserStatusChanged = (opt) => {
        setFormData({
            ...formData,
            userStatus: opt.value,
            pageNum: 1,
        })
        setCurUserStatusOpt(opt);
    }
    const onListingChanged = (opt) => {
        setFormData({
            ...formData,
            listing: opt.value,
            pageNum: 1
        });
        setCurListingOpt(opt);
    };

    const showDetail = (e, advisor) => {
        e.preventDefault();
        navigate('/admin/advisor/edit/service/' + advisor._id);
    }

    const handleEditAdvisor = (advisor, rowIndex, colname) => {
        setEditTarget({
            rowIndex,
            colname,
            advisor: {...advisor}
        })
    }
    const handleInputChange = (e) => {
        if(!editTarget) return;
        setEditTarget({
            ...editTarget,
            advisor: {
                ...editTarget.advisor,
                [e.target.name]: e.target.value
            }
        })
    }
    const handleKeyDown = (e) => {
        if(!editTarget) return;

        if(e.key == 'Enter') {
            if(e.target.name === 'commission' && !e.target.value) {
                e.preventDefault();
                return;
            }
            handleUpdateAdvisor();
        }
    }
    const handleCancelEdit = (e) => {
        setEditTarget(null)
    }
    const handleUpdateAdvisor = () => {
        ApiService.post('/admin/user/update', {user: editTarget.advisor}).then(async (result) => {
            await setAdvisors(advisors.map((a, index)=>{
                if(index === editTarget.rowIndex) return editTarget.advisor;
                else return a;
            }));
            await setEditTarget(null);
        })
        .finally(()=>{

        });
    }
    const handleCreditBack = (advisor) => {
        modalContext.setModalData({user: advisor});
        modalContext.setOpenCreditBackModal(true);
    }
    const handleListing = (e, advisor, rowIndex) => {
        if(advisor.user_status != UserStatus.APPROVED) {
            toast.warning("This advisor has not been approved.");
            return;
        }
        if(window.confirm("Are you sure to change?") == false) return;

        setEditTarget({
            rowIndex,
            colname: 'listing',
            advisor
        })
        const data = {
            aId: advisor._id,
            listing: e.target.checked
        }
        ApiService.post('/admin/advisor/listing', data).then(response=>{
            setAdvisors(advisors.map((a, index) => {
                if(index != rowIndex) return a;
                return {
                    ...a,
                    listing: data.listing
                }
            }))
        })
        .finally(()=>{
            setEditTarget(null);
        })
    }
    const handleShowVideo = (e, advisor, rowIndex) => {
        if(advisor.user_status != UserStatus.APPROVED) {
            toast.warning("This advisor has not been approved.");
            return;
        }

        setEditTarget({
            rowIndex,
            colname: 'show_video',
            advisor
        })
        const data = {
            aId: advisor._id,
            show_video: e.target.checked
        }
        ApiService.post('/admin/advisor/show_video', data).then(response=>{
            setAdvisors(advisors.map((a, index) => {
                if(index != rowIndex) return a;
                return {
                    ...a,
                    show_video: data.show_video
                }
            }))
        })
        .finally(()=>{
            setEditTarget(null);
        })
    }

    const breadcrumb=[{title: 'Advisor'}, {title: 'List'}];
    return (<>
        <Breadcrumb breadcrumb={breadcrumb} />
        <div className="advisors-list">
            <div className="confideas-table-header">
                <div className="px-2">
                    <label>Count : </label>
                    <span className="ps-2">{total}</span>
                </div>
                <SearchBar>
                    <div className="search-item me-2">
                        <Select 
                            options={perPageOpts} 
                            onChange={(opt) => onPerPageChanged(opt)} 
                            defaultValue={curPerPageOpt}
                        />
                    </div>
                    <div className="search-item me-2" style={{width:150}}>
                        <Select 
                            options={userStatusOpts}
                            onChange={(opt) => onUserStatusChanged(opt)}
                            defaultValue={curUserStatusOpt}
                        />
                    </div>
                    <div className="search-item me-2" style={{width: 150}}>
                        <Select 
                            options={listingOpts}
                            onChange={(opt) => onListingChanged(opt)}
                            defaultValue={curListingOpt}
                        />
                    </div>
                    <div className="search-item search-term">
                        <MDBInput   type='text' 
                                    label='Search' 
                                    value={search}
                                    onChange={onSearchChange}
                                    onKeyPress={onSearchKeyPress}
                        />
                        <span className='mx-2 cursor-pointer' onClick={onSearchBtnClicked}>
                            <MDBIcon fas icon='search' />
                        </span>
                    </div>
                </SearchBar>
            </div>

            <AdminTable columns={columns} rows={advisors} style={{minWidth: 1}}>
                {(row, colname, rowIndex, colIndex) => {
                    switch(colname) {
                        case 'username':
                            const online_status = row['isLoggedIn'] === true ? 'online' : 'offline';
                            return (
                                <div className="d-flex align-items-center">
                                    <img src={getAvatarUrl(row['avatar'])} className={`advisor-avatar round-avatar-40 me-1 ${online_status}`} />
                                    {row.username} 
                                </div>
                            )
                        case 'online':
                            return <div className={`online-status ${online_status.toLowerCase()}`}>
                                    {online_status}
                                </div>
                        case 'rate_per_min_chat':
                            return <div>$ {row.rate_per_min_chat}</div>
                        case 'commission':
                            return <div className="d-flex align-items-center">
                                { (() => {
                                    if(editTarget?.rowIndex === rowIndex && editTarget?.colname === 'commission') {
                                        return <>
                                            <div style={{width:80}}>
                                                <MDBInput type="number"
                                                    name='commission'
                                                    value={editTarget?.advisor.commission}
                                                    onChange={handleInputChange}
                                                    onKeyDown={handleKeyDown}
                                                />
                                            </div>
                                            <MDBIcon fas icon='save' 
                                                size="lg" 
                                                color="success" 
                                                className="ms-1 cursor-pointer" 
                                                title="Save" 
                                                onClick={handleUpdateAdvisor}
                                            />
                                            <MDBIcon fas icon='ban' 
                                                size="lg" 
                                                color="warning" 
                                                className="ms-1 cursor-pointer" 
                                                title="Cancel" 
                                                onClick={handleCancelEdit}
                                            />
                                        </>
                                    } else {
                                        return <>
                                            {row.commission}%
                                            <MDBIcon fas icon="pencil-alt" 
                                                size="lg" 
                                                className='ms-2 cursor-pointer' 
                                                color="warning" 
                                                onClick={e=>handleEditAdvisor(row, rowIndex, colname)}
                                            />
                                        </>
                                    }
                                })()
                                }
                            </div>
                        case 'user_status':
                            return <div className={`user-status ${row.user_status}`}>{row.user_status}</div>
                        case 'listing':
                            return <div className="d-flex align-items-center">
                                {(()=>{
                                    if(editTarget && editTarget.rowIndex === rowIndex && editTarget.colname === 'listing') {
                                        return <MDBSpinner size="sm" />;
                                    } else {
                                        return <MDBCheckbox 
                                                    checked={row.listing} 
                                                    onChange={e=>handleListing(e, row, rowIndex)} 
                                                    disabled={row.user_status != UserStatus.APPROVED} 
                                                />
                                    }
                                })()}
                            </div>
                        case 'show_video':
                            return <div className="d-flex align-items-center">
                                {(()=>{
                                    if(editTarget && editTarget.rowIndex === rowIndex && editTarget.colname === 'show_video') {
                                        return <MDBSpinner size="sm" />;
                                    } else {
                                        return <MDBCheckbox 
                                                    checked={row.show_video} 
                                                    onChange={e=>handleShowVideo(e, row, rowIndex)} 
                                                    disabled={row.user_status != UserStatus.APPROVED} 
                                                />
                                    }
                                })()}
                            </div>
                        case 'reviews':
                            return <div>
                                <span className="">{row.reviews?.length} </span>
                                (
                                    <span style={{color:'green'}}>{row.happy_review}</span>/ 
                                    <span style={{color:'red'}}>{row.unhappy_review}</span>
                                )
                            </div>
                        case 'action':
                            return (
                                <div className="d-flex action align-items-center">
                                    {row.user_status !== UserStatus.EMAIL_VERIFIED && (
                                        <MDBIcon far icon="edit" 
                                            className="edit cursor-pointer" 
                                            onClick={(e)=>showDetail(e, row)}
                                            size='lg' 
                                            color="warning" 
                                        />
                                    )}
                                    {row.user_status === UserStatus.APPROVED &&
                                        <MDBIcon className="ms-1 cursor-pointer" 
                                            far icon="credit-card" 
                                            size="lg" 
                                            onClick={e=>handleCreditBack(row)}
                                            title='Credit Back' 
                                        />
                                    }
                                </div>
                            )
                    }
                }}
            </AdminTable>

            <div className='d-flex justify-content-center mt-4'>
                <Paginator total={total} perPage={formData.perPage} onPageChanged={onPageChanged} />
            </div>
            {loading && <LoadingIndicator />}
        </div>
    </>)
});

export default AdminAdvisorList;
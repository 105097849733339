import React, { useState, useEffect, useRef, ChangeEvent, KeyboardEvent } from 'react'

const TextAreaAutoHeight = React.memo((props) => {
    const textareaRef = useRef(null);
    const [maxBoxHeight, setMaxBoxHeight] = useState(400);
    const [minBoxHeight, setMinBoxHeight] = useState(100);

    useEffect(()=>{
        if(props.style?.maxBoxHeight) setMaxBoxHeight(props.style.maxBoxHeight);
    }, [props.style?.maxBoxHeight]);

    useEffect(()=>{
        if(props.style?.minBoxHeight) {
            console.log(props.style?.minBoxHeight);
            setMinBoxHeight(props.style.minBoxHeight);
        }
    }, [props.style?.minBoxHeight]);

    useEffect(()=>{
        adjustTextareaHeight();
    }, [minBoxHeight, maxBoxHeight])

    const onChange = (e) => {
        props.onChange && props.onChange(e);
        adjustTextareaHeight();
    }

    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.overflowY = 'hidden'
            textareaRef.current.style.maxHeight = `${maxBoxHeight}px`
            textareaRef.current.style.minHeight = `${minBoxHeight}px`
            textareaRef.current.style.height = 'auto'
            if (textareaRef.current.scrollHeight >= maxBoxHeight) {
                textareaRef.current.style.overflowY = 'scroll'
            }
            if(textareaRef.current.scrollHeight > 0) {
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
            }
        }
    }

    return (
        <textarea
            {...props}
            value={props.value}
            onChange={onChange}
            ref={textareaRef}
            placeholder={props.placeholder ?? ''}
        />
    )
});

export default TextAreaAutoHeight;
import React from "react";
import { MDBCol, MDBIcon } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";

const StatisticCard = React.memo((props) => {
    const navigate = useNavigate();
    return (
        <MDBCol sm='6' md='6' lg='4' xl='3' className="p-1">
            <div className="d-flex statistic-card">
                <div className="card-header p-1 d-flex flex-column justify-content-center align-items-center"
                    style={{
                        background: props.customeStyle.cardHeaderBackgroundColor, 
                        color: 'white',
                        width: 80
                    }}
                >
                    <MDBIcon fas icon="user-friends" size="2x" />
                    {props.title}
                </div>

                <div className="card-body p-4 d-flex flex-column justify-content-center" 
                    style={{
                        background: props.customeStyle.cardBodyBackgroundColor, 
                        color: props.customeStyle.cardBodyColor
                    }}
                >
                    {props.data.map((d, index)=>(
                        <div key={index} className="d-flex align-items-center cursor-pointer statistic-item" onClick={(e)=>{navigate(d.url)}}>
                            <span className="label pe-2">{d.label}</span>
                            <span>:</span>
                            <span className="value ps-2">{d.value}</span>
                        </div>    
                    ))}
                </div>
            </div>
        </MDBCol>
    )
});

export default StatisticCard;
import React, { useEffect, useState } from "react";
import AdminTable from "../../../Components/AdminTable/AdminTable";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { Link, useNavigate } from "react-router-dom";
import { MDBBtn, MDBCheckbox, MDBIcon, MDBInput, MDBSpinner, MDBSwitch } from "mdb-react-ui-kit";
import { OpinionStatus, UserStatus } from "../../../Constants/constants";
import { toast } from "react-toastify";
import VerifyItemEditModal from "./VerifyItemEditModal";
import { text2html } from "../../../Utils/utils";

const AdminVerifyItemList = React.memo((props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [verifyItems, setVerifyItems] = useState([]);
    const columns = [
        {name: 'label', title: 'Label'},
        {name: 'comment', title: 'Comment'},
        {name: 'action', title: 'Action'}
    ];
    const [openEditModal, setOpenEditModal] = useState(false);
    const [verifyItemInEditing, setVerifyItemInEditing] = useState(null);
    const [deleting, setDeleting] = useState(false);

    useEffect(()=>{
        getVerifyItems();
    }, []);

    const getVerifyItems = () => {
        setLoading(true);
        ApiService.get('/admin/verifyitem/index').then(response => {
            setTotal(response.data.verifyItems.length);
            setVerifyItems(response.data.verifyItems);
        })
        .finally(()=>{
            setLoading(false);
        })
    }

    const onAddBtnClicked = (e) => {
        setVerifyItemInEditing(null);
        setOpenEditModal(true);
    }
    const onEditBtnClicked = (e, vi) => {
        setVerifyItemInEditing(vi);
        setOpenEditModal(true);
    }
    const onDeleteBtnClicked = (e, vi) => {
        if(window.confirm("Are you sure to delete this verify item?") == false) return;

        setVerifyItemInEditing(vi);
        setDeleting(true);
        ApiService.delete(`/admin/verifyitem/${vi._id}`).then(response=>{
            getVerifyItems();
        })
        .finally(()=>{
            setDeleting(false);
        })
    }

    const breadcrumb=[{title: 'VerifyItem'}, {title: 'List'}];
    return(<section className="admin-verifyitem-list">
        <Breadcrumb breadcrumb={breadcrumb} />
        <div className="confideas-table-header">
            <div>
                <label>Count: </label>
                <span className="ms-1">{total}</span>
            </div>
            <div className="flex-fluid d-flex justify-content-end">
                <MDBBtn onClick={onAddBtnClicked}>Add</MDBBtn>
            </div>
        </div>
        <AdminTable columns={columns} rows={verifyItems} style={{minWidth: 500}}>
            {(row, colname, rowIndex, colIndex) => {
                switch(colname) {
                    case 'comment':
                        return <div dangerouslySetInnerHTML={{ __html: text2html(row.comment)}}></div>
                    case 'action':
                        return (<>
                            <MDBBtn tag='a' color="none" onClick={(e)=>onEditBtnClicked(e, row)}>
                                <MDBIcon far icon="edit" color="warning" size="lg" />
                            </MDBBtn>
                            <MDBBtn 
                                tag='a' 
                                color="none" 
                                onClick={(e)=>onDeleteBtnClicked(e, row)} 
                                className="ms-2" 
                                disabled={deleting && verifyItemInEditing._id === row._id}
                            >
                                {deleting && verifyItemInEditing._id === row._id && <MDBSpinner size="sm" />}
                                <MDBIcon fas icon="trash-alt" color="danger" size="lg"/>
                            </MDBBtn>
                        </>);
                }
                return null;
            }}
        </AdminTable>
        <VerifyItemEditModal 
            open={openEditModal} 
            setOpen={setOpenEditModal} 
            verifyItemInEditing={verifyItemInEditing} 
            callback={getVerifyItems}
        />
        {loading && <LoadingIndicator />}
    </section>)
});

export default AdminVerifyItemList;
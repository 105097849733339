import { MDBContainer, MDBInputGroup, MDBIcon, MDBRow, MDBCol, MDBInput, MDBBtn } from "mdb-react-ui-kit";
import React, {useEffect, useState} from "react";
import SVG from 'react-inlinesvg';
import Advisor from "../../Components/Advisor/Advisor";
import ApiService from "../../Core/Service/ApiService";
import LoadingIndicator from "../../Components/LoadingIndicator/LoadingIndicator";
import { shallowEqual, useSelector } from "react-redux";
import { IsAuthenticated, memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { UserRole } from "../../Constants/constants";
import { IsChatSocketConnected } from "../../Store/Reducer/socketReducer";
import { useNavigate } from "react-router-dom";

const AdvisorList = React.memo(({}) => {
    // console.log('calling advisor list....')
    const perPageOpts = [
        { value: 12,label: "12" },
        { value: 16, label: "16" },
        { value: 20, label: "20" }
    ];
    const isAuthenticated = useSelector(IsAuthenticated);
    const authUser = useSelector(memoizedGetAuthUser);
    const [loadingAdvisors, setLoadingAdvisors] = useState(false);
    const [advisors, setAdvisors] = useState([]);
    const [availCoupons, setAvailCoupons] = useState(null);
    const isChatSockConnected = useSelector(IsChatSocketConnected);
    const navigate = useNavigate();

    const getTopAdvisors = async () => {
        try {
            setLoadingAdvisors(true);

            let response = await ApiService.get(`/client/advisor/top`);
            setAdvisors(response.data.advisors);

            if(isAuthenticated && authUser.role === UserRole.CLIENT && response.data.advisors.length > 0) {
                // To get available coupons
                const aIds = response.data.advisors.map(ad => ad._id);
                response = await ApiService.post('/client/coupon/available/bulk', {aIds});
                setAvailCoupons(response.data.coupons);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingAdvisors(false);
        }
    }

    useEffect(()=>{
        getTopAdvisors();
        if(!isAuthenticated) {
            setAvailCoupons(null);
        }
    }, [isAuthenticated]);

    const getAvailCoupon = (aId) => {
        if(isAuthenticated && availCoupons?.length > 0) {
            const availCoupon = availCoupons.find(coupon=>coupon.aId === aId);
            return availCoupon ?? null;
        }
        return null;
    }

    const gotoAllAdvisors = () => {
        navigate('/all_advisors');
    }

    const renderAdvisors = () => {
        if(advisors?.length == 0) {
            return <div className="text-center">
                {loadingAdvisors ? '' : <><SVG src='/img/icons/no_found.svg' style={{height:80}} /><div className="mt-1">No Found Advisors</div></>}
            </div>
        } else {
            return <MDBRow>
                { advisors?.map((a, index) => {
                    return <MDBCol className="advisor-container py-2" size={6} xl='2' lg="3" md='3' sm='4' key={index}>
                        <Advisor advisor={a} availCoupon={getAvailCoupon(a._id)} />
                    </MDBCol>
                })}
            </MDBRow>
        }
    }

    return (
        <div className="advisor-list-container py-2" id='advisors'>
            <MDBContainer breakpoint="lg" style={{position: 'relative'}}>
                <div className="title d-flex align-items-center ms-3">
                    Top Advisors
                </div>

                { renderAdvisors() }

                <div className="d-flex justify-content-end mt-4">
                    <MDBBtn className="confideas-primary-btn" onClick={gotoAllAdvisors}>
                        <span style={{textTransform: 'none'}}>All Advisors</span>
                    </MDBBtn>
                </div>
                {loadingAdvisors && isChatSockConnected && <LoadingIndicator />}
            </MDBContainer>
        </div>
    )
})

export default AdvisorList;
import { createSlice } from '@reduxjs/toolkit';
import { ChatOffThreadStatus } from '../../Constants/constants';
import { getSHA256 } from '../../Utils/utils';

const chatOffMsgSlice = createSlice({
    name: 'chatoff_message',
    initialState: {
        threads: [],
        total_unread_msg_count: 0,
        hash256: ''
    },
    reducers: {
        setChatOffThreads: (state, action) => {
            console.log('setChatOffThreads', action.payload);
            state.threads = action.payload.threads;
            state.total_unread_msg_count = action.payload.total_unread_msg_count;
        },
        addChatOffMessage: (state, action) => {
            console.log('addChatOffMessage', action.payload);
            const {message, cId, aId, is_recv_msg} = action.payload;
            const index = state.threads.findIndex(thread => thread._id == message.thread_id);
            if(index >= 0) {
                state.threads[index].last_message = message;
                state.threads[index].last_msg_time = message.createdAt;
                if(is_recv_msg) {
                    state.threads[index].unread_msg_count = state.threads[index].unread_msg_count + 1;
                    state.total_unread_msg_count++;
                }
            } else {
                state.threads.push({
                    _id: message.thread_id,
                    aId,
                    cId, 
                    unread_msg_count: is_recv_msg ? 1 : 0,
                    last_message: message,
                    last_msg_time: message.createdAt
                })
                if(is_recv_msg) state.total_unread_msg_count++;
            }
        },
        ackChatOffThread: (state, action) => {
            console.log('ackChatOffMessage', action.payload);
            const thread_id = action.payload;
            const index = state.threads.findIndex(thread=>thread._id === thread_id);
            if(index >= 0) {
                state.total_unread_msg_count -= state.threads[index].unread_msg_count;
                state.threads[index].unread_msg_count = 0;
            }
        },
        closeChatOffThread: (state, action) => {
            const thread_id = action.payload;
            const index = state.threads.findIndex(thread => thread._id === thread_id);
            if(index >= 0) {
                state.threads[index].status = ChatOffThreadStatus.CLOSED;
            }
        }
    }
});

export const {setChatOffThreads, addChatOffMessage, ackChatOffThread, closeChatOffThread} = chatOffMsgSlice.actions;

export const getTotalUnreadChatOffMsgCount = (state) => state.chatoff_message.total_unread_msg_count;
export const getChatOffThreads = (state) => state.chatoff_message.threads;
export const getChatOffThreadHash = (state, thread_id) => {
    if(!thread_id) return '';

    const thread = state.chatoff_message.threads.find(t => t._id === thread_id);
    if(thread) return getSHA256(thread);
    return '';
}

export default chatOffMsgSlice.reducer;
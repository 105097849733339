import React, {useEffect, useRef, useState} from "react";
import ApiService from "../../Core/Service/ApiService";
import { 
    MDBModal,
    MDBModalDialog,
    MDBModalBody,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBBtn,
    MDBSpinner,
    MDBInput,
    MDBModalFooter,
} from "mdb-react-ui-kit";
import ModalContext from "../../Context/ModalContext";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { memoizedGetAuthUser, updateAuth } from "../../Store/Reducer/authReducer";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import { RateLimit } from "../../Constants/constants";
import { floatV } from "../../Utils/utils";

const AdvisorRateModal = React.memo((props) => {
    const dispatch = useDispatch();
    const modalContext = useContext(ModalContext);
    const modalData = modalContext.modalData;
    const open = modalContext.openAdvisorRateModal;
    const setOpen = modalContext.setOpenAdvisorRateModal;
    const [formData, setFormData] = useState({
        rate_per_min_chat: 0
    })
    const [priceSetting, setPriceSetting] = useState({
        chat_rate_min: RateLimit.CHAT_MIN,
        chat_rate_max: RateLimit.CHAT_MAX
    });
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [advisor, setAdvisor] = useState();
    const authUser = useSelector(memoizedGetAuthUser);

    const closeModal = () => {
        setOpen(false);
    }

    useEffect(()=>{
        if(authUser) {
            setAdvisor(authUser);
            setFormData({
                ...formData,
                rate_per_min_chat: authUser.rate_per_min_chat
            })
        }
    }, [authUser]);

    useEffect(()=>{
        setLoading(true);
        ApiService.get('/advisor/price/index').then(result=>{
            setPriceSetting(result.data);
        })
        .finally(()=>{
            setLoading(false);
        })
    }, [])

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const updateRate = (e) => {
        e.preventDefault();

        if(formData.rate_per_min_chat < priceSetting.chat_rate_min || formData.rate_per_min_chat > priceSetting.chat_rate_max) {
            toast.warning("Kindly set a rate in accordance with our defined range");
            return;
        }

        setSaving(true);

        ApiService.post('/advisor/auth/updtRate', formData).then(result=>{
            dispatch(updateAuth({
                rate_per_min_chat: floatV(formData.rate_per_min_chat)
            }));
        })
        .finally(()=>{
            setSaving(false);
        })
    }

    const renderPC = () => {
        return (
        <div>
            <div className="d-flex">
                <div className="mt-1" style={{width: 130, color:"#3476cb"}}>Rate Per Min</div>
                <div>
                    <div className="d-flex align-items-center">
                        <span className="comment">USD</span>
                        <span className="ms-2" style={{width: 100}}>
                            <MDBInput   
                                type='number' 
                                className="text-center"
                                name='rate_per_min_chat'
                                value={formData.rate_per_min_chat} 
                                onChange={handleInputChange} 
                            /> 
                        </span>
                        <span className="mx-1 comment">/ min,</span>
                    </div>
                    <div className="comment mt-1">
                        (USD <span className="fw-bold" style={{color:'#555'}}>{priceSetting.chat_rate_min}</span>/min ~ 
                        USD <span className="fw-bold" style={{color:'#555'}}>{priceSetting.chat_rate_max}</span>/min)
                    </div>
                </div>
            </div>
            <div className="mt-4 d-flex">
                <div className="mt-1" style={{width: 130, color: '#3476cb'}}>My Share ({advisor?.commission}%)</div>
                <div>
                    <div className="d-flex align-items-center">
                        <span className="comment">USD</span>
                        <span className="ms-2" style={{width: 100}}>
                            <MDBInput type="number" 
                                className="text-center" 
                                name='chat-share' 
                                value={floatV(formData.rate_per_min_chat * advisor?.commission / 100)} 
                                readonly={true} /> 
                        </span>
                        <span className="ms-1 comment">/min</span>
                    </div>
                </div>
            </div>
        </div>)
    }

    if(open) {
        return (
            <MDBModal 
                open={open} 
                setOpen={setOpen} 
                tabIndex='-1' 
                className="edit-note-modal" 
                closeOnEsc={false} 
                staticBackdrop 
            >
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>My Rate</MDBModalTitle>
                            <MDBBtn tabIndex='-1' className='btn-close' color='none' onClick={closeModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody className="d-flex py-4 flex-column align-items-center">
                            { renderPC() }
                            { loading && <LoadingIndicator/> }
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="default" onClick={closeModal}>Close</MDBBtn>
                            <MDBBtn className="confideas-primary-btn" onClick={updateRate}>
                                {saving && (<>
                                    <MDBSpinner  size='sm'/>
                                    <span className="ms-2">Saving</span>
                                </>)}
                                {!saving && <span>Save</span>}
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        )
    } else {
        return <></>
    }
});

export default AdvisorRateModal;
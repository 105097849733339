import { MDBContainer } from "mdb-react-ui-kit";
import React, {useEffect, useState} from "react";
import ChatOffThreads from "./ChatOffThread";

const ChatOffThreadPage = React.memo((props) => {
    return (
        <MDBContainer breakpoint="lg">
            <ChatOffThreads />
        </MDBContainer>
    )
})

export default ChatOffThreadPage;
import React, { useEffect, useState } from "react";
import AdminTable from "../../../Components/AdminTable/AdminTable";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { Link, useNavigate } from "react-router-dom";
import styles from './Advisor.scss';
import { MDBCheckbox, MDBIcon, MDBInput, MDBSwitch } from "mdb-react-ui-kit";
import Paginator from "../../../Components/Paginator/Paginator";
import { PendingType, UserStatus } from "../../../Constants/constants";
import { toast } from "react-toastify";
import Moment from "react-moment";
import Select from "react-select";
import { getAvatarUrl } from "../../../Utils/utils";

const AdminAdvisorPendingList = React.memo((props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [advisorPendings, setAdvisorPendings] = useState([]);
    const [search, setSearch] = useState('');
    const [formData, setFormData] = useState({
        search: '',
        perPage: 10,
        pageNum: 1
    });
    const [total, setTotal] = useState(0);
    const columns = [
        {name: 'username', title: 'Username'},
        {name: 'legalname', title: 'Legal Name'},
        {name: 'email', title: 'Email'},
        {name: 'mobile', title: 'Mobile'},
        {name: 'birth', title: 'Birthday'},
        {name: 'pending_type', title: 'Pending Type'},
        {name: 'createdAt', title: 'Signup Date'},
        {name: 'action', title: 'Action'}
    ];

    useEffect(()=>{
        getAdvisorPendings();
    }, [formData]);

    const getAdvisorPendings = () => {
        setLoading(true);

        ApiService.post('/admin/advisor/pending/index', formData).then(result=>{
            setAdvisorPendings(result.data.advisor_pendings);
            setTotal(result.data.total);
        })
        .catch(error=>{

        })
        .finally(()=>{
            setLoading(false);
        })
    }

    const onSearchChange = (e) => {
        setSearch(e.target.value);
    }
    const onSearchBtnClicked = (e) => {
        e.preventDefault();
        setFormData({
            ...formData,
            search,
            pageNum: 1
        })
    }
    const onSearchKeyPress = (e) => {
        if(e.key == 'Enter') {
            e.preventDefault();
            setFormData({
                ...formData,
                pageNum: 1,
                search
            });
        }
    }
    const onPageChanged = (pageNum) => {
        setFormData({
            ...formData,
            pageNum
        })
    }
    const showDetail = (e, row) => {
        e.preventDefault();
        if(row.pending_type === PendingType.INSERT) {
            navigate('/admin/advisor/check/signup/' + row._id);
        } else {
            navigate('/admin/advisor/check/profileupdate/' + row._id);
        }
    }

    const breadcrumb=[{title: 'Advisor Pending'}, {title: 'List'}];
    return (<>
        <Breadcrumb breadcrumb={breadcrumb} />
        <div className="advisor-pending-list">
            <div className="d-flex">
                <div className="px-2">
                    <label>Count : </label>
                    <span className="ps-2">{total}</span>
                </div>
                <div className="flex-fluid d-flex justify-content-end mb-2">
                    <div className="d-flex align-items-center">
                        <MDBInput   type='text' 
                                    label='Search' 
                                    value={search}
                                    onChange={onSearchChange}
                                    onKeyPress={onSearchKeyPress}
                        />
                        <span className='mx-2 cursor-pointer' onClick={onSearchBtnClicked}>
                            <MDBIcon fas icon='search' />
                        </span>
                    </div>
                </div>
            </div>

            <AdminTable columns={columns} rows={advisorPendings} style={{minWidth: 1000}}>
                {(row, colname, rowIndex, colIndex) => {
                    switch(colname) {
                        case 'username':
                            return (
                                <div className="d-flex align-items-center">
                                    <img src={getAvatarUrl(row['avatar'])} className="advisor-avatar round-avatar-40 me-1" />
                                    {row.username} 
                                </div>
                            )
                        case 'online':
                            const online_status = row['online'] === true ? 'online' : 'offline';
                            return <div className={`online-status ${online_status.toLowerCase()}`}>
                                    {online_status}
                                </div>
                        case 'birth':
                            return <Moment format='YYYY-MM-DD'>{new Date(row['birth'])}</Moment>
                        case 'pending_type':
                            if(row.pending_type === 'insert') {
                                return <div className="pending-type insert">New</div>
                            } else if(row.pending_type === 'update') {
                                return <div className="pending-type update">Update</div>
                            }
                        case 'createdAt':
                            return <Moment format='YYYY-MM-DD'>{row.createdAt}</Moment>
                        case 'action':
                            return <div className="d-flex action">
                                <div className="edit cursor-pointer" onClick={(e)=>showDetail(e, row)}><MDBIcon far icon="edit" size='lg' color="warning" /></div>
                            </div>
                    }
                }}
            </AdminTable>

            <div className='d-flex justify-content-center mt-4'>
                <Paginator total={total} perPage={formData.perPage} onPageChanged={onPageChanged} />
            </div>
            {loading && <LoadingIndicator />}
        </div>
    </>)
});

export default AdminAdvisorPendingList;
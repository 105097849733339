import React, {useState, useEffect} from 'react';
import styles from './Paginator.scss';
import { MDBPagination, MDBPaginationItem, MDBPaginationLink } from 'mdb-react-ui-kit';

const Paginator = React.memo(({curPage, total, perPage, onPageChanged}) => {
    const [pgInfo, setPgInfo] = useState({
        curPage: curPage ?? 1,
        fromPage: 0,
        toPage: 0,
        lastPage: 0,
        pageShows: 5
    });

    useEffect( () => {
        updatePages();
    }, [pgInfo.curPage, pgInfo.lastPage, pgInfo.pageShows, total, perPage]);

    const calcPages = () => {
        setPgInfo({
            ...pgInfo,
            lastPage: Math.ceil(total/perPage),
        });
    }

    const updatePages = () => {
        const fromPage = Math.max(1, pgInfo.curPage - parseInt(pgInfo.pageShows / 2));
        const toPage = Math.min(fromPage + pgInfo.pageShows, pgInfo.lastPage + 1);
        setPgInfo({
            ...pgInfo,
            fromPage,
            toPage
        });
    }

    const goPage = (pageNum) => {
        if(pgInfo.curPage == pageNum) return;
        setPgInfo({
            ...pgInfo,
            curPage: pageNum
        });
        onPageChanged && onPageChanged(pageNum);
    }
    const goPrevPage = () => {
        if(pgInfo.curPage > 1) {
            goPage(pgInfo.curPage - 1);
        }
    }
    const goNextPage = () => {
        if(pgInfo.curPage < pgInfo.lastPage) {
            goPage(pgInfo.curPage + 1);
        }
    }

    const renderPages = () => {
        const pages = [];
        for(let p=pgInfo.fromPage; p<pgInfo.toPage; p++) {
            pages.push(p);
        }
        return pages.map((p, index) => (
            <MDBPaginationItem active={p == pgInfo.curPage} key={p}>
                <MDBPaginationLink onClick={()=>goPage(p)}>{p}</MDBPaginationLink>
            </MDBPaginationItem>
        ));
    }

    useEffect(()=> {
        if(total > 0) {
            calcPages();
        }
    }, [total, perPage])

    if(total > 0) {
        return (
            <nav aria-label='Page navigation'>
                <MDBPagination className='mb-0 paginator'>
                    <MDBPaginationItem>
                        <MDBPaginationLink onClick={goPrevPage} aria-label='Previous'>
                            <span aria-hidden='true'>«</span>
                        </MDBPaginationLink>
                    </MDBPaginationItem>

                    {renderPages()}

                    <MDBPaginationItem>
                        <MDBPaginationLink onClick={goNextPage} aria-label='Next'>
                            <span aria-hidden='true'>»</span>
                        </MDBPaginationLink>
                    </MDBPaginationItem>
                </MDBPagination>
            </nav>
        )
    } else {
        return <></>
    }

});

export default Paginator;
import { MDBBtn } from "mdb-react-ui-kit";
import React, {useEffect, useState} from "react";
import { useContext } from "react";
import ModalContext from "../../../Context/ModalContext";
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { useNavigate } from "react-router-dom";

const VerifyItems = React.memo((props) => {
    const navigate = useNavigate();
    // const modalContext = useContext(ModalContext);
    // const [loading, setLoading] = useState(false);
    // const [verifyItems, setVerifyItems] = useState([]);

    // useEffect(()=>{
    //     setLoading(true);
    //     ApiService.get('/advisor/verifyitem/index').then(response=>{
    //         setVerifyItems(response.data.verifyItems.filter(vi=>{
    //             return props.verifyItems.includes(vi._id);
    //         }))
    //     })
    //     .finally(()=>{
    //         setLoading(false);
    //     })
    // }, []);

    const showVerifyItems = () => {
        navigate('/advisor/activatelisting');
    }

    return (
        <div className="verify-items mb-4 d-flex  align-items-center justify-content-center">
            Click 
            <a 
                color="link" 
                className="p-0 mx-1 cursor-pointer" 
                onClick={showVerifyItems} 
                style={{textDecoration: 'underline'}}
            >
                Here
            </a> 
            to activate your listing on our website.
        </div>
    )
});

export default VerifyItems;
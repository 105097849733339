import { MDBCard, MDBCardBody, MDBCardHeader, MDBContainer, MDBIcon } from "mdb-react-ui-kit";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import SVG from 'react-inlinesvg';
import { getAvatarUrl } from "../../../Utils/utils";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";

const MyAdvisors = React.memo((props) => {
    const [advisors, setAdvisors] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const gotoAdvisorDetail = (advisor) => {
        navigate(`/client/advisor/detail/${advisor._id}`);
    }

    useEffect(()=>{
        {(async ()=>{
            try {
                setLoading(true);

                // To get lastChats by advisor
                let response = await ApiService.get('/client/chat/myadvisors');
                const { ad_lastChats } = response.data;
                
                if(ad_lastChats?.length > 0) {
                    // To get total chatoff_message by advisors.
                    const aIds = ad_lastChats.map(lc => lc.aId._id);
                    response = await ApiService.post('/chatoff_message/totalcount', {partnerIds: aIds});
                    const { messageCounts } = response.data;
                    setAdvisors(ad_lastChats.map(lc => {
                        const mc = messageCounts.find(mc => mc._id == lc.aId._id);
                        return {
                            ...lc.aId,
                            lastChat: lc.lastChat,
                            chatOffMessageCount: mc?.total ?? 0
                        }
                    }));
                }
            } finally {
                setLoading(false);
            }
        })()}
    }, []);

    const renderAdvisors = () => {
        if(advisors?.length > 0) {
            return advisors.map((advisor, index) => (
                <div className="d-flex cursor-pointer align-items-center mb-3 underline underline-5px" key={index} onClick={e=>gotoAdvisorDetail(advisor)}>
                    <div className="flex-fluid d-flex align-items-center">
                        <img src={getAvatarUrl(advisor.avatar)} className="round-avatar-40" />
                        <div className="ms-2">
                            <div>{advisor.username}</div>
                            <div>
                                <label>Last Chat: </label>
                                <span className="ms-2"><Moment format="YYYY-MM-DD">{advisor.lastChat.startedAt}</Moment></span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        {advisor.chatOffMessageCount > 0 && <span className="ms-1 me-2 text-amber1" style={{fontSize: '120%'}}>{ advisor.chatOffMessageCount }</span> }
                        <MDBIcon fas icon="angle-right" size="lg" />
                    </div>
                </div>
            ))
        } else {
            {loading ? '' : <div className="d-flex justify-content-center align-items-center">
                <SVG src='/img/icons/no_found.svg' style={{height:40, width: 40}} /><div className="ms-2 mt-1">No Chats</div>
            </div>}
        }
    }

    return (
        <MDBContainer breakpoint="lg" className="p-4">
            <MDBCard className="client-my-advisors">
                <MDBCardHeader>
                    <div className="d-flex align-items-center">
                        <MDBIcon fas icon='user-graduate' color="info" size='lg' />
                        <span className="ms-2 fw-bold">My Advisors</span>
                    </div>
                </MDBCardHeader>
                <MDBCardBody>
                    { renderAdvisors() }
                </MDBCardBody>
            </MDBCard>
            {loading && <LoadingIndicator/>}
        </MDBContainer>
    )
});

export default MyAdvisors;
import React from "react";
import ReactHowler from 'react-howler';
import { useContext } from "react";
import ModalContext from "../Context/ModalContext";
import { useSelector } from "react-redux";
import { IsAuthenticated } from "../Store/Reducer/authReducer";

const CallRing = React.memo((props) => {
    const modalContext = useContext(ModalContext);
    const isAuthenticated = useSelector(IsAuthenticated);

    return <>
        {modalContext.openAcceptChatModal && <ReactHowler src='/audio/chat_call.mp3' playing={true} loop={true} />}
    </>
});

export default CallRing;
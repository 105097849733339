import React, {useState , useEffect} from "react";
import { toast } from 'react-toastify';
import SVG from 'react-inlinesvg';
import styels from './show.scss';
import { MDBCol, MDBRow, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, MDBIcon, MDBSpinner } from "mdb-react-ui-kit";
import { getAvatarUrl, isMobileScreen } from "../../../Utils/utils";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { IsAuthenticated, IsFavoriteAdvisor, addFavoriteAdvisor, memoizedGetAuthUser, removeFavoriteAdvisor } from "../../../Store/Reducer/authReducer";
import { IsChatSocketConnected, getChatSocket } from "../../../Store/Reducer/socketReducer";
import { MIN_CHAT_TIME, SendMessages, UserRole } from "../../../Constants/constants";
import { getChangedAdvisors, getChangedAdvisorsHash256 } from "../../../Store/Reducer/changedAdvisorReducer";
import { useContext } from "react";
import ModalContext from "../../../Context/ModalContext";
import { setPaymentRequest } from "../../../Core/Service/JwtService";
import ApiService from "../../../Core/Service/ApiService";
import JwtService from "../../../Core/Service/JwtService";
import { useNavigate } from "react-router-dom";
import { getOpenChatOffThread } from "../../../Store/Reducer/chatoffMsgReducer";

const MainInfo = (props) => {
    const {advisor} = props;
    const [advisorUpdtInfo, setAdvisorUpdtInfo] = useState(advisor);
    const changedAdvisorHash = useSelector(getChangedAdvisorsHash256);
    const changedAdvisors = useSelector(getChangedAdvisors);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoggedOn = useSelector(IsAuthenticated);
    const authUser = useSelector(memoizedGetAuthUser);
    const modalContext = useContext(ModalContext);
    const [setCheckingBuzz] = useState(false);
    const [checkingAvailCoupon, setCheckingAvailCoupon] = useState(false);
    const [addingFavorite, setAddingFavorite] = useState(false);
    const isFavoriteAdvisor = useSelector((state) => IsFavoriteAdvisor(state, advisor._id));
    const [availCoupon, setAvailCoupon] = useState();

    const updateAdvisorInfo = (adInfo) => {
        const info = {...adInfo};
        if(info.isLoggedIn != true) {
            info.serviceChatStatus = 'offline';
        } else {
            if(info.isBusy) {
                info.serviceChatStatus = 'busy';
            } else {
                info.serviceChatStatus = info.isAvailableChat ? 'online' : 'offline';
            }
        }
        setAdvisorUpdtInfo(info);
    }

    useEffect(()=>{
        if(changedAdvisorHash) {
            const chgAdvisor = changedAdvisors.find(a=>a._id === advisor._id);
            if(chgAdvisor) updateAdvisorInfo(chgAdvisor);
        }
    }, [changedAdvisorHash])

    useEffect(()=>{
        if(authUser?.role === UserRole.CLIENT) {
            ApiService.get(`/client/coupon/available/${advisor._id}`).then(response => {
                setAvailCoupon(response.data.coupon);
            })
            .finally(()=>{

            })
        }
    }, [authUser]);

    const onChatOffMessageClicked = async(e) => {
        e.preventDefault();
        if(authUser?.role != UserRole.CLIENT) {
            toast.warn("Only clients could use this service.");
            return;
        }

        try {
            const response = await ApiService.get(`/chatoff_thread/getopen/${advisor._id}`);
            if(isMobileScreen()) {
                JwtService.setChatOffThread(response.data.thread);
                JwtService.setChatOffPartner(advisor);
                navigate('/chatoff_message');
            } else {
                modalContext.setModalData({
                    thread: response.data.thread,
                    partner: advisor
                });
                modalContext.setOpenChatOffMessageModal(true);
            }
        } finally {

        }
    }

    const onBuzzBtnClicked = (e) => {
        e.preventDefault();

        if(!isLoggedOn) {
            modalContext.setOpenSignInModal(true);
            return;
        }
        if(authUser?.role != UserRole.CLIENT) {
            toast.warn("Only clients could use this service.");
            return;
        }

        setCheckingBuzz(true);
        ApiService.post('/client/buzz/can_send', {aId: advisor._id}).then(result=>{
            if(!result.data.canSend) {
                toast.warning("You have already sent a chat request. You can send another one in an hour");
                return;
            }

            modalContext.setModalData({advisor});
            modalContext.setOpenSendBuzzModal(true);
        })
        .finally(()=>{
            setCheckingBuzz(false);
        })
    }

    const onChatBtnClicked = () => {
        if(!isLoggedOn) {
            modalContext.setOpenSignInModal(true);
            return;
        }
        if(authUser?.role != UserRole.CLIENT) {
            toast.warn("Only clients could use this service.");
            return;
        }

        // To check if the client has got coupon from the advisor
        setCheckingAvailCoupon(true);
        ApiService.get('/client/coupon/available/' + advisor._id).then(result=>{
            if(!result.data.coupon && authUser.balance < (MIN_CHAT_TIME-1) * advisor.rate_per_min_chat) {
                // if client's balance is less than limit, then goto payment page.
                // Upon payment made with success, it would emit request_chat message.
                JwtService.setPaymentRequest({
                    purpose: 'request_chat',
                    advisor: advisor,
                });
                modalContext.setOpenPayAmountModal(true);
                return;
            } else {
                modalContext.setModalData({advisor: advisor});
                modalContext.setOpenEnoughBalanceModal(true);
            }
        })
        .finally(()=>{
            setCheckingAvailCoupon(false);
        })
    }

    const onRemoveFavorite = (e) => {
        e.preventDefault();

        const aId = advisor._id;
        setAddingFavorite(true);
        ApiService.post('/client/advisor/favorite/remove', {aId}).then(result => {
            dispatch(removeFavoriteAdvisor(aId));
        })
        .finally(()=>{
            setAddingFavorite(false);
        })
    }

    const onAddFavorite = (e) => {
        e.preventDefault();

        const aId = advisor._id;
        setAddingFavorite(true);
        ApiService.post('/client/advisor/favorite/add', {aId}).then(result => {
            dispatch(addFavoriteAdvisor(aId));
        })
        .finally(()=>{
            setAddingFavorite(false);
        })
    }

    return (
        <div className="main-info-container d-flex p-3">
            <div className="me-3 d-flex justify-content-center photo position-relative">
                <img    src={getAvatarUrl(advisor?.avatar)} 
                        alt='Advisor' 
                        title='Advisor Avatar' />
                {availCoupon && <div className="coupon" style={{left: 'calc(50% - 15px)', top: -10}}>
                    {availCoupon.availMinutes}
                </div>}
            </div>

            <div className="d-flex flex-column other-info">
                <div>
                    <div className="advisor-name">
                        <strong>{ advisor?.username }</strong>
                    </div>
                    <div className="text-gray1">
                        {advisor?.service_name}
                    </div>
                </div>

                <div className="flex-fluid d-flex flex-column justify-content-end">
                    <div><span className="rate-chat">${advisor?.rate_per_min_chat}</span> / min</div>
                    <div className="advisor-comm">
                        <MDBBtn className={`chat-btn ${advisorUpdtInfo.serviceChatStatus}`} 
                                disabled={advisorUpdtInfo.serviceChatStatus != 'online' || checkingAvailCoupon}
                                onClick={onChatBtnClicked} 
                                style={{textTransform: 'none'}}
                        >
                            {checkingAvailCoupon && <MDBSpinner role='status' size="sm" />}
                            {!checkingAvailCoupon && <img src='/img/icons/message.svg' alt='Chat' />}
                            <span className="ms-2">Live Chat</span>
                        </MDBBtn>
                    </div>
                    <div className="mt-1 text-gray1">
                        {advisor?.total_chats ?? 0} Readings
                    </div>
                </div>

                {authUser?.role === UserRole.CLIENT && 
                <div className="action-bar d-flex align-items-center">
                    { isFavoriteAdvisor && 
                        <div onClick={onRemoveFavorite} 
                            className="trash-favorite-btn d-flex justify-content-center align-items-center cursor-pointer"
                            title="Remove from favorite advisor"
                        >
                            {addingFavorite && <MDBSpinner />}
                            {!addingFavorite && <MDBIcon fas icon='heart' color="danger" size="lg" />}
                        </div> 
                    }
                    { !isFavoriteAdvisor && 
                        <div onClick={onAddFavorite} 
                            className="add-favorite-btn d-flex justify-content-center align-items-center cursor-pointer"
                            title="Add to favorite advisor"
                        >
                            {addingFavorite && <MDBSpinner />}
                            {!addingFavorite && <MDBIcon far icon='heart' color='danger' size="lg" />}
                        </div>
                    }
                    <div className="buzz-btn ms-2">
                        <MDBBtn tag='a' color="none" onClick={onChatOffMessageClicked}>
                            <SVG className="bell-icon" 
                                src='/img/icons/envelope.svg' 
                                width={30} 
                                height={30} 
                                fill="#a08aed"
                                title="Send Buzz"
                            />
                        </MDBBtn>
                    </div>
                </div>}
            </div>
        </div>
    )
};

export default MainInfo;
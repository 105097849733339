import { MDBContainer } from "mdb-react-ui-kit";
import React, {useEffect, useState} from "react";
import ChatOffMessage from "./ChatOffMessage";
import JwtService from "../../Core/Service/JwtService";
import { getAvatarUrl } from "../../Utils/utils";

const ChatOffMessagePage = React.memo((props) => {
    const thread = JwtService.getChatOffThread();
    const partner = JwtService.getChatOffPartner();
    const [availCoupon, setAvailCoupon] = useState();

    return (
        <MDBContainer breakpoint="lg">
            <div className="d-flex align-items-center justify-content-center p-2 pt-0" style={{borderBottom:'1px solid #eee'}}>
                <img src={getAvatarUrl(partner.avatar)} className="round-avatar-40" />
                <span className="ms-2 fw-bold" style={{color:'purple'}}>{partner.username}</span>
                {availCoupon && <div className="position-relative ms-4" style={{width:30, height: 30}}>
                    <span className="coupon">{availCoupon.availMinutes}</span>
                </div>}
            </div>
            <ChatOffMessage 
                partner={partner} 
                thread={thread} 
                setAvailCoupon={setAvailCoupon} 
            />
        </MDBContainer>
    )
});

export default ChatOffMessagePage;
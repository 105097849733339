import React, {useEffect, useRef, useState} from "react";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBContainer,
        MDBFile,
        MDBInput, MDBRow,MDBTextArea} from "mdb-react-ui-kit";
import { scrollToElement, validateForm } from "../../../Utils/utils";
import Select from "react-select";
import ApiService from "../../../Core/Service/ApiService";
import { toast } from "react-toastify";
import ImageSelector from "../../../Components/ImageSelector/ImageSelector";
import { RateLimit } from "../../../Constants/constants";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";

const AdvisorSignupStep3 = React.memo((props) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(props.data);
    const [price, setPrice] = useState({
        chat_rate_min: RateLimit.CHAT_MIN,
        chat_rate_max: RateLimit.CHAT_MAX
    })
    const [rules, setRules] = useState({
        username: {
            label: 'Stage Name',
            required: 'string',
            maxlength: 30
        },
        avatar: {required: 'file'},
        service_name: {
            label: 'Service Name',
            required: 'string',
            maxlength: 30,
        },
        rate_per_min_chat: {
            label: 'Chat Rate',
            required: 'number',
            min: RateLimit.CHAT_MIN,
            max: RateLimit.CHAT_MAX,
        },
        aboutme: {
            label: 'About Me',
            required: 'string'
        },
        service_description: {
            label: 'Service Description',
            required: 'string'
        }
    })
    const [inputErrors, setInputErrors] = useState({});

    useEffect(()=>{
        setLoading(true);
        ApiService.get('/advisor/price/index').then(result=>{
            setPrice(result.data);
            setRules({
                ...rules,
                rate_per_min_chat : {
                    ...rules.rate_per_min_chat,
                    min: parseFloat(result.data.chat_rate_min),
                    max: parseFloat(result.data.chat_rate_max),
                    messages: {
                        min: `Please choose a rate between $${result.data.chat_rate_min} and $${result.data.chat_rate_max}`,
                        max: `Please choose a rate between $${result.data.chat_rate_min} and $${result.data.chat_rate_max}`
                    }
                }
            })
        })
        .finally(()=>{
            setLoading(false);
        })
    }, [])

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }
    const checkUsername = (username) => {
        const specialCharsRegs = /[\\\/?"|'@-]/;
        return specialCharsRegs.test(username) == false;
    }
    const onPrev = (e) => {
        e.preventDefault();
        props.onPrev && props.onPrev(formData);
    }
    const onNext = (e) => {
        e.preventDefault();

        setFormData({
            ...formData,
            username: formData['username'].trim()
        })

        const valid_ret = validateForm(formData, rules);
        if(valid_ret.result) {
            if(!checkUsername(formData.username)) {
                setInputErrors({
                    username: 'Please input the correct stage name.'
                })
                scrollToElement(document.getElementById('username'));
                return;
            }

            setInputErrors({});
            props.onNext && props.onNext(formData);
        } else {
            setInputErrors(valid_ret.errors);
            scrollToElement(document.getElementById(Object.keys(valid_ret.errors)[0]))
        }
    }

    const onPhotoSelected = (image, file) => {
        if(image.width < 200 || image.height < 200) {
            toast.error('Image size(width, height) should be larger than 200px');
            return false;
        }
        // if(file.size > 2*1024*1024) {
        //     toast.warning("Please select the image of less than 2MB.");
        //     return;
        // }
        
        setFormData({
            ...formData,
            avatar: file
        })

        return true;
    }

    return <MDBContainer breakpoint="lg" className="d-flex justify-content-center">
        <MDBCard className="signup-step3-card">
            <MDBCardHeader className="text-center">
                <div className="mb-2">STEP 3 OF 4</div>
                <h4 style={{color:'purple'}}>Please fill in your listing.</h4>
            </MDBCardHeader>

            <MDBCardBody className="">
                <div className="d-sm-flex main-info">
                    <div className="avatar d-flex flex-column align-items-center me-4" id='avatar'>
                        <div className="label mb-2 required">My Photo:</div>
                        <ImageSelector  
                            cropImage={true}
                            defaultSrc={ formData.avatar ? URL.createObjectURL(formData.avatar) : '/img/others/default_user.png' }
                            onImageSelected={onPhotoSelected} />
                        {inputErrors.avatar && <div className="error text-center">{ inputErrors.avatar }</div>}
                    </div>
                    <div className="stage-service-name">
                        <div className="stage-name w-100" id='username'>
                            <div className="label required">Stage Name: </div>
                            <MDBInput   className="form-control"
                                        name='username'
                                        value={formData.username}
                                        tabIndex={1}
                                        onChange={handleInputChange} />
                            {inputErrors.username && <div className="error">{ inputErrors.username }</div>}
                        </div>
                        <div className="servicename mt-2 w-100" id='service_name'>
                            <div className="d-flex">
                                <div className="label required">Service Name: </div>
                                <div className="flex-fluid d-flex justify-content-end comment">30 Letters Maximum</div>
                            </div>
                            <MDBInput   className="form-control"
                                    name='service_name'
                                    value={formData.service_name}
                                    tabIndex={2}
                                    maxLength={30}
                                    onChange={handleInputChange} />
                            {inputErrors.service_name && <div className="error">{ inputErrors.service_name }</div>}
                        </div>
                        <div className="chat-rate mt-2 w-100" id='rate_per_min_chat'>
                            <div className="d-flex">
                                <div className="label required">Chat Rate: </div>
                                <div className="flex-fluid d-flex justify-content-end comment"> ${price.chat_rate_min} to ${price.chat_rate_max} Per Minute</div>
                            </div>
                            <MDBInput   type='number' 
                                        className="text-center" 
                                        name='rate_per_min_chat'
                                        tabIndex={3}
                                        value={formData.rate_per_min_chat} 
                                        min={price.chat_rate_min}
                                        max={price.chat_rate_max}
                                        onChange={handleInputChange} />
                            {inputErrors.rate_per_min_chat && <div className="error">{ inputErrors.rate_per_min_chat }</div>}
                        </div>
                    </div>
                </div>

                <div className="aboutme mt-4" id='aboutme'>
                    <span className="label required">About Me: </span>
                    <MDBTextArea    className="form-control" 
                                    value={formData.aboutme}
                                    tabIndex={4}
                                    name='aboutme' 
                                    rows={4} 
                                    onChange={handleInputChange} />
                    {inputErrors.aboutme && <div className="error">{ inputErrors.aboutme }</div>}
                </div>

                <div className="serviceDesc mt-4" id='service_description'>
                    <span className="label required">Service Offered: </span>
                    <MDBTextArea className="form-control"
                                name='service_description'
                                value={formData.service_description}
                                tabIndex={5}
                                rows={6}
                                onChange={handleInputChange} />
                    {inputErrors.service_description && <div className="error">{ inputErrors.service_description }</div>}
                </div>

                {loading && <LoadingIndicator />}
            </MDBCardBody>

            <MDBCardFooter className="text-center p-4">
                <MDBBtn color="second" tabIndex={-1} onClick={onPrev}>Prev</MDBBtn>
                <MDBBtn className="ms-4 confideas-primary-btn" tabIndex={-1} color="primary" onClick={onNext}>Next</MDBBtn>
            </MDBCardFooter>
        </MDBCard>
    </MDBContainer>
});

export default AdvisorSignupStep3;
import React, { useState, useEffect, useRef } from "react";
import { FUNDING, PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { toast } from "react-toastify";
import { MDBSpinner } from "mdb-react-ui-kit";
import ApiService from "../../Core/Service/ApiService";

const PaypalCheckOut = React.memo((props) => {
    const [ppInitiated, setPpInitiated] = useState(false);
    const [message, setMessage] = useState({
        msgType: 'info',
        message: 'Initializing Paypal...'
    });
    const amount = useRef(0);

    useEffect(()=>{
        amount.current = props.amount
    }, [props.amount]);

    const onPaypalInit = () => {
        setPpInitiated(true);
        setMessage(null);
    }

    const createOrder = async () => {
        try {
            const response  =  await ApiService.post('/client/paypal/create_payment', {amount: amount.current});
            if(response.data.id) {
                return response.data.id;
            } else {
                const errorDetail = response.data.details?.[0];
                const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                    : JSON.stringify(orderData);
    
                toast.warning(errorMessage);
            }
        }
        catch(error) {
            console.error(error);
            toast.warning('Could not initiate PayPal Checkout');
        }
    }
    const onApprove = async(data, actions) => {
        try {
            setMessage({type: 'info', message: 'Checking Payment...'});
            const response = await ApiService.post("/client/paypal/capture", {orderId: data.orderID});
            setMessage({type: 'info', message: 'Payment successful'})

            // below, I am using setTimeout because UI effect.
            setTimeout(()=>{
                props.onPaymentSuccess && props.onPaymentSuccess(parseFloat(response.data.balance));
            }, 500);
        } catch(error) {
            console.error(error);
            setMessage({type: 'error', message: 'Payment failed.'})
        }
    }

    return (<>
        <PayPalButtons
            style={{
                shape: 'rect',
                layout: "vertical", //default value. Can be changed to horizontal
            }}
            createOrder={createOrder}
            onApprove={onApprove}
            onInit={onPaypalInit}
            shippingPreference="NO_SHIPPING"
            fundingSource={FUNDING.PAYPAL}
        >
        </PayPalButtons>
        {message && (
            <div className="d-flex justify-content-center align-items-center">
                <MDBSpinner size="sm" />
                <span className="fs-120 ms-2">{ message.message }</span>
            </div>
        )}
    </>)
});

export default PaypalCheckOut;
import { MDBAccordion, MDBAccordionItem, MDBBadge, MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBCheckbox, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBListGroup, MDBListGroupItem, MDBRow, MDBSpinner, MDBTextArea } from "mdb-react-ui-kit";
import React from "react";
import styles from './Advisor.scss';
import PersonalData from "./Partials/PersonalData";
import { useEffect } from "react";
import ApiService from "../../../Core/Service/ApiService";
import { useState } from "react";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import SkillServiceData from "./Partials/SkillServiceData";
import ApplicantDocument from "./Partials/ApplicantDocument";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PendingStatus, UserStatus } from "../../../Constants/constants";
import Select from "react-select";
import { toast } from "react-toastify";
import { getBackendAssetUrl, scrollUpTop } from "../../../Utils/utils";

const AdminAdvisorCheckProfile = React.memo((props) => {
    const [loading, setLoading] = useState(false);
    const [advisor, setAdvisor] = useState(null);
    const params = useParams();
    const navigate = useNavigate();
    const aId = params.aId;
    const pendingStatusOpts = [
        { value: PendingStatus.WAITING, label: "Waiting"},
        { value: PendingStatus.DECLINED, label: "Decline"},
        { value: PendingStatus.APPROVED, label: "Approve" },
    ];
    const [curPendingStatusOpt, setPendingStatusOpt] = useState(null);
    const [updating, setUpdating] = useState(false);
    const [formData, setFormData] = useState({
        status: '',
        decline_comment: '',
        listing: false,
        selVerifyItems: []
    })
    const [verifyItems, setVerifyItems] = useState([]);
    const [addVerifyItems, setAddVerifyItems] = useState([]);
    const [removeVerifyItems, setRemoveVerifyItems] = useState([]);

    useEffect(()=>{
        (async() => {
            try {
                setLoading(true);

                // To get verifyItems
                let response = await ApiService.get('/admin/verifyitem/index');
                const dl_verifyItems = response.data.verifyItems;

                // To get pending_advisor's profile
                response = await ApiService.get(`/admin/advisor/pending/${aId}`);
                const advisor = response.data.advisor;
                setAdvisor(advisor);
                setPendingStatusOpt(pendingStatusOpts.find(opt=>opt.value === advisor.pending_status));
                setFormData({
                    ...formData,
                    status: advisor.pending_status,
                    listing: advisor.aId.listing,
                    selVerifyItems: dl_verifyItems.filter(vi => {
                        return advisor.verifyItems.includes(vi._id);
                    })
                });

                setVerifyItems(dl_verifyItems.filter(vi => !advisor.verifyItems.includes(vi._id)));
            } finally {
                setLoading(false);
            }
        })();

        setTimeout(()=>{
            scrollUpTop();
        }, 500);
    }, []);

    const goBack = (e) => {
        e.preventDefault();
        navigate(-1);
    }

    const onDeclineReasonChg = (e) => {
        setFormData({
            ...formData,
            decline_comment: e.target.value
        })
    }

    const onPendingStatusChg = (opt) => {
        setPendingStatusOpt(opt);
        setFormData({
            ...formData,
            status: opt.value
        })
    }

    const onUserStatusSaveBtnClick = (e) => {
        e.preventDefault();

        let confirmMessage = "";
        if(formData.listing && formData.selVerifyItems.length > 0) {
            confirmMessage = "The advisor has still items to be verified.\nAre you sure to list him?";
        } else {
            confirmMessage = "Are you sure to save?";
        }
        if(window.confirm(confirmMessage) == false) return;

        setUpdating(true);
        const data = {
            aId,
            status: formData.status,
            decline_comment: formData.decline_comment,
            listing: formData.listing,
            verifyItems: formData.selVerifyItems.map(vi=>vi._id)
        }
        ApiService.post('/admin/advisor/check/profile', data).then(result => {
            toast.success('User status changed successfully');
            navigate('/admin/advisor/pending/list');
        })
        .finally(()=>{
            setUpdating(false);
        })
    }

    const onAddVerifyItemSelChanged = (e, verifyItem) => {
        if(e.target.checked) {
            setAddVerifyItems([
                ...addVerifyItems,
                verifyItem
            ]);
        } else {
            setAddVerifyItems(addVerifyItems.filter(addItem => addItem._id != verifyItem._id));
        }
    }
    const onRemoveVerifyItemSelChanged = (e, verifyItem) => {
        if(e.target.checked) {
            setRemoveVerifyItems([
                ...removeVerifyItems,
                verifyItem
            ]);
        } else {
            setRemoveVerifyItems(removeVerifyItems.filter(removeItem => removeItem._id != verifyItem._id));
        }
    }
    const onAddVerifyItemBtnClicked = () => {
        setFormData({
            ...formData,
            selVerifyItems: [
                ...formData.selVerifyItems,
                ...addVerifyItems
            ]
        });
        setVerifyItems(verifyItems.filter(vi=>{
            const found = addVerifyItems.find(addItem => addItem._id === vi._id);
            return found ? false : true;
        }));
        setAddVerifyItems([]);
    }
    const onRemoveVerifyItemBtnClicked = () => {
        setVerifyItems([
            ...verifyItems,
            ...removeVerifyItems
        ]);
        setFormData({
            ...formData,
            selVerifyItems: formData.selVerifyItems.filter(vi=>{
                const found = removeVerifyItems.find(rmItem => rmItem._id === vi._id);
                return found ? false : true;
            })
        });
        setRemoveVerifyItems([]);
    }
    const onListingChanged = (e) => {
        setFormData({
            ...formData,
            listing: e.target.checked
        })
    }

    const renderPersonalData = () => {
        return (
        <MDBCard>
            <MDBCardHeader>
                <MDBCardTitle>
                    <MDBIcon fas icon="user-circle" size="lg" className="me-1" />
                    <span className="accordion-item-title">Personal Information</span>
                </MDBCardTitle>
            </MDBCardHeader>
            <MDBCardBody>
                { advisor && <PersonalData advisor={advisor} compareData={advisor.aId} /> }
            </MDBCardBody>
        </MDBCard>
        )
    }
    const renderSkillServiceData = () => {
        return (
        <MDBCard className="mt-4">
            <MDBCardHeader>
                <MDBCardTitle>
                    <MDBIcon fas icon="atom" size='lg' className="me-1" />
                    <span className="accordion-item-title">Services</span>
                </MDBCardTitle>
            </MDBCardHeader>
            <MDBCardBody>
                { advisor && <SkillServiceData advisor={advisor} compareData={advisor.aId} /> }
            </MDBCardBody>
        </MDBCard>
        )
    }
    const renderApplicantDocument = () => {
        return (
        <MDBCard className="mt-4">
            <MDBCardHeader>
                <MDBIcon far icon="id-card" className="me-1" size="lg" />
                <span className="accordion-item-title">Application Documents</span>
            </MDBCardHeader>
            <MDBCardBody>
                { advisor && <ApplicantDocument advisor={advisor} compareData={advisor.aId} /> }
            </MDBCardBody>
        </MDBCard>
        )
    }
    const renderVideo = () => {
        if(advisor?.video) {
            return (
                <MDBCard className="mt-4">
                    <MDBCardHeader>
                        <MDBIcon fas icon="video" size="lg" />
                        <span className="accordion-item-title">Introduction Video</span>
                    </MDBCardHeader>
                    <MDBCardBody className="d-flex flex-column justify-content-center align-items-center p-4">
                        <video 
                            src={getBackendAssetUrl(advisor.video)}
                            style={{width: 400, height: 300}}
                            controls
                        />
                        <span className={`${advisor.video != advisor.aId.video ? 'updated' : ''}`}></span>
                    </MDBCardBody>
                </MDBCard>
            )
        } else {
            return <></>
        }
    }
    const renderVerifyItems = () => {
        return (
        <MDBCard className="mt-4">
            <MDBCardHeader>
                <MDBIcon far icon="check-circle" className="me-1" size="lg" />
                <span className="accordion-item-title">Verify</span>
            </MDBCardHeader>
            <MDBCardBody>
                <div className="d-flex">
                    <div className="flex-fluid px-4 d-flex flex-column align-items-center">
                        <h6>Verify Items</h6>
                        <MDBListGroup light style={{maxWidth: 250}}>
                            {verifyItems.map((vi, index) => (
                                <MDBListGroupItem key={index}>
                                    <MDBCheckbox 
                                        inline 
                                        onChange={e=>onAddVerifyItemSelChanged(e, vi)}
                                        checked={addVerifyItems.find(addItem => addItem._id == vi._id) ? true : false}
                                    />
                                    {vi.label}
                                </MDBListGroupItem>
                            ))}
                        </MDBListGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <MDBBtn color="link" onClick={onAddVerifyItemBtnClicked}>Add VerifyItem</MDBBtn>
                        <MDBBtn color="link" onClick={onRemoveVerifyItemBtnClicked}>Remove VerifyItem</MDBBtn>
                    </div>
                    <div className="flex-fluid px-4 d-flex flex-column align-items-center">
                        <h6>Items to be verified for this advisor</h6>
                        <MDBListGroup light style={{maxWidth: 250}}>
                            {formData.selVerifyItems.map((vi, index) => (
                                <MDBListGroupItem key={index}>
                                    <MDBCheckbox 
                                        inline 
                                        onChange={e=>onRemoveVerifyItemSelChanged(e, vi)}
                                        checked={removeVerifyItems.find(rmItem => rmItem._id == vi._id) ? true : false}
                                    />
                                    {vi.label}
                                </MDBListGroupItem>
                            ))}
                        </MDBListGroup>
                    </div>
                </div>
            </MDBCardBody>
        </MDBCard>
        )
    }
    const renderPayoutMethod = () => {
        return (
        <MDBCard className="mt-4">
            <MDBCardHeader>
                <MDBIcon fas icon="money-bill" className="me-1" size="lg"/>
                <span className="accordion-item-title">Payout Method</span>
            </MDBCardHeader>
            <MDBCardBody>
            </MDBCardBody>
        </MDBCard>
        )
    }
    const renderAdminAction = () => {
        return (
        <div className="mt-4 pending-type w-100">
            <div className="text-left d-flex align-items-center">
                <MDBCheckbox 
                    checked={formData.listing}
                    onChange={onListingChanged}
                />
                <span>Listing</span>
            </div>
            <div className="d-flex mt-2">
                <div className="pe-2 d-flex justify-content-start align-items-center">Profile Change : </div>
                <div className="ms-4">
                    { curPendingStatusOpt && <Select options={pendingStatusOpts} onChange={opt=>onPendingStatusChg(opt)} defaultValue={curPendingStatusOpt} /> }
                </div>
            </div>
            {formData.status === PendingStatus.DECLINED && (
                <div className="w-100">
                    <div className="text-left required">Reason : </div>
                    <div className="flex-fluid">
                        <MDBTextArea label=""
                                    value={formData.decline_comment}
                                    rows={4}
                                    style={{backgroundColor: 'white'}}
                                    onChange={e=>onDeclineReasonChg(e)}
                        />
                    </div>  
                </div>
            )}
        </div>
        )
    }

    const breadcrumb = [];
    breadcrumb.push({title: 'Advisor Pending', url:'/admin/advisor/pending/list'});
    breadcrumb.push({title: 'Show'});

    return <MDBContainer breakpoint="lg" className="admin-advisor-show-container">
        <Breadcrumb breadcrumb={breadcrumb} className='mb-4' />
        {renderPersonalData()}
        {renderSkillServiceData()}
        {renderApplicantDocument()}
        {renderPayoutMethod()}
        {renderVideo()}
        {/* {renderVerifyItems()} */}
        {renderAdminAction()}

        <div className="d-flex justify-content-end mt-4">
            <MDBBtn onClick={onUserStatusSaveBtnClick} className="mx-2 confideas-primary-btn">
                {updating && <>
                    <MDBSpinner size='sm' role='status' tag='span' className='me-2' />
                    Saving
                </>}
                {!updating && <span>Save</span>}
            </MDBBtn>
            <MDBBtn onClick={goBack} color="second">Back</MDBBtn>
        </div>
        {loading && <LoadingIndicator />}
    </MDBContainer>
});

export default AdminAdvisorCheckProfile;
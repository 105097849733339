import React, { useEffect } from 'react';
import styles from './TermsOfUse.scss';
import { MDBContainer } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import { scrollUpTop } from '../../../Utils/utils';
import InfoCenterNavBar from '../NavBar/NavBar';

const TermsOfuse = React.memo((props) => {
    useEffect(()=>{
        scrollUpTop();
    }, [])

    return <MDBContainer breakpoint='lg' className='terms-of-use p-4'>
        <h3 className='text-center title'>Terms of Use</h3>
        <p style={{color:'purple'}}>Welcome to Confideas! </p>
        <p>
            We aim to create a comforting virtual space for individuals to <span style={{color:'#8415bb'}}>confide ideas</span>.
            We understand the importance of having a safe environment where users can freely express their thoughts, share their aspirations, and seek guidance without fear of judgment or criticism.
        </p>
        <p>
            These Terms of Use govern users’ (“You”, or “Your”) use of and access to the Confideas (“We”, “Us”, or “Our”) platform and any related services provided by us (referred to collectively as the "Service"). 
            By accessing or using the Service, you confirm that you are at least eighteen (18) years old and legally capable of forming binding contracts under relevant laws. 
            You acknowledge that you have thoroughly read, comprehended, and accepted these Terms of Use, thereby agreeing to abide by them. Should you disagree with these terms, refrain from using our website. 
            The information you provide is governed by our <Link to='/privacy_policy'>Privacy Policy</Link>.
            If you work as an advisor, then <Link to='/advisor_terms'>Advisor Terms and Conditions</Link> also apply. 
        </p>

        <h5 className='sub-title'>Information Collection:</h5>
        <p>
            Users can sign up using just their email address. We do not require users to provide their real name, picture, or contact information, nor do we forward any user information to advisors. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. It is entirely up to the user to decide how much information they want to share with the advisor during the chat session, and indirectly, with the website. 
        </p>

        <h5 className='sub-title'>Advisor Accounts:</h5>
        <p>
            Each advisor is permitted to sign up for only one account and is prohibited from registering multiple accounts on Confideas. Advisors are required to provide their real legal name to Confideas, submit government-issued photo identification, supply tax information, set up a Stripe account, and submit verifying documents upon request. It's important to note that Confideas will not disclose the advisor's information to users.
        </p>

        <h5 className='sub-title'>User Content:</h5>
        <p>
            You retain ownership of any content you post or upload to the Service ("User Content"). By posting User Content, you grant us a worldwide, non-exclusive, royalty-free license to use, modify, reproduce, distribute, and display your User Content for the purpose of providing the Service.
        </p>

        <h5 className='sub-title'>Licenses:</h5>
        <p>
            Confideas enables users to share various types of content through its service, with users bearing responsibility for the legality, reliability, and appropriateness of their posts. Users are granted a limited, revocable right to use the platform for consulting in live chat sessions and receiving feedback from advisors for personal entertainment only. Confideas retains the right to terminate this license at any time. Users are prohibited from using Confideas’s logo or any of its intellectual property for advertising or marketing purposes.
        </p>

        <h5 className='sub-title'>Collection and Use of User-Provided Information:</h5>
        <p>
            User information may be utilized for marketing purposes, and users, while utilizing our platform, consent to its use by Google, Apple, or third parties working on our behalf.
        </p>

        <h5 className='sub-title'>Prohibited Conduct:</h5>
        <p className='mb-2'>You agree not to engage in any of the following prohibited activities:</p>
        <div className='ps-2'>
            <div className='d-flex'>
                <div className='me-2'>a.</div>
                <div>Violation of these Terms of Use or any other policies or guidelines provided by Confideas.</div>
            </div>
            <div className='d-flex'>
                <div className='me-2'>b.</div>
                <div>Failure to comply with legal obligations or requests from law enforcement agencies.</div>
            </div>
            <div className='d-flex'>
                <div className='me-2'>c.</div>
                <div>Posting or transmitting any content (including but not limited to pictures and texts) that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable.</div>
            </div>
            <div className='d-flex'>
                <div className='me-2'>d.</div>
                <div>Any activity that disrupts or interferes with the functioning of the Service or the experience of other users.</div>
            </div>
            <div className='d-flex'>
                <div className='me-2'>e.</div>
                <div>Fraudulent, deceptive, or unauthorized use of the Service.</div>
            </div>
        </div>

        <h5 className='sub-title mt-3'>Termination and Suspension:</h5>
        <p>
            Confideas reserves the right to suspend or terminate user and advisor accounts at our discretion, without prior notice, for any reason addressed above in the Prohibited Conduct section. Upon termination or suspension, users may lose access to their account and any content associated with it. Confideas reserves the right to take appropriate legal action against users who violate these terms or engage in unlawful activities.
        </p>

        <h5 className='sub-title mt-3'>Intellectual Property:</h5>
        <p>
            We retain all rights, title, and interest in and to the Service, including all associated intellectual property rights. You acknowledge that Confideas and its original content, including graphics, scripts, service names, features, and functionality, are the exclusive property of Confideas and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. You agree not to modify, sell, reverse engineer, or use the Service to infringe upon intellectual property rights.
        </p>

        <h5 className='sub-title mt-3'>Disclaimer of Warranties:</h5>
        <p>
            The Service is provided on an "as-is" and "as available" basis, without any warranties of any kind, either express or implied. We make no representations or warranties about the accuracy, reliability, completeness, or timeliness of the Service.
        </p>
        <p>
            Confideas’ liability to you is limited to the amount you paid for the service, not exceeding $50. If no fees are paid, your remedy is limited to injunctive relief only. Any claims must be made within one year. If you're a California resident, you waive California Civil Code Section 1542.
        </p>

        <h5 className='sub-title mt-3'>Limitation of Liability:</h5>
        <p>
            In no event shall we be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, arising out of or in connection with your use of the Service.
        </p>

        <h5 className='sub-title mt-3'>Indemnification:</h5>
        <p>
            You agree to indemnify and hold us harmless from any claims, losses, liabilities, damages, costs, or expenses, including reasonable attorneys' fees, arising out of or in connection with your use of the Service or any violation of these Terms of Use.
        </p>

        <h5 className='sub-title mt-3'>Modification of Terms:</h5>
        <p>
            We reserve the right to modify or revise these Terms of Use at any time. Your continued use of the Service following the posting of any changes to these terms constitutes acceptance of those changes.
        </p>

        <h5 className='sub-title mt-3'>Governing Law:</h5>
        <p>
            These Terms of Use shall be governed by and construed in accordance with the laws of Delaware, United States of America, without regard to its conflict of law principles. We do not assert or guarantee that the Service is authorized or lawful for use outside the United States. If you access or use the Service from a location outside the United States, you do so at your own risk and you are accountable for complying with the laws of the jurisdiction where you reside.
        </p>

        <h5 className='sub-title mt-3'>Class Action Waiver:</h5>
        <p>
            These Terms of Use include a class action waiver and a mandatory arbitration provision. With certain exceptions, any disputes, actions, claims, or causes of action arising from or related to these Terms of Use must be resolved by arbitration on an individual basis and may not be pursued as a class action.
        </p>

        <h5 className='sub-title mt-3'>Entertainment Purposes Disclaimer:</h5>
        <p>
            We strive to provide a platform where users can access guidance and insights from advisors. However, it's important to note that while our advisors are dedicated professionals, we cannot guarantee the accuracy or effectiveness of the insights they provide. Users should exercise their judgment and discretion when considering advice received through our platform. We encourage users to seek multiple perspectives and consult with qualified professionals as needed to make informed decisions.
        </p>

        <h5 className='sub-title mt-3'>Refund Policy:</h5>
        <p>
            At Confideas, we do not offer a refund policy for services rendered. However, we encourage users to terminate the chat session immediately if they do not wish to continue. In cases where users have valid reasons and we determine that a refund is appropriate, we will credit the amount directly into the user's account on our website. Refunds will not be issued back to the original method of payment.
        </p>

        <h5 className='sub-title mt-3'>Contact Us:</h5>
        <p>
            If you have any questions about these Terms of Use, please contact us at contact@confideas.com.
        </p>

        <InfoCenterNavBar />
    </MDBContainer>
});

export default TermsOfuse;
import { MDBContainer, MDBInputGroup, MDBIcon, MDBRow, MDBCol, MDBInput, MDBBtn } from "mdb-react-ui-kit";
import React, {useEffect, useState} from "react";
import Advisor from "../../Components/Advisor/Advisor";
import ApiService from "../../Core/Service/ApiService";
import JwtService from "../../Core/Service/JwtService";
import LoadingIndicator from "../../Components/LoadingIndicator/LoadingIndicator";
import Paginator from "../../Components/Paginator/Paginator";
import Select from "react-select";
import { useSelector } from "react-redux";
import { IsAuthenticated, memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { UserRole } from "../../Constants/constants";
import SearchBar from "../../Components/SearchBar/SearchBar";
import styles from './AllAdvisors.scss'
import { getChangedAdvisors, getChangedAdvisorsHash256 } from "../../Store/Reducer/changedAdvisorReducer";
import SVG from 'react-inlinesvg';

const AllAdvisors = React.memo(({}) => {
    // console.log('calling advisor list....')
    const paginationInfo = JwtService.getPaginationInfo('all_advisors');
    const perPageOpts = [
        { value: 18,label: "18" },
        { value: 24, label: "24" },
        { value: 30, label: "30" }
    ];
    const isAuthenticated = useSelector(IsAuthenticated);
    const authUser = useSelector(memoizedGetAuthUser);
    const [curPerPageOpt, setCurPerPageOpt] = useState(paginationInfo ? perPageOpts.find(opt=>opt.value === paginationInfo.perPage) : perPageOpts[1]);
    const [keyword, setKeyword] = useState(paginationInfo ? paginationInfo.search : '');
    const [searchFormData, setSearchFormData] = useState({
        search: paginationInfo ? paginationInfo.search : '',
        pageNum: paginationInfo ? paginationInfo.pageNum : 1,
        perPage: 18,
        advisorType: 'all'
    });
    const [loadingAdvisors, setLoadingAdvisors] = useState(false);
    const [advisors, setAdvisors] = useState([]);
    const [total, setTotal] = useState(0);
    const [availCoupons, setAvailCoupons] = useState(null);
    const advisorTypeOpts = [
        {value: 'all', label: 'All'},
        {value: 'favorite', label: 'Favorite'}
    ]
    const [curAdvisorType, setCurAdvisorType] = useState(advisorTypeOpts[0]);
    const changedAdvisorHash = useSelector(getChangedAdvisorsHash256);
    const changedAdvisors = useSelector(getChangedAdvisors);

    useEffect(()=>{
        if(changedAdvisorHash) {
            setAdvisors(advisors.filter(a => {
                const found = changedAdvisors.find(ca => ca._id == a._id);
                return found?.listing == false ? false : true;
            }))
        }
    }, [changedAdvisorHash])

    const onPerPageChanged = (opt) => {
        setSearchFormData({
            ...searchFormData,
            perPage: opt.value,
            pageNum: 1
        });
        setCurPerPageOpt(opt);
    }
    const onPageChanged = (pageNum) => {
        setSearchFormData({
            ...searchFormData,
            pageNum
        });
    }
    const onSearchTermChanged = (e) => {
        setKeyword(e.target.value);
    }
    const handleKeyDown = (e) => {
        if(e.key == 'Enter') {
            e.preventDefault();
            setSearchFormData({
                ...searchFormData,
                pageNum: 1,
                search: keyword
            });
        }
    }
    const onSearchBtnClicked = () => {
        setSearchFormData({
            ...searchFormData,
            pageNum: 1,
            search: keyword
        });
    }
    const onAdvisorTypeChanged = (opt) => {
        setSearchFormData({
            ...searchFormData,
            search: '',
            advisorType: opt.value
        })
        setCurAdvisorType(opt);
        setKeyword('');
    }
    const getAdvisors = () => {
        setLoadingAdvisors(true);

        const data = {
            pageNum: searchFormData.pageNum,
            perPage: searchFormData.perPage,
            search: searchFormData.search.trim()
        }

        ApiService.post(`/client/advisor/index`, data).then(result => {
            setAdvisors(result.data.advisors);
            setTotal(result.data.total);

            if(isAuthenticated && authUser.role === UserRole.CLIENT && result.data.total > 0) {
                // To get available coupons
                const aIds = result.data.advisors.map(ad => ad._id);
                ApiService.post('/client/coupon/available/bulk', {aIds}).then(result=>{
                    setAvailCoupons(result.data.coupons);
                })
                .finally(()=>{
                    setLoadingAdvisors(false);
                })
            } else {
                setLoadingAdvisors(false);
            }
        })
        .catch(error => {
            setLoadingAdvisors(false);
        })
    }
    const getFavoriteAdvisors = async () => {
        try {
            setLoadingAdvisors(true);

            let response = await ApiService.get('/client/advisor/favorite');
            setAdvisors(response.data.advisors);
            setTotal(response.data.advisors.length);

            if(isAuthenticated && authUser.role === UserRole.CLIENT && response.data.advisors.length > 0) {
                // To get available coupons
                const aIds = response.data.advisors.map(ad => ad._id);
                response = await ApiService.post('/client/coupon/available/bulk', {aIds})
                setAvailCoupons(response.data.coupons);
            }
        } finally {
            setLoadingAdvisors(false);
        }
    }

    useEffect(()=>{
        if(searchFormData.advisorType == 'all') getAdvisors();
        else getFavoriteAdvisors();

        JwtService.setPaginationInfo('all_advisors', searchFormData);
    }, [searchFormData]);

    useEffect(()=>{
        if(!isAuthenticated) {
            setAvailCoupons(null);
        }
    }, [isAuthenticated]);

    const getAvailCoupon = (aId) => {
        if(isAuthenticated && availCoupons?.length > 0) {
            const availCoupon = availCoupons.find(coupon=>coupon.aId === aId);
            return availCoupon ?? null;
        }
        return null;
    }



    const renderAdvisors = () => {
        if(advisors?.length == 0) {
            return <div className="text-center">
                {loadingAdvisors ? '' : <><SVG src='/img/icons/no_found.svg' style={{height:80}} /><div className="mt-1">No Found Advisors</div></> }
            </div>
        } else {
            return <MDBRow>
                { advisors.map((a, index) => {
                    return <MDBCol className="advisor-container py-2" size={6} xl='2' lg="3" md='3' sm='4' key={index}>
                        <Advisor advisor={a} availCoupon={getAvailCoupon(a._id)} />
                    </MDBCol>
                })}
            </MDBRow>
        }
    }

    return (
        <MDBContainer breakpoint="lg" className="all-advisor-container py-4" id='advisors' style={{position: 'relative'}}>
            <h4 className="ms-2">
                Meet Advisors
            </h4>

            <div className="confideas-table-header">
                {/* <div className="total-count">
                    <label>Total : </label>
                    <span className="ms-2">{total}</span>
                </div> */}

                <SearchBar>
                    {/* <Select className="search-item per-page"
                            options={perPageOpts} 
                            onChange={(opt) => onPerPageChanged(opt)} 
                            defaultValue={curPerPageOpt}
                    /> */}
                    {authUser?.role === UserRole.CLIENT && 
                        <div className="w-150px">
                            <Select className="search-item me-2"
                                options={advisorTypeOpts}
                                onChange={(opt)=>onAdvisorTypeChanged(opt)}
                                defaultValue={curAdvisorType}
                            />
                        </div>
                    }
                    <div className="search-item search-term mx-2 d-flex align-items-center">
                        <MDBInput type="text" 
                                label="Search"
                                value={keyword}
                                onChange={onSearchTermChanged}
                                onKeyDown={handleKeyDown}
                                readOnly={searchFormData.advisorType === 'favorite'}
                        />
                        <span className='mx-2 cursor-pointer' onClick={onSearchBtnClicked}>
                            <MDBIcon fas icon='search' />
                        </span>
                    </div>
                </SearchBar>
            </div>

            <div className="position-relative">
                { renderAdvisors() }
                {loadingAdvisors && <LoadingIndicator />}
            </div>

            {searchFormData.advisorType === 'all' &&
                <div className='d-flex justify-content-center mt-4'>
                    <Paginator curPage={searchFormData.pageNum} total={total} perPage={searchFormData.perPage} onPageChanged={onPageChanged} />
                </div>
            }

        </MDBContainer>
    )
})

export default AllAdvisors;
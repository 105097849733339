import { Outlet } from "react-router";
import { Navigate } from 'react-router-dom';
import { shallowEqual, useSelector } from "react-redux";
import { IsAuthenticated, memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { UserStatus } from "../../Constants/constants";

const useAdvisorAuth = () => {
  const isAuthenticated = useSelector(IsAuthenticated);
  const authUser = useSelector(memoizedGetAuthUser);

  return isAuthenticated && authUser?.role === 'advisor';
}

const AdvisorProtectedRoutes = () => {
  const isAuth = useAdvisorAuth();
  const authUser = useSelector(memoizedGetAuthUser);
  
  if(!isAuth) return <Navigate replace to="/login" />;
  if(authUser.status === UserStatus.EMAIL_VERIFIED) return <Navigate replace to="/advisor_auth/signup-step" />;
  return <Outlet />;
}

export default AdvisorProtectedRoutes


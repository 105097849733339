import React, { useState } from "react";
import styles from './AdminLayout.scss';
import AdminFooter from "./Footer";
import AdminSidebar from "./Sidebar";
import AdminTopBar from "./TopBar";
import { Outlet, useNavigate } from "react-router-dom";
import ApiService from "../../Core/Service/ApiService";
import ChangePasswdModal from "../../Components/Modals/ChangePasswdModal";
import ModalContext from "../../Context/ModalContext";
import { MDBContainer } from "mdb-react-ui-kit";
import AdminContext from "../../Context/AdminContext";


const AdminLayout = React.memo((props) => {
    const navigate = useNavigate();
    ApiService.setNavigate(navigate);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
    const adminContext = {
        sidebarCollapsed, setSidebarCollapsed,
    };

    return <>
        <AdminContext.Provider value={adminContext}>
            <section className={`admin-container1 ${sidebarCollapsed ? 'sidebar-collapsed' : 'sidebar-expanded'}`}>
                <div className="d-flex flex-column h-100" style={{overflow:'auto'}}>
                    <div className="flex-fluid d-flex">
                        <AdminSidebar />
                        <main className="main-content w-100">
                            <AdminTopBar />
                            <section className="p-4">
                                <Outlet/>
                            </section>
                        </main>
                    </div>
                    <AdminFooter />
                </div>
            </section>
        </AdminContext.Provider>
        <ChangePasswdModal />
    </>
});

export default AdminLayout;
import { MDBBtn } from "mdb-react-ui-kit";
import React from "react"
import { useState } from "react";
import ReactHowler from "react-howler";

const Test1 = React.memo((props) => {
    const [playingAudio, setPlayingAudio] = useState(false);

    const handlePlay = () => {
        setPlayingAudio(true);
    }
    const handleStop = () => {
        setPlayingAudio(false);
    }

    return <div>
        {playingAudio && <ReactHowler src='/audio/test.mp3' playing={true} />}
        <MDBBtn onClick={handlePlay} >Play</MDBBtn>
        <MDBBtn onClick={handleStop}>Stop</MDBBtn>
    </div>
});

export default Test1;
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import DateRangeSelector from "../../../Components/DateRangeSelector/DateRangeSelector";
import { formatDate, formatDate_YYYY_MM_DD } from "../../../Utils/utils";
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";

const AdminPaymentChart = React.memo((props) => {
    const [chartOptions, setChartOptions] = useState({
        chart: {
            id: "paid_chart"
          },
          xaxis: {
            categories: []
          }
    });
    const [chartSeries, setChartSeries] = useState([
        {
          name: "Paid Amount",
          data: []
        }
    ]);

    const from = new Date();
    const to = new Date();
    from.setDate(from.getDate() - 6);
    const [toDate, setToDate] = useState(to);
    const [fromDate, setFromDate] = useState(from);

    const [loading, setLoading] = useState(false);
    
    useEffect(()=>{
        setLoading(true);
        const data = {
            fromDate: formatDate(fromDate, 'YYYY-MM-DD'),
            toDate: formatDate_YYYY_MM_DD(toDate, 'YYYY-MM-DD')
        };
        ApiService.get('/admin/transaction/paid_amount_by_period', data).then(response=>{
            setChartOptions({
                ...chartOptions,
                xaxis: {
                    categories: response.data.rows.map(r=>r.date)
                }
            });
            setChartSeries([{
                name: "Paid Amount",
                data: response.data.rows.map(r=>r.amount)
            }]);
        })
        .finally(()=>{
            setLoading(false);
        })
    }, [fromDate, toDate]);

    const onDateRangeSelected = (fromDate, toDate) => {
        setFromDate(fromDate);
        setToDate(toDate);
    }

    return (<div className="client-payment-chart position-relative">
        <div className="d-flex align-items-center">
            <div className="text-center fw-bold flex-fluid fs-150">Client Total Paid</div>
            <div className="daterange-select">
                <DateRangeSelector onDateRangeSelected={onDateRangeSelected} startDate={fromDate} endDate={toDate} />
            </div>
        </div>

        <div className="mt-2">
            <Chart
                options={chartOptions}
                series={chartSeries}
                type="line"
                height="500"
            />
        </div>

        {loading && <LoadingIndicator />}
    </div>)
});

export default AdminPaymentChart;
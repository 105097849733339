import React from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import { useState } from "react";

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  }

const Test2 = (()=>{
    const [imgSrc, setImgSrc] = useState('');
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();
    const [aspect, setAspect] = useState(1);

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () => {
                const s = reader.result?.toString();
                console.log(s);
                setImgSrc(reader.result?.toString() || '');
            })
            reader.readAsDataURL(e.target.files[0])
        }
    }
    const onImageLoad = (e) => {
        if(aspect) {
            const {width, height} = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    }
    const onCompletedCrop = (c) => {
        setCompletedCrop(c);
    }

    return <>
    <div className="crop-controls">
        <input type="file" accept="image/*" onChange={onSelectFile} />
    </div>

    {!!imgSrc && 
        <ReactCrop 
            crop={crop} 
            onChange={c => setCrop(c)}
            onComplete={onCompletedCrop}
        >
            <img src={imgSrc} onLoad={onImageLoad} />
        </ReactCrop>
    }
    </>
});

export default Test2;
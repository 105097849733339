import React, {useEffect, useState} from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import styles from './layout.scss'
import modalStyles from '../../Components/Modals/Modal.scss'
import { useSelector } from 'react-redux';
import { IsAuthenticated } from '../../Store/Reducer/authReducer';
import { IsChatSocketConnected } from '../../Store/Reducer/socketReducer';
import LoadingIndicator from '../../Components/LoadingIndicator/LoadingIndicator';
import CallRing from '../../Components/CallRing';
import { scrollUpTop } from '../../Utils/utils';
import { MDBIcon } from 'mdb-react-ui-kit';

const MainLayout = React.memo((props) => {
    const [loading, setLoading] = useState(true);
    const isAuthenticated = useSelector(IsAuthenticated);
    const isChatSockConnected = useSelector(IsChatSocketConnected);
    const [socketConnecting, setSocketConnecting] = useState(false);
    const [scrollY, setScrollY] = useState(0);
    const [scrollHeight, setScrollHeight] = useState(0);

    useEffect(()=>{
        // To add window.scroll event handler
        const handleScroll = () => {
            setScrollY(window.scrollY);
        }
        window.addEventListener('scroll', handleScroll);

        // To add document.scrollHeight change event handler
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                if (entry.target === document.documentElement) {
                    setScrollHeight(document.documentElement.scrollHeight)
                }
            }
        });
        resizeObserver.observe(document.documentElement);

        setLoading(false);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    useEffect(()=>{
        setSocketConnecting(isAuthenticated && !isChatSockConnected);
    }, [isAuthenticated, isChatSockConnected]);

    const scrollUp = () => {
        window.scroll({
            top: window.scrollY - window.innerHeight,
            behavior: 'smooth'
        })
    }
    const scrollDown = () => {
        window.scroll({
            top: window.scrollY + window.innerHeight,
            behavior: 'smooth'
        })
    }

    const renderScrollUpButton = () => {
        if(window.scrollY > 100) {
            return <section className='goto-top-section'>
                <div className='d-flex align-items-center justify-content-center' onClick={()=>scrollUp()}>
                    <MDBIcon fas icon="arrow-up" size='lg' style={{color: '#367e34'}} />
                </div>
            </section>
        } else {
            return <></>
        }
    }
    const renderScrollDownButton = () => {
        let scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
        let scrolled = window.scrollY;

        if (scrollableHeight - scrolled > 100 ) {
            return <section className='goto-bottom-section'>
                <div className='d-flex align-items-center justify-content-center' onClick={()=>scrollDown()}>
                    <MDBIcon fas icon="arrow-down" size='lg' style={{color: '#367e34'}} />
                </div>
            </section>
        } else {
            return <></>
        }
    }


    return (
        <section className={`${loading ? 'd-none' : ''}`}>
            <Header />

            <section className='main-body-section'>
                <Outlet />
            </section>

            <Footer />

            {renderScrollDownButton()}
            {renderScrollUpButton()}

            {isAuthenticated && <CallRing />}
            {socketConnecting && <LoadingIndicator />}
        </section>
    )
});

export default MainLayout;
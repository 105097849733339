import { MDBContainer } from "mdb-react-ui-kit";
import React, { useEffect } from "react";
import { scrollUpTop } from "../../../Utils/utils";
import InfoCenterNavBar from "../NavBar/NavBar";

const AboutUs = React.memo(props=>{
    useEffect(()=>{
        scrollUpTop();
    }, []);

    return (<MDBContainer breakpoint="lg" className="about-confideas p-4">
        <h3 className="text-center fw-bold" style={{color: '#04549b'}}>About Confideas</h3>
        <p>
            <span className="fw-bold" style={{color: '#004db1'}}>Confideas</span>, deriving its name from <span className="text-purple">“confide ideas”</span>, is a unique marketplace where users can consult with experts and psychics to discuss their personal concerns in a confidential and comfortable environment.<br/>
        </p>
        <p>
            In daily life, you may not always want to discuss yourself, even with family or friends, yet your questions still need answers. 
            Through one-on-one live chats, your private concerns will be met with profound understanding and support. 
            You can discuss anything that comes to mind and receive honest feedback and genuine insights from advisors.
        </p>
        <p>
            Whether you have questions about love, relationships, career, business, your wellbeing, or simply want to chat, you are in the right place! 
            Our chat sessions will provide you with an oasis to temporarily escape the hustle. 
            You will receive long-term support for your spiritual journey and achieve greater personal fulfillment.
        </p>
        <InfoCenterNavBar />
    </MDBContainer>)
});

export default AboutUs;
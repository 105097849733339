import React, {useContext, useEffect, useRef} from "react";
import styles from './ImageSelector.scss';
import { toast } from "react-toastify";
import ModalContext from "../../Context/ModalContext";
import EventContext from "../../Context/EventContext";
import { useState } from "react";

const ImageSelector = React.memo((props) => {
    const photoRef = useRef(null);
    const fileInputRef = useRef(null);
    const modalContext = useContext(ModalContext);
    const eventContext = useContext(EventContext);
    const [image, setImage] = useState();

    useEffect(()=>{
        if(eventContext.evtCompletedCropImage) {
            const cropedImageUrl = eventContext.evtCompletedCropImage.cropedImageUrl;
    
            // Convert the data URL back to binary data
            const byteString = atob(cropedImageUrl.split(',')[1]);
            const arrayBuffer = new ArrayBuffer(byteString.length);
            const uintArray = new Uint8Array(arrayBuffer);
            for (let i = 0; i < byteString.length; i++) {
                uintArray[i] = byteString.charCodeAt(i);
            }
    
            // Create a new File object with the binary data
            const file = new File([arrayBuffer], 'partial_image.jpg', { type: 'image/jpeg' });

            // To create a new Image for cropedImageUrl
            const partialImage = new Image();
            partialImage.onload = function() {
                if(props.onImageSelected && !props.onImageSelected(partialImage, file)) {
                    return;
                }
                photoRef.current.src = cropedImageUrl;
            }
            partialImage.src = cropedImageUrl;

            eventContext.setEvtCompleteCropImage(null);
        }

    }, [eventContext.evtCompletedCropImage])

    const onImageClick = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    }

    const onImageSelected = (e) => {
        const file = e.target.files[0];
        if(!file) return;

        if (!file.type.startsWith('image/')) {
            toast.error("Please select the image file");
            return;
        }

        let reader = new FileReader();
        reader.onload = function() {
            const img = new Image();
            img.onload = function() {
                if(props.cropImage) {
                    modalContext.setModalData({
                        imgSrc: reader.result?.toString() || ''
                    })
                    modalContext.setOpenCropImageModal(true);
                } else {
                    if(props.onImageSelected && !props.onImageSelected(this, file)) {
                        return;
                    }
                    photoRef.current.src = reader.result;
                }
            }
            img.src = reader.result;
            setImage(img);
        }
        reader.readAsDataURL(file);
    }

    return <div className="image-selector">
        {props.readonly && (
        <>
            <img    src={props.defaultSrc} 
                    ref={photoRef} 
            />        
        </>
        )}

        {!props.readonly && (
        <>
            <img    src={props.defaultSrc} 
                    onClick={onImageClick} 
                    ref={photoRef} 
                    style={{cursor: 'pointer'}} 
            />
            <input  type="file" 
                    className="hidden" 
                    ref={fileInputRef} 
                    onChange={onImageSelected}
                    accept=".jpg, .jpeg, .png"
                    style={{ display: 'none' }}  
            />
        </>
        )}

    </div>
})

export default ImageSelector;
import { MDBAccordion, MDBAccordionItem, MDBBadge, MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBContainer, MDBIcon, MDBInput, MDBSpinner, MDBTextArea } from "mdb-react-ui-kit";
import React from "react";
import styles from './Advisor.scss';
import PersonalData from "./Partials/PersonalData";
import { useEffect } from "react";
import ApiService from "../../../Core/Service/ApiService";
import { useState } from "react";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import SkillServiceData from "./Partials/SkillServiceData";
import ApplicantDocument from "./Partials/ApplicantDocument";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UserStatus } from "../../../Constants/constants";
import Select from "react-select";
import { toast } from "react-toastify";
import PayoutMethod from "./Partials/PayoutMethod";

const AdminAdvisorEdit = React.memo((props) => {
    const [loading, setLoading] = useState(false);
    const [advisor, setAdvisor] = useState(null);
    const params = useParams();
    const navigate = useNavigate();
    const aId = params.aId;
    const userStatusChgOpts = [
        { value: UserStatus.APPROVED, label: "Approve" },
        { value: UserStatus.SUSPENDED, label: "Suspend"}
    ];
    const [curUserStatusOpt, setUserStatuOpt] = useState(null);
    const [updatingUserStatus, setUpdatingUserStatus] = useState(false);

    useEffect(()=>{
        setLoading(true);

        const url = `/admin/advisor/${aId}`;
        ApiService.get(url).then(result=>{
            setAdvisor(result.data.advisor);
            setUserStatuOpt(userStatusChgOpts.find(opt=>opt.value === result.data.advisor.user_status));
        })
        .finally(()=>{
            setLoading(false);
        })
    }, []);

    const goBack = (e) => {
        e.preventDefault();
        navigate(-1);
    }

    const handleInputChange = (e) => {
        setAdvisor({
            ...advisor,
            [e.target.name]: e.target.value
        })
    }

    const onUserStatusChg = (opt) => {
        setUserStatuOpt(opt);
        setAdvisor({
            ...advisor,
            user_status: opt.value
        })
    }

    const handleUpdate = (e) => {
        e.preventDefault();
        if(window.confirm("Are you sure to save?") == false) return;

        setUpdatingUserStatus(true);

        const data = {
            user: {...advisor}
        }
        delete data.user['mirror'];
        delete data.user['clients'];

        ApiService.post('/admin/user/update', data).then(result => {
            navigate('/admin/advisor/list');
        })
        .finally(()=>{
            setUpdatingUserStatus(false);
        })
    }

    const breadcrumb = [];
    breadcrumb.push({title: 'Advisor', url:'/admin/advisor/list'});
    breadcrumb.push({title: 'Show'});

    return <MDBContainer breakpoint="lg" className="admin-advisor-show-container">
        <Breadcrumb breadcrumb={breadcrumb} className='mb-4' />
        <MDBCard>
            <MDBCardHeader>
                <MDBIcon fas icon="user-circle" size="lg" className="me-1" />
                <span className="accordion-item-title">Personal Information</span>
            </MDBCardHeader>
            <MDBCardBody>
                { advisor && <PersonalData advisor={advisor} /> }
            </MDBCardBody>
        </MDBCard>

        <MDBCard className="mt-4">
            <MDBCardHeader>
                <MDBIcon fas icon="atom" size='lg' className="me-1" />
                <span className="accordion-item-title">Services</span>
            </MDBCardHeader>
            <MDBCardBody>
                { advisor && <SkillServiceData advisor={advisor} /> }
            </MDBCardBody>
        </MDBCard>

        <MDBCard className="mt-4">
            <MDBCardHeader>
                <MDBIcon fas icon="atom" size='lg' className="me-1" />
                <span className="accordion-item-title">Payout Methods</span>
            </MDBCardHeader>
            <MDBCardBody>
                { advisor && <PayoutMethod advisor={advisor} setAdvisor={setAdvisor} /> }
            </MDBCardBody>
        </MDBCard>

        <MDBCard className="mt-4">
            <MDBCardHeader>
                <MDBIcon far icon="id-card" className="me-1" />
                <span className="accordion-item-title">Application Documents</span>
            </MDBCardHeader>
            <MDBCardBody>
                { advisor && <ApplicantDocument advisor={advisor} /> }
            </MDBCardBody>
        </MDBCard>

        {advisor?.video && (
            <MDBCard className="mt-4">
                <MDBCardHeader>
                    <MDBIcon fas icon="video" className="me-1" />
                    <span className="accordion-item-title">Introduction Video</span>
                </MDBCardHeader>
                <MDBCardBody className="d-flex justify-content-center p-4">
                    <video 
                        src={getBackendAssetUrl(advisor.video)}
                        style={{width: 400, height: 300}}
                        controls
                    />
                </MDBCardBody>
            </MDBCard>
        )}

        <MDBCard className="mt-4">
            <MDBCardHeader>
                <MDBIcon fas icon="user-circle" size="lg" className="me-1" />
                <span className="accordion-item-title">Some Other Info</span>
            </MDBCardHeader>
            <MDBCardBody className="d-flex">
                <div style={{width:150}}>
                    <label>Commission</label>
                    <MDBInput type='number' 
                        name='commission'
                        value={advisor?.commission} 
                        onChange={handleInputChange}
                        style={{textAlign:'center'}}
                    />
                </div>
            </MDBCardBody>
        </MDBCard>

        <div className="mt-4 user-memo">
            <div className="px-2">Memo : </div>
            <div className="flex-fluid">
                <MDBTextArea label=""
                            name='memo'
                            value={advisor?.memo}
                            rows={4}
                            style={{backgroundColor: 'white'}}
                            onChange={e=>handleInputChange(e)}
                />
                <div className="notify">
                    ( This message won't be showed to the advisor, instead it is only for admin's reference.) 
                </div>
            </div>
        </div>

        <div className="user-status mt-4 w-100">
            <div className="d-flex align-items-center">
                <div className="me-1">Status : </div>
                { (!curUserStatusOpt) &&
                    <div>{ advisor?.user_status }</div>
                }
                { curUserStatusOpt && <div>
                    <Select options={userStatusChgOpts} onChange={opt=>onUserStatusChg(opt)} defaultValue={curUserStatusOpt} />
                    </div>
                }
            </div>
        </div>

        <div className="d-flex justify-content-end mt-4">
            <MDBBtn onClick={handleUpdate} className="mx-2">
                {updatingUserStatus && <>
                    <MDBSpinner size='sm' role='status' tag='span' className='me-2' />
                    Saving
                </>}
                {!updatingUserStatus && <span>Save</span>}
            </MDBBtn>
            <MDBBtn onClick={goBack} color="second">Back</MDBBtn>
        </div>
        {loading && <LoadingIndicator />}
    </MDBContainer>
});

export default AdminAdvisorEdit;
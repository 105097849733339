import React, {useState, useEffect, useContext, useMemo} from "react";
import { 
    MDBModal,
    MDBModalDialog,
    MDBModalBody,
    MDBModalContent,
    MDBBtn,
    MDBModalHeader,
    MDBModalTitle,
    MDBSpinner,
    MDBModalFooter,
    MDBIcon,
} from "mdb-react-ui-kit";
import ModalContext from "../../Context/ModalContext";
import { memoizedGetAuthUser, purgeAuth, updateAuth } from "../../Store/Reducer/authReducer";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "../../Core/Service/ApiService";
import { toast } from "react-toastify";

const DeclineAdvisorModal = React.memo((props)=>{
    const modalContext = useContext(ModalContext);
    const open = modalContext.openDeclineAdvisorModal;
    const setOpen = modalContext.setOpenDeclineAdvisorModal;
    const [action, setAction] = useState('');
    const dispatch = useDispatch();
    
    const handleBecomeClient = (become) => {
        if(!become) {
            if(window.confirm("Your email will no longer be available.\nAre you sure to continue?") == false) return;
        }

        setAction(become ? 'become' : 'no_become');
        ApiService.post('/advisor/becomeclient/', {become}).then(response => {
            dispatch(purgeAuth());
            setOpen(false);

            if(become) {
                toast.success("You can use this account as a client.\nPlease login again.");
                setTimeout(()=>{
                    modalContext.setOpenSignInModal(true);
                }, 1000);
            } else {
            }
        })
        .finally(()=>{
            setAction('');
        })
    }

    if(open) {
        return (
            <MDBModal
                className="decline-advisor-modal"
                open={open}
                setOpen={setOpen} 
                closeOnEsc={false}
                staticBackdrop 
                tabIndex='-1'
            >
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle className="w-100">
                                <MDBIcon fas icon="exclamation-circle" color="info" className="me-2" />
                                Notification
                            </MDBModalTitle>
                        </MDBModalHeader>
                        <MDBModalBody className='p-4 flex-column'>
                            <p>
                                Thank you very much for your interest in our advisor opening. 
                                Due to our current client base and specific needs, we regret to inform you that you were not selected for the advisor role this time.
                                However, you are always welcome to explore new advisory categories on our website periodically and apply for other advisory positions.
                            </p>
                            <p className="text-center fw-bold">
                                You are going to be a client?
                            </p>
                            <p className="font-italic">
                                Your current email will no longer be able to log into our website if you select "No."
                            </p>
                        </MDBModalBody>
                        <MDBModalFooter>
                        <div className="w-100 d-flex justify-content-center p-2">
                                <MDBBtn className="w-50 me-2" onClick={e=>handleBecomeClient(false)} color="default">
                                    {action === 'no_become' && <MDBSpinner size="sm" />}
                                    No
                                </MDBBtn>
                                <MDBBtn className="w-50 confideas-primary-btn" onClick={e=>handleBecomeClient(true)}>
                                    {action === 'become' && <MDBSpinner size="sm" />}
                                    Yes
                                </MDBBtn>
                            </div>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        )
    } else {
        return <></>
    }
});

export default DeclineAdvisorModal;
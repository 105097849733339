import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle } from "mdb-react-ui-kit";
import React, { useContext } from "react";
import AdvisorClientKeyNote from "../../Pages/Advisor/ClientDetail/ClientKeyNote";
import AdvisorClientChatHistory from "../../Pages/Advisor/ClientDetail/ClientChatHistory";
import '../../Pages/Advisor/ClientDetail/ClientDetail.scss';
import ModalContext from "../../Context/ModalContext";

const ClientDetailModal = React.memo((props) => {
    const modalContext = useContext(ModalContext);
    const modalData = modalContext.modalData;
    const open = modalContext.openClientDetailModal;
    const setOpen = modalContext.setOpenClientDetailModal;

    const closeModal = () => {
        setOpen(false);
    }

    if(open) {
        return (
            <MDBModal
                className="advisor-client-detail"
                open={open}
                setOpen={setOpen}
                closeOnEsc={false}
                staticBackdrop 
                tabIndex={-1}
            >
                <MDBModalDialog scrollable style={{maxHeight: '80vh'}}>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Quick Glance</MDBModalTitle>
                            <MDBBtn tabIndex='-1' className='btn-close' color='none' onClick={closeModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody className="">
                            <AdvisorClientKeyNote cId={modalData.cId} modalMode={true} />
                            <AdvisorClientChatHistory cId={modalData.cId} modalMode={true} />
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        )
    } else {
        return <></>
    }
});

export default ClientDetailModal;


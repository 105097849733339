import React, {useState, useEffect, useContext, useMemo} from "react";
import { 
    MDBModal,
    MDBModalDialog,
    MDBModalBody,
    MDBModalContent,
    MDBBtn,
    MDBModalHeader,
    MDBModalTitle,
    MDBSpinner,
} from "mdb-react-ui-kit";
import ModalContext from "../../Context/ModalContext";
import { memoizedGetAuthUser, updateAuth } from "../../Store/Reducer/authReducer";
import { useSelector } from "react-redux";
import { getAvatarUrl } from "../../Utils/utils";
import { IsChatSocketConnected, getChatSocket } from "../../Store/Reducer/socketReducer";
import { SendMessages, UserRole } from "../../Constants/constants";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import ApiService from "../../Core/Service/ApiService";
import { IsChatCreated } from "../../Store/Reducer/chatReducer";
import { toast } from "react-toastify";

const EnoughBalanceModal = React.memo((props) => {
    const authUser = useSelector(memoizedGetAuthUser);
    const modalContext = useContext(ModalContext);
    const open = modalContext.openEnoughBalanceModal;
    const setOpen = modalContext.setOpenEnoughBalanceModal;
    const modalData = modalContext.modalData;
    const [availTalkTime, setAvailTalkTime] = useState(0);
    const chatSocket = useSelector(getChatSocket);
    const isChatSockConnected = useSelector(IsChatSocketConnected);
    const [availCoupon, setAvailCoupon] = useState(null);
    const [checkingAvailCoupon, setCheckingAvailCoupon] = useState(false);
    const [requestingChat, setRequestingChat] = useState(false);
    const isChatCreated = useSelector(IsChatCreated);

    useEffect(()=>{
        if(open && authUser && authUser.role === UserRole.CLIENT && modalData) {
            setAvailTalkTime(parseInt(authUser.balance / modalData.advisor.rate_per_min_chat));

            // To get available coupon
            setCheckingAvailCoupon(true);
            ApiService.get('/client/coupon/available/' + modalData.advisor._id).then(result=>{
                setAvailCoupon(result.data.coupon);
            })
            .finally(()=>{
                setCheckingAvailCoupon(false);
            })
        }
    }, [authUser, modalData, open]);

    useEffect(()=>{
        if(isChatCreated) {
            setRequestingChat(false);
            setOpen(false);
        }
    }, [isChatCreated]);

    const closeModal = () => {
        setRequestingChat(false);
        setOpen(false);
    }

    const onContinueBtnClicked = () => {
        if(isChatSockConnected) {
            chatSocket.emit(SendMessages.REQUEST_CHAT, {aId: modalData.advisor._id});
            setRequestingChat(true);
        } else {
            toast.warn("Your network is issue. Please try again later.")
        }
    }

    return (
        <MDBModal   className="pay-amount-modal" 
                    open={open} 
                    setOpen={setOpen} 
                    closeOnEsc={false}
                    staticBackdrop 
                    tabIndex='-1' >
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader>
                        <div className="text-center w-100">
                            Your current balance:
                            <span className="current-balance ms-1">${authUser?.balance}</span>
                        </div>
                        <MDBBtn tabIndex='-1' className='btn-close' color='none' onClick={closeModal}></MDBBtn>
                    </MDBModalHeader>
    
                    <MDBModalBody className='d-flex p-4 flex-column justify-content-center align-items-center'>
                        <div className="w-100 mt-1">
                            <div className="advisor-info-card d-flex justify-content-center align-items-center">
                                <div>
                                    <img src={getAvatarUrl(modalData?.advisor?.avatar)} className="advisor-avatar"/>
                                </div>
                                <div className="main-info">
                                    <div className="username">{modalData?.advisor?.username}</div>
                                    <div className="service-name">{modalData?.advisor?.service_name}</div>
                                    <div className="rate-per-min-chat">${modalData?.advisor?.rate_per_min_chat} / min</div>
                                </div>
                            </div>
    
                            <div className="mt-4 text-center d-flex justify-content-center">
                                <div>
                                    You can have
                                </div>
                                <div className="ms-1">
                                    {availCoupon && <div>
                                    a free talk of <span style={{color:'#23ad1f', fontWeight: 'bold'}}>{availCoupon.availMinutes}</span> minutes,
                                    </div>}
                                    <div>a paid talk of <span style={{color:'#23ad1f', fontWeight: 'bold'}}>{availTalkTime}</span> minutes</div>
                                </div>
                            </div>
    
                            <div className="w-100 d-flex justify-content-center mt-4">
                                <MDBBtn className="w-50 me-2" onClick={closeModal} color="default">Cancel</MDBBtn>
                                <MDBBtn className="w-60 confideas-primary-btn" onClick={onContinueBtnClicked} disabled={requestingChat} >
                                    {requestingChat && <div className="d-flex align-items-center">
                                        <MDBSpinner size="sm" />
                                        <span className="ms-2">Connecting</span>
                                    </div>}
                                    {!requestingChat && <span>Continue</span>}
                                </MDBBtn>
                            </div>
                        </div>
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
    
            {checkingAvailCoupon && <LoadingIndicator />}
        </MDBModal>
    )
});

export default EnoughBalanceModal;
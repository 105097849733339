import React from "react";
import styles from './ChatView.scss';
import { shallowEqual, useSelector } from "react-redux";
import { IsAuthenticated, memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { useEffect } from "react";
import ApiService from "../../Core/Service/ApiService";
import { useState } from "react";
import { MDBContainer } from "mdb-react-ui-kit";
import ChatMetaInfo from "./ChatMetaInfo";
import { useParams } from "react-router-dom";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import Messages from "./Messages";
import ChatMetaInfoSm from "./ChatMetaInfoSm";
import { UserRole } from "../../Constants/constants";
import ChatMetaInfoAdmin from "./ChatMetaInfoAdmin";

const ChatView = React.memo((props) => {
    const params = useParams();
    const chatId = params._id;
    const isAuthenticated = useSelector(IsAuthenticated)
    const authUser = useSelector(memoizedGetAuthUser);
    const [loading, setLoading] = useState(false);
    const [chat, setChat] = useState(null);
    
    useEffect(()=>{
        if(isAuthenticated) {
            setLoading(true);

            ApiService.get('/chat/' + chatId).then(result => {
                setChat(result.data);
            })
            .catch(error=>{

            })
            .finally(()=>{
                setLoading(false);
            });
        }
    }, [isAuthenticated]);

    return <>
        <MDBContainer className="chat-history-container d-flex" breakpoint="lg">
            {chat && <>
                {authUser?.role != UserRole.ADMIN && <ChatMetaInfoSm chat={chat.chat} /> }
                <Messages chat={chat.chat} messages={chat.messages} />
                {authUser?.role != UserRole.ADMIN && <ChatMetaInfo chat={chat.chat} /> }
                {authUser?.role === UserRole.ADMIN && <ChatMetaInfoAdmin chat={chat.chat} /> }
            </>}
            {loading && <LoadingIndicator />}
        </MDBContainer>
    </>
});

export default ChatView;
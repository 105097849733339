import React, {useEffect, useState} from "react";
import { useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import './ChatOffThread.scss';
import { getChatOffThreads } from '../../Store/Reducer/chatoffMsgReducer';
import { memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { BREAKPOINT_WIDTH, LocalStorageKey, UserRole } from "../../Constants/constants";
import { compareDates, getAvatarUrl, isMobileScreen, text2html } from "../../Utils/utils";
import Moment from "react-moment";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useContext } from "react";
import ModalContext from "../../Context/ModalContext";
import { useNavigate } from "react-router-dom";
import JwtService from "../../Core/Service/JwtService";

const ChatOffThreads = React.memo((props) => {
    const threads = useSelector(getChatOffThreads);
    const authUser = useSelector(memoizedGetAuthUser);
    const modalContext = useContext(ModalContext);
    const navigate = useNavigate();

    const gotoThread = (thread) => {
        if(!isMobileScreen()) {
            modalContext.setModalData({
                thread,
                partner: authUser.role == UserRole.CLIENT ? thread.aId : thread.cId
            });
            modalContext.setOpenChatOffMessageModal(true);
        } else {
            JwtService.setChatOffThread(thread);
            JwtService.setChatOffPartner(authUser.role == UserRole.CLIENT ? thread.aId : thread.cId);
            navigate('/chatoff_message');
        }
    }

    const renderThreads = () => {
        if(authUser && threads?.length > 0) {
            // To sort threads by last_message_time
            const sorted_threads = [...threads].sort((thread1, thread2) => {
                return compareDates(new Date(thread2.last_msg_time), new Date(thread1.last_msg_time));
            });

            return sorted_threads.map((thread, index) => {
                const partner = authUser.role === UserRole.CLIENT ? thread.aId : thread.cId;

                let last_message = '';
                if(thread.last_message) {
                    if(thread.last_message.sender == authUser._id) last_message += 'You : ';
                    last_message += text2html(thread.last_message.message);
                }

                return (
                    <div className="one-thread d-flex" onClick={e=>gotoThread(thread)} key={index}>
                        <div className="flex-fluid">
                            <div className="d-flex align-items-center">
                                <img src={getAvatarUrl(partner?.avatar)} className="round-avatar-40" /> 
                                <div className="ms-2" style={{width: 'calc(100% - 50px)'}}>
                                    <div className="partner-name">{partner?.username}</div>
                                    <div className="last-message"
                                        dangerouslySetInnerHTML={{ __html: last_message}} 
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center" style={{width: 60}}>
                            <div>
                                <Moment format="MM-DD">{thread.last_message?.createdAt}</Moment>
                            </div>
                            <div>
                                {thread.unread_msg_count > 0 && (
                                    <div className='unread-msg-count d-flex justify-content-center align-items-center'>{thread.unread_msg_count}</div>
                                )}
                            </div>
                        </div>
                    </div>
                )
            })
        } else {
            return <div className="d-flex justify-content-center align-items-center">
                <SVG src='/img/icons/no_found.svg' style={{height:40, width: 40}} /><div className="ms-2 mt-1">No Messages</div>
            </div>
        }
    }

    return (
        <div className='chatoff-threads w-100 p-2'>
            <div className='fw-bold'>Messages</div>
            <div className="mt-2">
                { renderThreads() }
            </div>
        </div>
    )
});

export default ChatOffThreads;
const hooks = [];
let timer = null;

const hookTimer = (title, interval, callback) => {
    const hook = hooks.find(h => h.title === title);
    if(hook) {
        hook.callback = callback;
        hook.calltime = 0;
    } else {
        hooks.push({
            title,
            callback,
            interval,
            calltime: 0
        })
    }
}

const resetTimer = (title) => {
    const hook = hooks.find(h=>h.title === title);
    if(hook) {
        hook.calltime = 0;
    }
}

const unHookTimer = (title) => {
    const index = hooks.findIndex(h=>h.title === title);
    if(index >= 0) hooks.splice(index, 1);
}

if(!timer) {
    timer = setInterval(() => {
        const now = new Date().getTime();
        for(let i=0; i<hooks.length; i++) {
            const hook = hooks[i];
            if(now - hook.calltime >= hook.interval) {
                hook.callback();
                hook.calltime = now;
            }
        }
    }, 200);
}

module.exports = {
    hookTimer,
    resetTimer,
    unHookTimer   
}
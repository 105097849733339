import React, { useEffect, useState } from "react";
import './ChatDetail.scss';
import { MDBBtn, MDBCard, MDBCardBody, MDBContainer } from "mdb-react-ui-kit";
import ChatMetaInfo from "./ChatMetaInfo";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import ApiService from "../../Core/Service/ApiService";
import { useSelector } from "react-redux";
import { memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { useNavigate, useParams } from "react-router-dom";

const ChatDetail = React.memo((props) => {
    const params = useParams();
    const chatId = params._id;
    const [loading, setLoading] = useState(false);
    const [chat, setChat] = useState(null);
    const authUser = useSelector(memoizedGetAuthUser);
    const navigate = useNavigate();
    
    useEffect(()=>{
        if(authUser) {
            setLoading(true);

            ApiService.get('/chat/' + chatId).then(result => {
                setChat(result.data);
            })
            .finally(()=>{
                setLoading(false);
            });
        }
    }, [authUser]);

    const goBack = () => {
        navigate(-1);
    }

    return (
        <MDBContainer breakpoint="lg" className="chat-detail-container d-flex justify-content-center p-4">
                {chat && (<>
                    <div className="d-none d-md-block w-100" style={{maxWidth: 700}}>
                        <MDBCard>
                            <MDBCardBody className="p-4">
                                <ChatMetaInfo chat={chat.chat} />
                            </MDBCardBody>
                        </MDBCard>
                        <div className="d-flex justify-content-end mt-4">
                            <MDBBtn color="default" onClick={goBack}>Back</MDBBtn>
                        </div>
                    </div>

                    <div className="d-block d-md-none w-100">
                        <ChatMetaInfo chat={chat.chat} />
                    </div>
                </>)}
            {loading && <LoadingIndicator />}
        </MDBContainer>
    )
});

export default ChatDetail;
import React from "react";
import styles from './MyPage.scss';
import MainInfo from "./MainInfo";
import { MDBContainer } from "mdb-react-ui-kit";

const ClientMyPage = (props) => {
    return <MDBContainer breakpoint="lg" className="client-mypage p-4">
        <MainInfo />
    </MDBContainer>
}

export default ClientMyPage;
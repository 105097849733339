import React, { useState } from "react";
import { useContext } from "react";
import ModalContext from "../../Context/ModalContext";
import { MDBBtn, MDBCheckbox, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from "mdb-react-ui-kit";
import JwtService from "../../Core/Service/JwtService";

const AllowNotifyModal = React.memo((props)=>{
    const modalContext = useContext(ModalContext);
    const open = modalContext.openAllowNotifyModal && !JwtService.getNoShowPermitNotification();
    const setOpen = modalContext.setOpenAllowNotifyModal;
    const [noShowChecked, setNoShowChecked] = useState(false);
    
    const closeModal = (e) => {
        e.preventDefault();
        setOpen(false);
    }
    const onOk = (e) => {
        e.preventDefault();
        JwtService.setNoShowPermitNotification(noShowChecked);
        setOpen(false);
    }

    return (
        <MDBModal className="allow-notify-modal" open={open} setOpen={setOpen} closeOnEsc={false} staticBackdrop tabIndex='-1'>
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>
                            Permit Notification.
                        </MDBModalTitle>
                        <MDBBtn tabIndex='-1' className='btn-close' color='none' onClick={closeModal}></MDBBtn>
                    </MDBModalHeader>

                    <MDBModalBody className="p-4">
                        <h6 className="mt-3">
                            <MDBIcon fas icon="info-circle" color="danger" size="lg" className="me-2"/>
                            Your browser has blocked notification from our site.
                        </h6>

                        <div className="p-2">
                            <p className="">
                                If you permit notification from our site, you can be informed whenever you have got unread message.
                            </p>
                            <p>
                                If you want to permit notification, please click 'site information button' left to url.
                            </p>
                            <img src='/img/others/site_info_btn.png' />
                        </div>

                    </MDBModalBody>

                    <MDBModalFooter>
                        <div className="w-100 action p-2 d-flex">
                            <div>
                                <MDBCheckbox label='Never show again'
                                            checked={noShowChecked}
                                            onChange={() => setNoShowChecked(!noShowChecked)}
                                />
                            </div>
                            <div className="flex-fluid d-flex justify-content-end">
                                <MDBBtn onClick={onOk}>OK</MDBBtn>
                            </div>
                        </div>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
});

export default AllowNotifyModal;
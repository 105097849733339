import React from "react";
import styles from './ScrollHorizon.scss';
import { MDBIcon } from "mdb-react-ui-kit";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

const ScrollHorizon = React.memo((props) => {
    const containerRef = useRef(null);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleScroll = () => {
        if(containerRef.current) {
            setScrollLeft(containerRef.current.scrollLeft);
        }
    }
    const scrollToRight = () => {
        if(containerRef.current) {
            containerRef.current.scroll({
                left: containerRef.current.scrollLeft + (props.step ?? window.visualViewport.width),
                behavior: 'smooth'
            })
        }
    }
    const scrollToLeft = () => {
        if(containerRef.current) {
            containerRef.current.scroll({
                left: containerRef.current.scrollLeft - (props.step ?? window.visualViewport.width),
                behavior: 'smooth'
            })
        }
    }

    return <div ref={containerRef} className={`scroll-horizon-container ${props.className}`} onScroll={handleScroll}>
        {props.children}
        
        {scrollLeft > 0 &&
            <div className="scroll-left-btn" style={{left: scrollLeft}}>
                <MDBIcon far icon="arrow-alt-circle-left" onClick={scrollToLeft} size="2x" color="black-50" />
            </div>
        }

        {scrollLeft + containerRef.current?.clientWidth < containerRef.current?.scrollWidth &&
            <div className="scroll-right-btn" style={{right: - scrollLeft}}>
                <MDBIcon far icon="arrow-alt-circle-right" onClick={scrollToRight} size="2x" color="black-50"/>
            </div>
        }
    </div>
});

export default ScrollHorizon;
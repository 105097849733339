import React, {useState, useEffect, useContext, useMemo} from "react";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { addDate, validateForm } from '../../Utils/utils';
import ApiService from "../../Core/Service/ApiService";
import { 
    MDBModal,
    MDBModalDialog,
    MDBModalBody,
    MDBModalContent,
    MDBInput,
    MDBBtn,
    MDBModalHeader,
    MDBModalTitle,
    MDBSpinner,
    MDBRange,
    MDBIcon,
} from "mdb-react-ui-kit";
import ModalContext from "../../Context/ModalContext";
import TextAreaAutoHeight from "../TextAreaAutoHeight/TextAreaAutoHeight";
import { memoizedGetAuthUser, updateAuth } from "../../Store/Reducer/authReducer";

const SendOpinionModal = React.memo((props) => {
    const dispatch = useDispatch();
    const authUser = useSelector(memoizedGetAuthUser);
    const modalContext = useContext(ModalContext);
    const open = modalContext.openSendOpinionModal;
    const setOpen = modalContext.setOpenSendOpinionModal;
    const [sending, setSending] = useState(false);
    const [inputErrors, setInputErrors] = useState({});
    const [formData, setFormData] = useState({
        content: ''
    });
    const rules = {
        content: {
            required: 'string',
            'label': 'Message',
        }
    };

    useEffect(()=>{
        if(open) {
            setFormData({
                content: ''
            })
            setInputErrors({});
        }
    }, [open])

    const closeModal = () => {
        setOpen(false);
    }

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const sendOpinion = (e) => {
        e.preventDefault();
        const valid_ret = validateForm(formData, rules);
        if(valid_ret.result) {
            setInputErrors({});
            setSending(true);
            
            ApiService.post('/opinion/create', formData).then(result => {
                toast.success("The message has been sent successfully");
                closeModal();
            })
            .finally(()=>{
                setSending(false);
            });
        } else {
            setInputErrors(valid_ret.errors);
        }
    }

    return (
    <MDBModal   className="opinion-modal" 
                open={open} 
                setOpen={setOpen} 
                closeOnEsc={false}
                staticBackdrop 
                tabIndex='-1' >
        <MDBModalDialog>
            <MDBModalContent>
                <MDBModalHeader className="d-flex w-100">
                    <MDBModalTitle className="flex-fluid d-flex justify-content-center align-items-center">Write to Customer Support</MDBModalTitle>
                    <MDBBtn tabIndex='-1' className='btn-close' color='none' onClick={closeModal}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody className='d-flex p-4 pt-2 flex-column justify-content-center align-items-center'>
                    <div className="message mt-3 w-100">
                        <label className="d-flex align-items-center mb-1">
                            <MDBIcon fas icon='envelope' size="lg" className="me-1" color="warning" />
                            Message: 
                        </label>
                        <div className="value">
                            <TextAreaAutoHeight 
                                    className='w-100 p-2'
                                    name='content'
                                    value={formData.content}
                                    onChange={handleInputChange}
                                    tabIndex={1}
                                    style={{minBoxHeight: 200}}
                            />
                            {inputErrors.content && <div className="error">{ inputErrors.content }</div>}
                        </div>
                    </div>
                    <div className="action d-flex justify-content-end align-items-center mt-4 ">
                        <MDBBtn className="me-2" color="second" onClick={closeModal} disabled={sending}>Cancel</MDBBtn>
                        <MDBBtn className="confideas-primary-btn" onClick={sendOpinion} disabled={sending}>
                            {sending && <>
                                <MDBSpinner size='sm' role='status' tag='span' className='me-2' />
                                Sending
                            </>}
                            {!sending && <span>Send</span>}
                        </MDBBtn>
                    </div>
                </MDBModalBody>
            </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
    )
});

export default SendOpinionModal;
import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import React, { useRef } from 'react';
import './VideoSelector.scss';

const VideoSelector = React.memo((props) => {
  const videoRef = useRef(null);
  const videoInputRef = useRef(null);

  const handleVideoSelected = (e) => {
    const file = e.target.files[0];
    if(!file) return;
    
    const url = URL.createObjectURL(file);
    videoRef.current.src = url;

    props.onVideoSelected && props.onVideoSelected(file);
  }
  const onSelectBtnClicked = (e) => {
    videoInputRef.current?.click();
  }

  return (
    <div className='w-100 d-flex flex-column align-items-center'>
        <input 
            type="file" 
            className="hidden" 
            accept="video/*" 
            ref={videoInputRef} 
            onChange={handleVideoSelected} 
        />

        <video 
            ref={videoRef} 
            controls 
            className='w-90 cursor-pointer' 
            style={{maxWidth:625, height: 300}}
            onClick={onSelectBtnClicked}
            src={props.defaultSrc}
        />

        <MDBBtn 
            className='w-100 mt-2 d-flex align-items-center justify-content-center'
            tag='a' 
            color='none' 
            onClick={onSelectBtnClicked}
        >
            <MDBIcon fas icon="video" className='me-2' />
            Select Video
        </MDBBtn>
    </div>
  );
});

export default VideoSelector;
import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle, MDBSpinner, MDBTextArea } from "mdb-react-ui-kit";
import React from "react";
import { getAvatarUrl } from "../../../Utils/utils";
import Moment from "react-moment";
import { ReviewType } from "../../../Constants/constants";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import ApiService from "../../../Core/Service/ApiService";

const AdminEditReview = React.memo((props) => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [formData, setFormData] = useState(null);
    
    useEffect(()=>{
        setLoading(true);

        ApiService.get('/admin/review/' + params._id).then(result=>{
            setFormData(result.data.review);
        })
        .finally(()=>{
            setLoading(false);
        })
    }, [params._id]);

    const onReviewContentChanged = (e) => {
        setFormData({
            ...formData,
            content: e.target.value
        })
    }

    const handleSave = (e) => {
        e.preventDefault();
        setSaving(true);

        const data = {
            _id: params._id,
            reviewContent: formData.content
        }
        ApiService.post('/admin/review/update', data).then(result=>{
            navigate(-1);
        })
        .finally(()=>{
            setSaving(false);
        })
    }

    const breadcrumb=[{title: 'Review'}, {title: 'Edit'}];
    return (<>
        <Breadcrumb breadcrumb={breadcrumb} />
        <div className="d-flex justify-content-center p-4">
            <div style={{width: 600}} className="admin-edit-review p-4">
                <div className="d-flex align-items-center">
                    <label>Date</label>
                    <span className="mx-2">:</span>
                    <Moment format="YYYY-MM-DD">{formData?.createdAt}</Moment>
                </div>
                <div className="d-flex align-items-center mt-4">
                    <label>Client</label>
                    <span className="mx-2">:</span>
                    <img src={getAvatarUrl(formData?.cId.avatar)} className="round-avatar-40" />
                    <span className="ms-2">{formData?.cId.username}</span>
                </div>
                <div className="d-flex align-items-center mt-4">
                    <label>Advisor</label>
                    <span className="mx-2">:</span>
                    <img src={getAvatarUrl(formData?.aId.avatar)} className="round-avatar-40" />
                    <span className="ms-2">{formData?.aId.username}</span>
                </div>
                <div className="d-flex align-items-center mt-4">
                    <label>Type</label>
                    <span className="mx-2">:</span>
                    <span className={`review-type ${formData?.type}`}>{formData?.type == ReviewType.HAPPY ? 'Happy' : 'UnHappy'}</span>
                </div>
                <div className="mt-4">
                    <MDBTextArea name='content'
                        className='w-100 p-2'
                        value={formData?.content}
                        onChange={onReviewContentChanged}
                    />
                </div>
                <div className="d-flex justify-content-end mt-4">
                    <MDBBtn onClick={handleSave} disabled={saving}>
                        {saving && <>
                            <MDBSpinner role='status' size="sm" />
                            <span>Saving</span>
                        </>}
                        {!saving && <span>Save</span>}
                    </MDBBtn>
                    <MDBBtn color="default" className="ms-2" onClick={e=>navigate(-1)}>Back</MDBBtn>
                </div>
            </div>
        </div>
    </>)
});

export default AdminEditReview;
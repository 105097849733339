import React from "react";
import SVG from 'react-inlinesvg';
import { useSelector } from "react-redux";
import { memoizedGetAuthUser } from "../../../Store/Reducer/authReducer";
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBIcon, MDBInput, MDBRow } from "mdb-react-ui-kit";

const AdvisorPayoutMethod = React.memo((props) => {
    const authUser = useSelector(memoizedGetAuthUser);

    return (
        <MDBCard className="advisor-payment-methods-card">
            <MDBCardHeader className="d-flex align-items-center">
                <SVG src='/img/icons/debit-card.svg' fill="#00c4ff" height={25} />
                <span className="px-2"><strong>My Payout Method</strong></span>
            </MDBCardHeader>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol md={6}>
                        <div className="payment-method d-flex align-items-center">
                            <div className="d-flex align-items-center">
                                <MDBIcon fab icon='paypal' size="lg" color="primary"/>
                                <span className="ms-2">Paypal Email: </span>
                            </div>
                            <div className="ms-2 flex-fluid">
                                <MDBInput defaultValue={authUser.payMethods.paypal_email} readOnly={true} />
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    )
});

export default AdvisorPayoutMethod;

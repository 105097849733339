import React, {useEffect} from "react";
import styles from './home.scss';
import { MDBContainer } from "mdb-react-ui-kit";

function Introduction(props) {
    return (
        <div className="introduction-container py-2">
            <MDBContainer breakpoint="lg" >
                <div className="introduction-container2 py-2 d-flex flex-column justify-content-center">
                    <div className="intro-confide">
                        <span style={{color:'#0505bf'}}>confide</span>
                        <span className="ms-2" style={{color:'yellow'}}>ideas</span>
                    </div>
                </div>
            </MDBContainer>
        </div>
    )
}

export default Introduction;
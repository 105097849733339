import { createSlice } from '@reduxjs/toolkit';
import { getSHA256 } from '../../Utils/utils';

const changedAdvisorSlice = createSlice({
    name: 'changedAdvisor',
    initialState: {
        advisors: null,
        hash256: null
    },
    reducers: {
        setChangedAdvisors: (state, action) => {
            console.log('setChangedAdvisors', action.payload);
            state.advisors = action.payload;
            state.hash256 = getSHA256(action.payload);
        },
        removeChangedAdvisors: (state, action) => {
            console.log('removeChangedAdvisors');
            state.advisors = null;
            state.hash256 = null;
        }
    }
});

export const {setChangedAdvisors, removeChangedAdvisors} = changedAdvisorSlice.actions;

export const getChangedAdvisorsHash256 = (state) => state.changedAdvisor.hash256;
export const getChangedAdvisors = (state) => state.changedAdvisor.advisors;

export default changedAdvisorSlice.reducer;
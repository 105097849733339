import { MDBBtn } from "mdb-react-ui-kit";
import React from "react"
import ApiService from "../../Core/Service/ApiService";

const Test4 = (props) => {
    const test = () => {
        const data = {
            partnerId: 1000
        }
        ApiService.get('/advisor/chatoff-message/totalcount', data).then(response=>{

        });
    }

    return (
        <MDBBtn onClick={test}>
            Test
        </MDBBtn>
    )
};

export default Test4;
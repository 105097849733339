import React, {useEffect, useRef, useState} from "react";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../../Store/Reducer/authReducer';
import { validateForm } from '../../Utils/utils';
import ApiService from "../../Core/Service/ApiService";
import { 
    MDBModal,
    MDBModalDialog,
    MDBModalBody,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBBtn,
    MDBSpinner,
    MDBInput,
    MDBModalFooter
} from "mdb-react-ui-kit";
import { UserRole } from "../../Constants/constants";
import ModalContext from "../../Context/ModalContext";
import { useContext } from "react";

const AlarmModal = React.memo((props) => {
    const modalContext = useContext(ModalContext);
    const modalData = modalContext.modalData;
    const open = modalContext.openAlarmModal;
    const setOpen = modalContext.setOpenAlarmModal;
    const navigate = useNavigate();

    const closeModal = () => {
        setOpen(false);
        if(modalData.alarm.linkUrl) navigate(modalData.alarm.linkUrl);
    }

    if(open) {
        return (
            <MDBModal 
                open={open} 
                setOpen={setOpen} 
                tabIndex='-1' 
                className="edit-note-modal" 
                closeOnEsc={false} 
                staticBackdrop 
            >
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Notification</MDBModalTitle>
                            <MDBBtn tabIndex='-1' className='btn-close' color='none' onClick={closeModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody className="d-flex p-4 flex-column justify-content-center">
                            <div dangerouslySetInnerHTML={{__html: modalData.alarm?.dialogContent}} />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="default" onClick={closeModal}>Close</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        )
    } else {
        return <></>
    }
});

export default AlarmModal;
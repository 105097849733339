import { useSelector } from "react-redux";
import { IsAuthenticated } from "../../Store/Reducer/authReducer";
import { Link, useNavigate } from "react-router-dom";
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";

const Logo = () => {
    const isAuthenticated = useSelector(IsAuthenticated);
    const navigate = useNavigate();

    const goBack = (e) => {
        e.preventDefault();
        navigate(-1);
    }

    return (
        <div className='d-flex align-items-center'>
            <div>
                <MDBBtn className="d-block d-md-none me-2" tag='a' color="none" onClick={goBack}>
                    <MDBIcon fas icon="chevron-left" size="lg" style={{color: 'gold'}} />
                </MDBBtn>
            </div>

            {isAuthenticated && (
                <div className='logo-brand'>
                    <img src='/img/logo/logo.svg' className="logo-img" /> 
                    <img src='/img/logo/brand.svg' className="logo-brand-img" />                 
                </div>
            )}
            {!isAuthenticated && (
                <Link className='logo-brand' to='/'>
                    <img src='/img/logo/logo.svg' className="logo-img" /> 
                    <img src='/img/logo/brand.svg' className="logo-brand-img" /> 
                </Link>
            )}
        </div>
    )
}

export default Logo;
import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import React from "react";
import MyBalance from "./MyBalance";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import styles from './MyWallet.scss';
import ClientTransactions from "./MyTransactions";
import { useNavigate } from "react-router-dom";

const ClientMyWallet = React.memo((props) => {
    const breadcrumb = [
        {title: 'Home', url: '/'},
        {title: 'My Wallet'}
    ]
    const navigate = useNavigate();
    const goBack = (e) => {
        navigate(-1);
    }

    return <MDBContainer breakpoint="lg" className="client-my-wallet-container p-4">
        <MyBalance />
        <ClientTransactions />

        <div className="d-flex justify-content-end mt-4">
            <MDBBtn onClick={goBack} color="second">Back</MDBBtn>
        </div>
    </MDBContainer>
});

export default ClientMyWallet;
const ChatStatus = {
    CREATED: 1,
    WAITING: 2,
    CANCELED: 3,
    REJECTED: 4,
    MISSED: 5,
    CONNECTED: 10,
    FREE_SETUP_SESSION: 11,
    COUPON_SESSION: 12,
    PAY_SESSION: 13,
    PAUSED: 14,
    ENDED: 100,
    BILLED: 200
}
const ChatStatusStr = (status) => {
    switch(parseInt(status)) {
        case ChatStatus.CREATED:
            return 'Created';
        case ChatStatus.WAITING:
            return 'Waiting';
        case ChatStatus.CANCELED:
            return 'Cancelled';
        case ChatStatus.REJECTED:
            return 'Rejected';
        case ChatStatus.MISSED:
            return 'Missed';
        case ChatStatus.CONNECTED:
            return 'In Chatting';
        case ChatStatus.FREE_SETUP_SESSION:
            return 'In Free Setup Session';
        case ChatStatus.COUPON_SESSION:
            return 'In Free Minutes Session';
        case ChatStatus.PAY_SESSION:
            return 'In Pay Session';
        case ChatStatus.PAUSED:
            return 'Paused';
        case ChatStatus.ENDED:
            return 'In Billing';
        case ChatStatus.BILLED:
            return 'Billed';
    }
}

const SendMessages = {
    REQUEST_CHAT: 'REQUEST_CHAT',
    CANCEL_CHAT: 'CANCEL_CHAT',
    ACCEPT_CHAT: 'ACCEPT_CHAT',
    REJECT_CHAT: 'REJECT_CHAT',
    ENTER_CHAT: 'ENTER_CHAT',
    END_CHAT: 'END_CHAT',
    RESUME_CHAT: 'RESUME_CHAT',
    LEAVE_CHAT: 'LEAVE_CHAT',

    KEEP_ALIVE: "KEEP_ALIVE",
    GET_CHAT_INFO: 'GET_CHAT_INFO',
    GET_ADVISOR_SRV_STATUS: 'GET_ADVISOR_SRV_STATUS',
    ADVISOR_SRV_STATUS_CHG: "ADVISOR_SERVICE_STATUS_CHG",
    CHAT_MESSAGE: "CHAT_MESSAGE",
    IS_TYPING: "IS_TYPING",
    ACK_MSG_RECV: "ACK_MSG_RECV",
    GET_ALL_MESSAGES: 'GET_ALL_MESSAGES'
};

const RecvMessages = {
    PARTNER_UNAVAILABLE: "PARTNER_UNAVAILABLE",
    CHAT_CONNECTED: "CHAT_CONNECTED",
    CHAT_FREE_SETUP: "CHAT_FREE_SETUP",
    CHAT_COUPON_SESSION: 'CHAT_COUPON_SESSION',
    CHAT_PAY_SESSION: 'CHAT_PAY_SESSION',
    MISS_CHAT: 'MISS_CHAT',
    NO_FOUND_CHAT: "NO_FOUND_CHAT",
    ADVISOR_SRV_STATUS: 'ADVISOR_SERVICE_STATUS',
    STATUS_CHANGED_ADVISORS: 'STATUS_CHANGED_ADVISORS',
    LACK_OF_BALANCE: 'LACK_OF_BALANCE',
    RECV_ALARM: 'SEND_ALARM',
    PAYMENT_NOT_MADE: 'PAYMENT_NOT_MADE',
    UPDATE_AUTH_TOKEN: 'UPDATE_AUTH_TOKEN',
    RECV_CHATOFF_MESSAGE: 'RECV_CHATOFF_MESSAGE',
    CHATOFF_THREAD_CLOSED: 'CHATOFF_THREAD_CLOSED',

    ERROR: 'ERROR',
    UNAUTHORIZE: 'UNAUTHORIZED',
    CHAT_INFO: 'CHAT_INFO',
    UNKNOWN_ERROR: 'UNKNOWN_ERROR'
}

const TimeInterval = {
    SEND_GETCHAT_INTERVAL: 10*1000,
    SEND_TYPING_INTERVAL: 3000,
    CHAT_SOCK_ALIVE_INTERVAL: 20*1000,
}

const ReviewType = {
    HAPPY: 'happy',
    UNHAPPY: 'unhappy'
}

const UserStatus = {
    EMAIL_VERIFIED: 'email_verified',
    WAITING: 'waiting',
    DECLINE_ADVISOR: 'decline_advisor',
    APPROVED: 'approved',
    SUSPENDED: 'suspended',
    TERMINATED: 'terminated'
}

const RateLimit = {
    CHAT_MIN: 3.98,
    CHAT_MAX: 13.99
}

const UserRole = {
    CLIENT: 'client',
    ADVISOR: 'advisor',
    ADMIN: 'admin'
}

const PaymentKind = {
    STRIPE: 'stripe',
    PAYPAL: 'paypal',
    CREDIT_CARD: 'credit_card'
}

const PaymentStatus = {
    INTENT_SUCCEED: 'intent_succeed',
    CHARGE_SUCCEED: 'charge_succeed',
    CHARGE_REFUND: 'charge_refund',
    PAYOUT_SUCCESS: 'payout_success',
    PAYOUT_UNCLAIMED: 'payout_unclaimed',
}

const TransactionType = {
    CHARGE: 'charge',
    CHAT_PAY: 'chat_pay',
    CHAT_EARN: 'chat_earn',
    WITHDRAW: 'withdraw',
    WITHDRAW_FEE: 'withdraw_fee',           // in only case that advisor made payout less than withdraw_min
    CREDIT_BACK: 'credit_back'
}

const PendingType = {
    INSERT: 'insert',
    UPDATE: 'update'
}

const PendingStatus = {
    WAITING: 'waiting',
    APPROVED: 'approved',
    DECLINED: 'declined',
}

const OpinionStatus = {
    REPLIED: 'replied',
    WAITING: 'waiting'
}

const AdvisorStatusChangeEvent = {
    LOGON: 'logon',
    LOGOUT: 'logout',
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    CHAT_SERVICE_AVAILABLE: 'chat_service_available',
    CHAT_SERVICE_UNAVAILABLE: 'chat_service_unavailable',
    UPDATED_INFO: 'updated_info',
    BUSY: 'busy',
    FREE: 'free'
}

const BuzzType = {
    Buzz: 'buzz',
    MissedChat: 'missed_chat'
}

const ChatOffMessageType = {
    REQUEST_CHAT: 'request_chat',
    MESSAGE: 'message'
}
const ChatOffThreadStatus = {
    OPEN: 'open',
    CLOSED: 'closed'
}

const LocalStorageKey = {
    AUTH_TOKEN:"confideas_auth_token",
    LAST_TOKEN_UPDATED: "confideas_last_token_updated",
    AUTH_USER: "confideas_auth_user",
    CHAT: "confideas_chat",
    ADVISOR_SERVICE_STATUS: "confideas_advisor_service_status",
    PAYMENT_REQUEST: "confideas_payment_request",
    NO_SHOW_PERMIT_NOTIFICATION: "confideas_noshow_permit_notification",
    USER_ALARMS: "confideas_alarms",
    CHATOFF_THREAD: 'confideas_chatoff_thread',
    CHATOFF_PARTNER: 'confideas_chatoff_partner'
}

const MIN_CHAT_TIME = 5;
const CHAT_WAIT_TIMEOUT = 120;
const TO_RADIANS = Math.PI / 180;
const TEST_MODE = false;
const EDIT_REVIEW_DAYS_OUT = 7;
const BREAKPOINT_WIDTH = 772;

module.exports = {
    ChatStatus,
    ChatStatusStr,
    SendMessages,
    RecvMessages,
    TimeInterval,
    ReviewType,
    UserStatus,
    UserRole,
    RateLimit,
    TransactionType,
    PaymentKind,
    PaymentStatus,
    TransactionType,
    AdvisorStatusChangeEvent,
    ChatOffMessageType,
    ChatOffThreadStatus,
    LocalStorageKey,
    
    MIN_CHAT_TIME,
    CHAT_WAIT_TIMEOUT,
    TO_RADIANS,
    EDIT_REVIEW_DAYS_OUT,
    BREAKPOINT_WIDTH,

    PendingType,
    PendingStatus,
    OpinionStatus,
    BuzzType,

    TEST_MODE
}
import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import React from "react";
import styles from './dashboard.scss';
import Pilot from "./Pilot";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { IsAuthenticated, memoizedGetAuthUser } from "../../../Store/Reducer/authReducer";
import ApiService from "../../../Core/Service/ApiService";
import { useState } from "react";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import Alarm from "./Alarm";
import { useContext } from "react";
import ModalContext from "../../../Context/ModalContext";
import { useNavigate } from "react-router-dom";
import AdvisorClients from "./MyClients";
import { IsChatSocketConnected } from "../../../Store/Reducer/socketReducer";
import FCMService from "./FCMService";
import VerifyItems from "./VerifyItems";
import { UserStatus } from "../../../Constants/constants";

const AdvisorDashboard = React.memo((props)=> {
    const navigate = useNavigate();
    const breadcrumb=[{title: 'Home', url:'/'}, {title: 'Dashboard'}];
    const isAuthenticated = useSelector(IsAuthenticated);
    const authUser = useSelector(memoizedGetAuthUser);
    const [advisorDetail, setAdvisorDetail] = useState();
    const [loading, setLoading] = useState(false);
    const isChatSockConnected = useSelector(IsChatSocketConnected)

    useEffect(()=>{
        if(isAuthenticated && authUser) {
            setLoading(true);

            ApiService.get('/advisor/auth/' + authUser._id).then(result=>{
                setAdvisorDetail(result.data.advisor);
            })
            .finally(()=>{
                setLoading(false);
            })
        }
    }, [isAuthenticated]);

    const goBack = (e) => {
        navigate(-1);
    }

    return (<>
        <MDBContainer breakpoint="lg" className="advisor-dashboard-container">
            {(()=>{
                if(advisorDetail?.listing) {
                    return <>
                        <Pilot detail={advisorDetail} />
                        <AdvisorClients detail={advisorDetail} />
                        <FCMService />
                    </>
                } else if(advisorDetail) {
                    return <>
                        <VerifyItems />
                        <Pilot detail={advisorDetail} />
                    </>
                }
            })()}

            <div className="d-flex justify-content-end">
                <MDBBtn onClick={goBack} color="second">Back</MDBBtn>
            </div>

            {loading && isChatSockConnected && <LoadingIndicator />}
        </MDBContainer>
    </>)
})

export default AdvisorDashboard;
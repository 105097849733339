import React, { useEffect, useState } from "react";
import Select from "react-select";
import AdminTable from "../../../Components/AdminTable/AdminTable";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { Link } from "react-router-dom";
import { MDBCheckbox, MDBIcon, MDBInput, MDBSwitch } from "mdb-react-ui-kit";
import Paginator from "../../../Components/Paginator/Paginator";
import { TransactionType, UserStatus } from "../../../Constants/constants";
import { toast } from "react-toastify";
import Moment from "react-moment";

const AdminTransactionList = React.memo((props) => {
    const perPageOpts = [
        { value: 10,label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" }
    ];
    const [curPerPageOpt, setCurPerPageOpt] = useState(perPageOpts[0]);
    const transTypeOpts = [
        {value: '', label: 'All'},
        {value: TransactionType.CHARGE, label: 'Add Balance'},
        {value: TransactionType.WITHDRAW, label: 'Withdraw'},
        {value: TransactionType.CHAT_EARN, label: 'Chat Earn'},
        {value: TransactionType.CHAT_PAY, label: 'Chat Pay'}
    ];
    const [curTransTypeOpt, setCurTransTypeOpt] = useState(transTypeOpts[0]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [total, setTotal] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [formData, setFormData] = useState({
        search: '',
        perPage: 10,
        pageNum: 1,
        transaction_type: ''
    })

    useEffect(()=>{
        getTransactions();
    }, [formData]);

    const getTransactions = () => {
        setLoading(true);

        ApiService.post('/admin/transaction/index', formData).then(result=>{
            setTotal(result.data.total);
            setTransactions(result.data.transactions);
        })
        .catch(error=>{

        })
        .finally(()=>{
            setLoading(false);
        })
    }

    const onSearchKeyDown = (e) => {
        if(e.key == 'Enter') {
            e.preventDefault();
            setFormData({
                ...formData,
                pageNum: 1,
                search
            });
        }
    }
    const onPageChanged = (pageNum) => {
        setFormData({
            ...formData,
            pageNum
        })
    }
    const onPerPageChanged = (opt) => {
        setFormData({
            ...formData,
            perPage: opt.value,
            pageNum: 1
        })
        setCurPerPageOpt(opt);
    }
    const onTransTypeChanged = (opt) => {
        setFormData({
            ...formData,
            transaction_type: opt.value,
            pageNum: 1
        });
        setCurTransTypeOpt(opt);
    }
    const onSearchBtnClicked = (e) => {
        getTransactions();
    }

    const columns = [
        {name: 'createdAt', title: 'Date'},
        {name: 'username', title: 'Username'},
        {name: 'email', title: 'Email'},
        {name: 'transaction_type', title: 'Transaction Type'},
        {name: 'amount', title: 'Amount'}
    ]
    const breadcrumb=[{title: 'Transaction'}, {title: 'Transactions'}];
    return (<>
        <Breadcrumb breadcrumb={breadcrumb} />
        <div className="admin-transaction-list">
            <div className="d-flex">
                <div className="total-count px-2">
                    <label>Count : </label>
                    <span className="ps-2">{total}</span>
                </div>
                <div className="flex-fluid d-flex justify-content-end align-items-center">
                    <div>
                        <Select className="per-page"
                                    options={perPageOpts} 
                                    onChange={(opt) => onPerPageChanged(opt)} 
                                    defaultValue={curPerPageOpt}
                        />
                    </div>
                    <div style={{width: 150}} className="mx-1">
                        <Select className="trans-type"
                                    options={transTypeOpts} 
                                    onChange={(opt) => onTransTypeChanged(opt)} 
                                    defaultValue={curTransTypeOpt}
                        />
                    </div>
                    <div className="d-flex align-items-center">
                        <MDBInput   label='Search' 
                                    value={search} 
                                    onKeyDown={onSearchKeyDown}
                                    onChange={(e)=>setSearch(e.target.value)} 
                                    />
                        <div className="ms-1 cursor-pointer" onClick={onSearchBtnClicked}>
                            <MDBIcon fas icon='search' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="admin-transaction-table mt-2">
                <AdminTable columns={columns} rows={transactions}>
                    {(row, colname, rowIndex, colIndex) => {
                        switch(colname) {
                            case 'createdAt':
                                return <Moment format="YYYY-MM-DD hh:mm">{row[colname]}</Moment>
                            case 'amount':
                                return <div>$ {row[colname]}</div>
                        }
                    }}
                </AdminTable>

                <div className='d-flex justify-content-center mt-4'>
                    <Paginator total={total} perPage={formData.perPage} onPageChanged={onPageChanged} />
                </div>

                {loading && <LoadingIndicator />}
            </div>
        </div>
    </>)
})

export default AdminTransactionList;
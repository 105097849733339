import { MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow } from "mdb-react-ui-kit";

const PayoutMethod = (props) => {
    const {advisor, setAdvisor} = props;

    const handleInputChange = (e) => {
        setAdvisor({
            ...advisor,
            [e.target.name]: e.target.value
        })
    }

    return (
        <MDBContainer breakpoint="lg" className="d-flex justify-content-center">
            <MDBRow className="w-100">
                <MDBCol size={6} className="d-flex">
                    <div className="d-flex align-items-center">
                        <MDBIcon fab icon='paypal' className="me-2" size="lg" color="primary" />
                        <span>Paypal Email:</span>
                    </div>
                    <div className="ms-2 flex-fluid">
                        {advisor && (
                            <MDBInput
                                name="paypal_email"
                                value={advisor.paypal_email} 
                                onChange={handleInputChange}
                            />
                        )}
                    </div>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )
}

export default PayoutMethod;
import React from "react";
import classname from 'classnames';
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import styles from './home.scss';

function WhyChooseUs(props) {
    return (
        <div className="why-choose-us-container">
            <MDBContainer breakpoint="lg" className="why-choose-us-container2">
                <img className="flower1" src='/img/backgrounds/plumeria.svg' />
                <img className="flower2 d-none d-lg-block" src='/img/backgrounds/plumeria.svg' />
                <div>
                    <h3 className="mb-4 px-2">Why Choose Us?</h3>
                    <MDBRow>
                        <MDBCol md='4' className="px-4">
                            <h5>Confiding Ideas:</h5>
                            <p>
                                Gain psychic insights and discuss things you can't even mention to your best friends!
                            </p>
                        </MDBCol>
                        <MDBCol md='4' className="px-4">
                            <h5>Anonymous Login:</h5>
                            <p>
                                Share your life in a confidential chat without revealing your identity.
                            </p>
                        </MDBCol>
                        <MDBCol md='4' className="px-4">
                            <h5>Customer Satisfaction:</h5>
                            <p>
                                Have advisors by your side to guide you through all areas of life.                                
                            </p>
                        </MDBCol>
                    </MDBRow>
                </div>
            </MDBContainer>
        </div>
    )
}

export default WhyChooseUs;
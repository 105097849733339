import React, {useState, useEffect, useContext, useMemo} from "react";
import { toast } from 'react-toastify';
import { addDate, getAvatarUrl, text2html, validateForm } from '../../Utils/utils';
import ApiService from "../../Core/Service/ApiService";
import Select from "react-select";
import { 
    MDBModal,
    MDBModalDialog,
    MDBModalBody,
    MDBModalContent,
    MDBInput,
    MDBBtn,
    MDBModalHeader,
    MDBModalTitle,
    MDBSpinner,
    MDBRange,
    MDBCheckbox,
    MDBRadio,
} from "mdb-react-ui-kit";
import ModalContext from "../../Context/ModalContext";
import TextAreaAutoHeight from "../TextAreaAutoHeight/TextAreaAutoHeight";
import Moment from "react-moment";
import EventContext from "../../Context/EventContext";
import { BuzzType } from "../../Constants/constants";

const SendCouponModal = React.memo((props) => {
    const modalContext = useContext(ModalContext);
    const open = modalContext.openSendCouponModal;
    const setOpen = modalContext.setOpenSendCouponModal;
    const modalData = modalContext.modalData;
    const [sending, setSending] = useState(false);
    const [inputErrors, setInputErrors] = useState({});
    const [formData, setFormData] = useState({
        cId: '',
        withFreeMinutes: false,
        freeMinutes: 0,
        expire_days: 21,
        expireAt: addDate(new Date(), 21),
        message: ''
    });
    const eventContext = useContext(EventContext);

    useEffect(()=>{
        if(modalData && open) {
            setFormData({
                cId: modalData.client._id,
                withFreeMinutes: false,
                freeMinutes: 0,
                expire_days: 21,
                expireAt: addDate(new Date(), 21),
                message: ''
            })
            setInputErrors({});
        }
    }, [modalData, open])

    const closeModal = () => {
        setOpen(false);
    }

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const onWithFreeMinutesChanged = (e) => {
        setFormData({
            ...formData,
            freeMinutes: formData.withFreeMinutes ? 0 : 3,
            expire_days: formData.withFreeMinutes ? 0 : 21,
            expireAt: formData.withFreeMinutes ? null : addDate(new Date(), 21),
            withFreeMinutes: !formData.withFreeMinutes
        })
    }

    const sendMessage = (e) => {
        e.preventDefault();
        
        let rules = {};
        if(formData.withFreeMinutes) {
            rules = {
                freeMinutes: {
                    required: 'number',
                    min: 3,
                    max: 5
                },
                expire_days: {
                    required: 'number',
                    min: 1,
                    max: 30
                },
            }
        }

        const valid_ret = validateForm(formData, rules);
        if(valid_ret.result) {
            setInputErrors({});
            setSending(true);

            if(modalData.buzz) formData.buzz_id = modalData.buzz._id

            ApiService.post('/advisor/coupon/create', formData).then(result => {
                toast.success("The message has been sent successfully");
                closeModal();
                eventContext.setEvtCouponSent({
                    time: new Date(),
                    action: 'sent'
                });
            })
            .finally(()=>{
                setSending(false);
            });
        } else {
            setInputErrors(valid_ret.errors);
        }
    }
    const onFreeMinutesChanged = (m) => {
        setFormData({
            ...formData,
            freeMinutes: m
        })
    }

    if(open) {
        return (
            <MDBModal   className="free-service-modal" 
                        open={open} 
                        setOpen={setOpen} 
                        closeOnEsc={false}
                        staticBackdrop 
                        tabIndex='-1' >
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader className="d-flex w-100">
                            <MDBModalTitle className="flex-fluid d-flex justify-content-center align-items-center">
                                {(()=>{
                                    if(modalData?.buzz?.type == BuzzType.Buzz) {
                                        return <span>A client was buzzing you!</span>
                                    } else if(modalData?.buzz?.type == BuzzType.MissedChat) {
                                        return <span>You missed a call<span style={{color: 'red'}}>!!</span></span>
                                    }
                                })()}
                            </MDBModalTitle>
                            <MDBBtn tabIndex='-1' className='btn-close' color='none' onClick={closeModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody className='d-flex p-4 flex-column justify-content-center align-items-center'>
                            <div className="buzz-info w-100 p-2 py-3">
                                {modalData?.buzz?.type == BuzzType.Buzz && <>
                                <div className="d-flex align-items-center">
                                    <img className="round-avatar-40" src={getAvatarUrl(modalData?.client.avatar)} />
                                    <span className="ms-2">{modalData?.client.username}</span>
                                    <span className="ms-1 comment font-italic">wrote</span>
                                </div>
                                <div 
                                    className="buzz-message ms-2 mt-1" 
                                    dangerouslySetInnerHTML={{ __html: text2html(modalData?.buzz?.message)}}
                                />
                                </>}

                                {modalData?.buzz?.type == BuzzType.MissedChat && <>
                                <div className="d-flex align-items-center">
                                    <span className="mx-2 comment font-italic">You missed a call from</span>
                                    <img className="round-avatar-40" src={getAvatarUrl(modalData?.client.avatar)} />
                                    <span className="ms-2">{modalData?.client.username}</span>
                                </div>
                                </>}
                            </div>
                            <div className="mt-4 w-100">
                                <MDBCheckbox label='Offer Free Minutes'
                                            name='withFreeMinutes'
                                            checked={formData.withFreeMinutes}
                                            onChange={onWithFreeMinutesChanged} 
                                />
                                <span className="notify">(Unselect this box if you only want to send message.)</span>
                            </div>

                            {formData.withFreeMinutes && <>
                            <div className="free-minutes mt-2 w-100">
                                <label>Time (min): </label>
                                <div className="value">
                                    <MDBRadio name='freeMinute' 
                                        id='freeMinutes3' 
                                        value={3} 
                                        label='3 mins' 
                                        inline 
                                        defaultChecked
                                        onClick={()=>onFreeMinutesChanged(3)}/>
                                    <MDBRadio name='freeMinute' 
                                        id='freeMinutes5' 
                                        value={5} 
                                        label='5 mins' 
                                        inline 
                                        onClick={()=>onFreeMinutesChanged(5)}/>
                                </div>
                            </div>
                            </>}

                            <div className="message mt-2 w-100">
                                <label>Message : </label>
                                <div className="value">
                                    <TextAreaAutoHeight 
                                            className='form-control'
                                            name='message'
                                            value={formData.message}
                                            onChange={handleInputChange}
                                            tabIndex={2}
                                    />
                                    {inputErrors.message && <div className="error">{ inputErrors.message }</div>}
                                </div>
                            </div>
                            <div className="action d-flex justify-content-end align-items-center mt-4 ">
                                <MDBBtn className="me-2" color="second" onClick={closeModal} disabled={sending}>Cancel</MDBBtn>
                                <MDBBtn className="confideas-primary-btn" onClick={sendMessage} disabled={sending}>
                                    {sending && <>
                                        <MDBSpinner size='sm' role='status' tag='span' className='me-2' />
                                        Sending
                                    </>}
                                    {!sending && <span>Send</span>}
                                </MDBBtn>
                            </div>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        )
    } else {
        return <></>
    }
});

export default SendCouponModal;
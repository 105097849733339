import { MDBInput } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Calendar } from "react-date-range";
import { formatDate_YYYY_MM_DD } from "../../Utils/utils";

const LiteDatePicker = (props) => {
    const [selectedDateData, setSelectedDateData] = useState({
        date: props.selectedDate ?? new Date(),
        dateStr: formatDate_YYYY_MM_DD(props.selectedDate ?? new Date())
    });
    const [showDatePicer, setShowDatePicker] = useState(false);

    const onInputClicked = () => {
        setShowDatePicker(true);
    }

    useEffect(()=>{
        if(showDatePicer) {
            const onDocumentClicked = (e) => {
                if(showDatePicer && !e.target.closest('.lite-date-picker')) {
                    console.log('fff')
                    setShowDatePicker(false);
                }
            }

            window.document.addEventListener('click', onDocumentClicked);

            return () => {
                window.document.removeEventListener('click', onDocumentClicked);
            }    
        }
    }, [showDatePicer]);

    const onDateSelected = (date) => {
        setSelectedDateData({
            date,
            dateStr: formatDate_YYYY_MM_DD(date)
        })
        props.onDateSelected && props.onDateSelected(date);
        setShowDatePicker(false);
    }

    return (<div className="position-relative lite-date-picker">
        <MDBInput 
            value={selectedDateData.dateStr} 
            readOnly={true} 
            onClick={onInputClicked} 
        />
        {showDatePicer && <div className="position-absolute" style={{left:0, top: 30, zIndex: 10}}>
            <Calendar 
                date={selectedDateData.date}
                onChange={onDateSelected}
            />
        </div>}
    </div>)
}

export default LiteDatePicker;
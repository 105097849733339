import React, {useState, useEffect, useContext, useMemo} from "react";
import { 
    MDBModal,
    MDBModalDialog,
    MDBModalBody,
    MDBModalContent,
    MDBBtn,
    MDBModalHeader,
    MDBModalTitle,
    MDBSpinner,
    MDBModalFooter,
    MDBIcon,
} from "mdb-react-ui-kit";
import ModalContext from "../../Context/ModalContext";
import { useNavigate } from "react-router-dom";

const ActivateListingModal = React.memo((props) => {
    const modalContext = useContext(ModalContext);
    const open = modalContext.openActivateListingModal;
    const setOpen = modalContext.setOpenActivateListingModal;
    const modalData = modalContext.modalData;
    const navigate = useNavigate();

    const closeModal = () => {
        setOpen(false);
    }

    const gotoVerify = (vi) => {
        navigate('/advisor/edit/contacts');
        setOpen(false);
    }

    if(open) {
        return (
            <MDBModal
                className="decline-advisor-modal"
                open={open}
                setOpen={setOpen} 
                closeOnEsc={false}
                staticBackdrop 
                tabIndex='-1'
            >
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>
                                Activate Your Listing
                                <MDBBtn tabIndex='-1' className='btn-close' color='none' onClick={closeModal}></MDBBtn>
                            </MDBModalTitle>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className="mb-2" style={{color: 'red'}}>Please kindly be advised:</div>
                            <p>
                                As part of the recruiting process, once you have been accepted to offer your services on our platform, 
                                the following items will be required to set up your payout account.
                            </p>
                            <div className="ps-2">
                                {modalData.verifyItems?.map((vi, index) => (
                                    <div className="d-flex align-items-center py-1" key={index}>
                                        <MDBBtn className="p-0" color="link" onClick={e=>gotoVerify(vi)}>
                                            <MDBIcon far icon="file" />
                                            <span className="ms-2">{vi.label}</span>
                                        </MDBBtn>
                                    </div>
                                ))}
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="default" onClick={closeModal}>Close</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        )
    } else {
        return <></>
    }
});

export default ActivateListingModal;
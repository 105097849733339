import { Outlet } from "react-router";
import { Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { IsAuthenticated } from "../../Store/Reducer/authReducer";

const useAuth = () => {
  const isAuthenticated = useSelector(IsAuthenticated);
  return isAuthenticated;
}

const AuthProtectedRoutes = () => {
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Navigate replace to="/login" />
}

export default AuthProtectedRoutes


import { LocalStorageKey } from "../../Constants/constants";

export const getAuthToken = () => {
  return window.localStorage.getItem(LocalStorageKey.AUTH_TOKEN);
};
export const getLastAuthTokenUpdtTime = () => {
  const str = window.localStorage.getItem(LocalStorageKey.LAST_TOKEN_UPDATED);
  if(str) {
    return parseInt(str);
  }
  else {
    return 0;
  }
}
export const setAuthToken = (token) => {
  window.localStorage.setItem(LocalStorageKey.AUTH_TOKEN, token);
  window.localStorage.setItem(LocalStorageKey.LAST_TOKEN_UPDATED, new Date().getTime().toString());
};
export const destroyAuthToken = () => {
  window.localStorage.removeItem(LocalStorageKey.AUTH_TOKEN);
  window.localStorage.removeItem(LocalStorageKey.LAST_TOKEN_UPDATED);
};

export const getAuthUser = () => {
  const v = window.localStorage.getItem(LocalStorageKey.AUTH_USER);
  return v == null ? null : JSON.parse(v);
}
export const setAuthUser = (authUser) => {
  window.localStorage.setItem(LocalStorageKey.AUTH_USER, JSON.stringify(authUser));
}
export const destroyAuthUser = () => {
  window.localStorage.removeItem(LocalStorageKey.AUTH_USER);
}

export const getChat = () => {
  const v = window.localStorage.getItem(LocalStorageKey.CHAT);
  return v == null ? {} : JSON.parse(v);
}
export const setChat = (chat) => {
  window.localStorage.setItem(LocalStorageKey.CHAT, JSON.stringify(chat));
}
export const destroyChat = () => {
  window.localStorage.removeItem(LocalStorageKey.CHAT);
}

export const getAdvisorServiceStatus = () => {
  const v = window.localStorage.getItem(LocalStorageKey.ADVISOR_SERVICE_STATUS);
  return v == null ? {} : JSON.parse(v);
}
export const setAdvisorServiceStatus = (serviceStatus) => {
  window.localStorage.setItem(LocalStorageKey.ADVISOR_SERVICE_STATUS, JSON.stringify(serviceStatus));
}
export const destroyAdvisorServiceStatus = () => {
  window.localStorage.removeItem(LocalStorageKey.ADVISOR_SERVICE_STATUS);
}

export const getPaymentRequest = () => {
  const v = window.localStorage.getItem(LocalStorageKey.PAYMENT_REQUEST);
  return v == null ? null : JSON.parse(v);
}
export const setPaymentRequest = (paymentRequest) => {
  window.localStorage.setItem(LocalStorageKey.PAYMENT_REQUEST, JSON.stringify(paymentRequest));
}
export const destroyPaymentRequest = () => {
  window.localStorage.removeItem(LocalStorageKey.PAYMENT_REQUEST);
}

export const getNoShowPermitNotification = () => {
  const v = window.localStorage.getItem(LocalStorageKey.NO_SHOW_PERMIT_NOTIFICATION);
  return (v === 'true') ? true : false;
}
export const setNoShowPermitNotification = (v) => {
  window.localStorage.setItem(LocalStorageKey.NO_SHOW_PERMIT_NOTIFICATION, v);
}

export const getAlarms = () => {
  const v = window.localStorage.getItem(LocalStorageKey.USER_ALARMS);
  return v == null ? null : JSON.parse(v);
}
export const setAlarms = (alarms) => {
  window.localStorage.setItem(LocalStorageKey.USER_ALARMS, JSON.stringify(alarms));
}
export const destroyAlarms = () => {
  window.localStorage.removeItem(LocalStorageKey.USER_ALARMS);
}

export const getPaginationInfo = (key) => {
  const v = window.localStorage.getItem(`confideas_${key}_pagination`);
  return v == null ? null : JSON.parse(v);
}
export const setPaginationInfo = (key, pi) => {
  window.localStorage.setItem(`confideas_${key}_pagination`, JSON.stringify(pi));
}
export const destroyPaginationInfo = (key) => {
  window.localStorage.removeItem(`confideas_${key}_pagination`);
}

export const getChatOffThread = () => {
  const v = window.localStorage.getItem(LocalStorageKey.CHATOFF_THREAD);
  return v == null ? null : JSON.parse(v);
}
export const setChatOffThread = (thread) => {
  window.localStorage.setItem(LocalStorageKey.CHATOFF_THREAD, JSON.stringify(thread))
}

export const getChatOffPartner = () => {
  const v = window.localStorage.getItem(LocalStorageKey.CHATOFF_PARTNER);
  return v == null ? null : JSON.parse(v);
}
export const setChatOffPartner = (partner) => {
  window.localStorage.setItem(LocalStorageKey.CHATOFF_PARTNER, JSON.stringify(partner))
}

export default { 
  getAuthToken, setAuthToken, destroyAuthToken,getLastAuthTokenUpdtTime,
  getAuthUser, setAuthUser, destroyAuthUser,
  getChat, setChat, destroyChat,
  getAdvisorServiceStatus, setAdvisorServiceStatus, destroyAdvisorServiceStatus,
  getPaymentRequest, setPaymentRequest, destroyPaymentRequest,
  getNoShowPermitNotification, setNoShowPermitNotification,
  getAlarms, setAlarms, destroyAlarms,
  getPaginationInfo, setPaginationInfo, destroyPaginationInfo,
  getChatOffThread, setChatOffThread,
  getChatOffPartner, setChatOffPartner
};

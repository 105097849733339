import React, {useState, useEffect, useRef} from "react";
import styles from './Chat.scss';
import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import ChatInputHistory from "./ChatInputHistory";
import ChatPartner from "./ChatPartner";
import ChatPartnerSm from "./ChatPartnerSm";
import { useDispatch, useSelector } from "react-redux";
import { IsChatSocketConnected, getChatSocket } from "../../Store/Reducer/socketReducer";
import { ChatStatus, MIN_CHAT_TIME, RecvMessages, SendMessages, TimeInterval, UserRole } from "../../Constants/constants";
import { IsChatCreated, endChat, updateChat, removeChat, getCurrentChatStatus, memoizedGetChat } from "../../Store/Reducer/chatReducer";
import { IsAuthenticated, memoizedGetAuthUser, updateAuth } from "../../Store/Reducer/authReducer";
import { useNavigate } from "react-router-dom";
import md5 from 'md5';
import { toast } from "react-toastify";
import ChatEndModal from "../../Components/Modals/ChatEndModal";
import { hookTimer } from "../../Utils/timer";
import AdvisorNoteModal from "../../Components/Modals/AdvisorNoteModal";
import { floatV, formatDate, formatDate_YYYY_MM_DD, html2Text, scrollUpTop, showWebNotification, updateFavIcon } from "../../Utils/utils";
import { useContext } from "react";
import ModalContext from "../../Context/ModalContext";
import WaitChatModal from "../../Components/Modals/WaitChatModal";
import AcceptChatModal from "../../Components/Modals/AcceptChatModal";
import AllowNotifyModal from "../../Components/Modals/AllowNotifiyModal";
import DeviceContext from "../../Context/DeviceContext";
import LackBalanceClientModal from "./LackBalanceClientModal";
import LackBalanceAdvisorModal from "./LackBalanceAdvisorModal";
import ChatContext from './ChatContext';
import ClientNotes from "./ClientNotes";

let sentTypingTime = 0;
let recvTypingTime = 0;
let unreadMsgCount = 0;
let sendTimeOutId;

const Chat = React.memo((props) => {
    const sockMsgHandlerKey = 'chat_session';
    const modalContext = useContext(ModalContext);
    const dispatch = useDispatch();
    const isChatSockConnected = useSelector(IsChatSocketConnected);
    const chatSocket = useSelector(getChatSocket);
    const isChatCreated = useSelector(IsChatCreated);
    const chat = useSelector(memoizedGetChat);
    const chatRef = useRef(chat);
    const isLoggedOn = useSelector(IsAuthenticated);
    const isAuthenticated = useSelector(IsAuthenticated);
    const authUser = useSelector(memoizedGetAuthUser);
    const navigate = useNavigate();
    const deviceContext = useContext(DeviceContext);
    const [partnerSmTop, setPartnerSmTop] = useState(0);
    const [inputBarBottom, setInputBarBottom] = useState(0);

    const [chatHistory, setChatHistory] = useState([]);
    const [partnerIsTyping, setPartnerIsTyping] = useState(false);
    const [partnerIsAvailable, setPartnerAvailable] = useState(true);
    const [chatInputFocus, setChatInputFocus] = useState(false);
    const [openLackBalanceModal, setOpenLackBalanceModal] = useState(false);
    const [openClientNote, setOpenClientNote] = useState(false);

    useEffect(() => {
        const confirmExit = (event) => {
          event.preventDefault();
          event.returnValue = '';
          return 'Are you sure to leave chat?';
        };
        window.addEventListener('beforeunload', confirmExit);

        // To add window.scroll event handler for adjusting top header's position in iOS
        const handleScroll = () => {
            setPartnerSmTop(window.scrollY);
        }
        if(deviceContext.isIOS && deviceContext.isMobile) {
            window.addEventListener('scroll', handleScroll);
        }

        // To add window.visualViewport.resize event handler for adjusting top header's position in android
        function handleViewportChange() {
            setPartnerSmTop(window.visualViewport.offsetTop);

            var windowHeight = window.innerHeight;
            var documentHeight = document.body.scrollHeight;
            var windowScrollHeight = Math.max(documentHeight, windowHeight);
            const bottom = windowScrollHeight - window.visualViewport.height - window.visualViewport.offsetTop;
            console.log('inputbarBottom', bottom);
            setInputBarBottom(bottom);
        }
        if(!deviceContext.isIOS && deviceContext.isMobile) {
            window.visualViewport.addEventListener('resize', handleViewportChange);
            window.visualViewport.addEventListener('scroll', handleViewportChange);
        }
    
        return () => {
          window.removeEventListener('beforeunload', confirmExit);
          window.removeEventListener('scroll', handleScroll);
          window.visualViewport.removeEventListener('resize', handleViewportChange);
          window.visualViewport.removeEventListener('scroll', handleViewportChange);
        };
      }, []);

    useEffect(()=>{
        chatRef.current = chat;
        unreadMsgCount = 0;
    }, [chat]);

    useEffect(()=>{
        if(isAuthenticated && isChatCreated && isChatSockConnected) {
            hookTimer('partner_istyping_timer', 1000, ()=>{
                const now = new Date().getTime();
                if(now - recvTypingTime > 2 * TimeInterval.SEND_TYPING_INTERVAL) {
                    setPartnerIsTyping(false);
                }
            });

            console.log('&&&&&&&&&&&&&&&&&&&&&&');
            chatSocket.emit(SendMessages.ENTER_CHAT, {chatId: chatRef.current.chatId});
            chatSocket.emit(SendMessages.GET_ALL_MESSAGES, {chatId: chatRef.current.chatId});

            chatSocket.on(SendMessages.END_CHAT, sockMsgHandlerKey, async ({chatTime, amount, balance})=> {
                if(authUser.role === UserRole.CLIENT) {
                    await dispatch(updateAuth({ balance: floatV(balance) }));
                }

                if(chatTime > 0) {
                    modalContext.setModalData({
                        client: chat.client,
                        advisor: chat.advisor,
                        chatDetail: {
                            chatTime,
                            amount,
                            balance
                        }
                    })
                    setTimeout(()=>{
                        modalContext.setOpenChatEndModal(true);
                    }, 1000);
                }

                await dispatch(removeChat());
            });
            chatSocket.on(RecvMessages.LACK_OF_BALANCE, sockMsgHandlerKey, (data) => {
                console.log(`recv lack_of_balance message.`)
                dispatch(updateChat({
                    status: ChatStatus.PAUSED,
                    isChatting: false,
                    passedSeconds: data.delayedSec
                }));
            })
            chatSocket.on(SendMessages.GET_ALL_MESSAGES, sockMsgHandlerKey, (data) => {
                setChatHistory(data.messages.map(msg => {
                    const time = new Date(authUser.role === UserRole.CLIENT ? msg.client_time : msg.advisor_time);
                    return {
                        sender: (authUser.role === msg.sender) ? 'me' : 'partner',
                        content: msg.message,
                        time,
                        ack: (msg.recv_ack == 1)
                    }
                }))
            })
            chatSocket.on(SendMessages.CHAT_MESSAGE, sockMsgHandlerKey, ({chatId, msgId, content}) => {
                chatContext.setPartnerAvailable(true);
                
                if(authUser.role === UserRole.ADVISOR && chat?.status === ChatStatus.PAUSED) {
                    console.log('receive chat_message: resume chat')
                    chatSocket.emit(SendMessages.GET_CHAT_INFO, {chatId});
                }
                setChatHistory(chatHistory => [
                    ...chatHistory,
                    {
                        sender: 'partner',
                        content,
                        time: new Date()
                    }
                ]);
                chatSocket.emit(SendMessages.ACK_MSG_RECV, {
                    chatId,
                    msgId,
                    recvTime: formatDate(new Date(), 'YYYY-MM-DD HH:mm')
                });

                if(document.hidden) {
                    unreadMsgCount++;
                    document.title = `Unread Message : (${unreadMsgCount})`;
                    updateFavIcon('/img/logo/logo_notice.png');

                    const partnerName = authUser.role === UserRole.CLIENT ? chatRef.current.advisor.username : chatRef.current.client.username;
                    showWebNotification( `${partnerName} is saying`, html2Text(content),  (result) => {
                            if(result === false) {
                                console.log('trying to open allow notification modal.');
                                modalContext.setOpenAllowNotifyModal(true);
                            }
                        }
                    )
                } else {
                    unreadMsgCount = 0;
                }
            });
            chatSocket.on(RecvMessages.PARTNER_UNAVAILABLE, sockMsgHandlerKey, () => {
                console.log('partner is unavailable');
                chatContext.setPartnerAvailable(false);
            })
            chatSocket.on(SendMessages.IS_TYPING, sockMsgHandlerKey, () => {
                chatContext.setPartnerIsTyping(true);
                chatContext.setPartnerAvailable(true);
                recvTypingTime = new Date().getTime();
            });
            chatSocket.on(SendMessages.ACK_MSG_RECV, sockMsgHandlerKey, ({msgId})=>{
                // console.log(`received ACK_MSG_RECV, msgId=${msgId}`);
                setChatHistory(chatHistory => chatHistory.map(ch=>{
                    if(ch.msgId === msgId) {
                        return {
                            ...ch,
                            ack: true
                        }
                    } else {
                        return ch;
                    }
                }))
            });

            return () => {
                chatSocket.off(RecvMessages.CHAT_INFO, sockMsgHandlerKey);
                chatSocket.off(SendMessages.END_CHAT, sockMsgHandlerKey);
                chatSocket.off(RecvMessages.LACK_OF_BALANCE, sockMsgHandlerKey);
                chatSocket.off(SendMessages.RESUME_CHAT, sockMsgHandlerKey);
                chatSocket.off(SendMessages.GET_ALL_MESSAGES, sockMsgHandlerKey);
                chatSocket.off(RecvMessages.PARTNER_UNAVAILABLE, sockMsgHandlerKey);
                chatSocket.off(SendMessages.CHAT_MESSAGE, sockMsgHandlerKey);
                chatSocket.off(SendMessages.IS_TYPING, sockMsgHandlerKey);
                chatSocket.off(SendMessages.ACK_MSG_RECV, sockMsgHandlerKey);
            }
        }
    }, [isChatSockConnected, isAuthenticated, isChatCreated]);

    const sendMessage = (msgContent) => {
        if(msgContent.trim() == '') return;

        if(sendTimeOutId) {
            clearTimeout(sendTimeOutId);
        }

        sendTimeOutId = setTimeout(()=>{
            sendTimeOutId = null;
            const msgId = md5(`${chatRef.current.chatId}-${new Date().getTime()}`);
            chatSocket.emit(SendMessages.CHAT_MESSAGE, {
                chatId: chatRef.current.chatId,
                msgId: msgId,
                content: msgContent,
                sendTime: formatDate(new Date(), 'YYYY-MM-DD HH:mm')
            });
            setChatHistory(chatHistory => [
                ...chatHistory,
                {
                    sender: 'me',
                    content: msgContent,
                    msgId: msgId,
                    time: new Date(),
                    ack: false
                }
            ]);
        }, 200);
    }

    const onTyping = () => {
        const now = new Date().getTime();
        if(now - sentTypingTime > TimeInterval.SEND_TYPING_INTERVAL) {
            chatSocket?.emit(SendMessages.IS_TYPING, {
                chatId: chatRef.current.chatId
            });
            sentTypingTime = now;
        }
    }

    // To be called when user click 'Hang Up' button
    const endChatSession = () => {
        if(!isChatSockConnected) {
            toast.warn("The connection with the server has been disconnected. Please refresh the page.");
            return;
        }
        chatSocket.emit(SendMessages.END_CHAT, {
          chatId: chatRef.current.chatId
        });
    }
    const onFocus = () => {
        unreadMsgCount = 0;
        setTimeout(()=>{
            document.title = 'Confideas';
            updateFavIcon('/img/logo/logo.svg');
        }, 1000);
    }
    const chatContext = {
        chatInputFocus, setChatInputFocus,
        openLackBalanceModal, setOpenLackBalanceModal,
        openClientNote, setOpenClientNote,
        partnerIsAvailable, setPartnerAvailable,
        partnerIsTyping, setPartnerIsTyping,
        chatHistory, setChatHistory,
        partnerSmTop, inputBarBottom,
        sendMessage,
        endChatSession,
        onTyping,
    }

    return (
    <ChatContext.Provider value={chatContext}>
        <div className="chat-container1" onFocus={onFocus}>
            <MDBContainer breakpoint="lg" className={`chat-container2 ${deviceContext.isIOS ? 'iOS' : ''}`}>
                <div className="chat-input-history-container d-flex flex-column">
                    <ChatPartnerSm style={{top: partnerSmTop}} />
                    <ChatInputHistory />
                </div>
                <ChatPartner />
                {authUser?.role === UserRole.ADVISOR && <ClientNotes />}
            </MDBContainer>
        </div>

        <ChatEndModal />
        <WaitChatModal />
        <AcceptChatModal />
        <AllowNotifyModal />

        {authUser.role  === UserRole.CLIENT && 
            <LackBalanceClientModal />
        }
        {authUser.role === UserRole.ADVISOR && <>
            <AdvisorNoteModal client={chatRef.current.client}/>
            <LackBalanceAdvisorModal />
        </>}
    </ChatContext.Provider>
    )
});

export default Chat;
import React, {useEffect, useRef, useState} from "react";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../../Store/Reducer/authReducer';
import { validateForm } from '../../Utils/utils';
import ApiService from "../../Core/Service/ApiService";
import { 
    MDBModal,
    MDBModalDialog,
    MDBModalBody,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBBtn,
    MDBSpinner,
    MDBInput
} from "mdb-react-ui-kit";
import { UserRole } from "../../Constants/constants";
import ModalContext from "../../Context/ModalContext";
import { useContext } from "react";

const ChangePasswdModal = React.memo((props) => {
    const modalContext = useContext(ModalContext);
    const [changingPasswd, setChangingPasswd] = useState(false);
    const curPasswdInputRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        curPasswd: '',
        newPasswd: '',
        checkPasswd: ''
    })
    const rules = {
        curPasswd: {
            required: 'string',
            label: 'Current Password'
        },
        newPasswd: {
            label: 'New Password',
            required: 'string',
            minlength: 6
        },
        checkPasswd: {
            label: 'Checking Password',
            required: 'string',
            minlength: 6,
            equal: 'newPasswd'
        }
    }
    const [inputErrors, setInputErrors] = useState({});

    useEffect(()=>{
        if(modalContext.openChangePasswdModal) {
            curPasswdInputRef.current?.focus();
        }
    }, [modalContext.openChangePasswdModal]);

    const closeModal = () => {
        formData.curPasswd = '';
        formData.newPasswd = '';
        formData.checkPasswd = '';
        setInputErrors({});

        modalContext.setOpenChangePasswdModal(false);
    }
    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }
    
    const handleChangePasswd = (e) => {
        e.preventDefault();

        let valid_ret = validateForm(formData, rules);
        if(valid_ret.result === true) {
            setInputErrors({});
            setChangingPasswd(true);

            const data = {
                curPasswd: formData.curPasswd,
                newPasswd: formData.newPasswd
            }
            ApiService.post('/user/auth/changePasswd', data).then(result => {
                toast.success("Password was changed successfully")

                setFormData({
                    curPasswd: '',
                    newPasswd: '',
                    checkPasswd: ''
                })
                
                modalContext.setOpenChangePasswdModal(false);
            })
            .finally(()=>{
                setChangingPasswd(false);
            })
        } else {
            setInputErrors(valid_ret.errors);
        }
    }

    return (
        <MDBModal open={modalContext.openChangePasswdModal} setOpen={modalContext.setOpenChangePasswdModal} tabIndex='-1' >
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader className='d-flex justify-content-center'>
                        <MDBModalTitle>Change Password</MDBModalTitle>
                        <MDBBtn tabIndex='-1' className='btn-close' color='none' onClick={closeModal}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody className='d-flex p-4 flex-column justify-content-center align-items-center'>
                        <form className='d-flex w-80 flex-column pb-3'>
                            <div className=''>
                                <MDBInput   type='password' 
                                            label="Current Password" 
                                            name='curPasswd' 
                                            tabIndex={1}
                                            autoComplete="off"
                                            ref={curPasswdInputRef}
                                            value={formData.curPasswd} 
                                            onChange={handleInputChange} />
                                {inputErrors.curPasswd && <div className='error'>{inputErrors.curPasswd}</div>}
                            </div>
                            <div className='mt-4'>
                                <MDBInput   type='password' 
                                            label="New Password" 
                                            name='newPasswd' 
                                            tabIndex={2}
                                            value={formData.newPasswd} 
                                            autoComplete="off"
                                            onChange={handleInputChange}
                                />
                                {inputErrors.newPasswd && <div className='error'>{inputErrors.newPasswd}</div>}
                            </div>
                            <div className='mt-4'>
                                <MDBInput   type='password' 
                                            label="Check Password" 
                                            name='checkPasswd' 
                                            tabIndex={3}
                                            value={formData.checkPasswd} 
                                            autoComplete="off"
                                            onChange={handleInputChange}
                                />
                                {inputErrors.checkPasswd && <div className='error'>{inputErrors.checkPasswd}</div>}
                            </div>
                            <div className='mt-4 text-right d-flex'>
                                <MDBBtn color="second" 
                                    tabIndex={5}
                                    type="button"
                                    className="w-50 me-2"
                                    onClick={closeModal}
                                    >Cancel</MDBBtn>
                                <MDBBtn 
                                    tabIndex={4}
                                    style={{
                                        backgroundColor: 'rgb(251, 233, 83)',
                                        color: '#111',
                                    }}
                                    className="w-50"
                                    type='submit'
                                    onClick={handleChangePasswd}
                                    disabled={changingPasswd}
                                >
                                    {(() => {
                                        if(changingPasswd) {
                                            return <>
                                                <MDBSpinner size='sm' role='status' tag='span' className='me-2' />
                                                Changing...
                                            </>
                                        } else  {
                                            return <>
                                                Change
                                            </>
                                        }
                                    })()}
                                </MDBBtn>
                            </div>
                        </form>
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
});

export default ChangePasswdModal;
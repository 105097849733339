import React, {useContext, useEffect, useRef, useState} from "react";
import ModalContext from "../../Context/ModalContext";
import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from "mdb-react-ui-kit";
import ChatOffMessage from "../ChatOffMessage/ChatOffMessage";
import { getAvatarUrl } from "../../Utils/utils";

const ChatOffMessageModal = React.memo((props) => {
    const modalContext = useContext(ModalContext);
    const modalData = modalContext.modalData;
    const open = modalContext.openChatOffMessageModal;
    const setOpen = modalContext.setOpenChatOffMessageModal;
    const [availCoupon, setAvailCoupon] = useState();

    const closeModal = () => {
        setOpen(false);
    }

    if(open) {
        return (
            <MDBModal
                open={open}
                setOpen={setOpen}
                tabIndex={-1}
                className="chatoff-message-modal"
                closeOnEsc={false} 
                staticBackdrop
            >
                <MDBModalDialog scrollable>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>
                                <div className="d-flex align-items-center" style={{textTransform: 'none'}}>
                                    <img src={getAvatarUrl(modalData.partner.avatar)} className="round-avatar-40" />
                                    <div className="ms-2">{modalData.partner.username}</div>
                                    {availCoupon && <div className="position-relative ms-4" style={{width:30, height: 30}}>
                                        <div className="coupon">{availCoupon.availMinutes}</div>
                                    </div>}
                                </div>
                            </MDBModalTitle>
                            <MDBBtn tabIndex='-1' className='btn-close' color='none' onClick={closeModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody style={{position:'static', maxHeight: '70vh'}}>
                            <ChatOffMessage 
                                partner={modalData.partner} 
                                thread={modalData.thread} 
                                setAvailCoupon={setAvailCoupon}
                            />
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        )
    } else {

    }

});

export default ChatOffMessageModal;
import React, { useContext, useEffect, useRef, useState} from "react";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle, MDBRow } from "mdb-react-ui-kit";
import { floatV, getAvatarUrl } from "../../Utils/utils";
import ModalContext from "../../Context/ModalContext";
import { destroyPaymentRequest, getPaymentRequest } from "../../Core/Service/JwtService";
import { IsAuthenticated, memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { MIN_CHAT_TIME } from "../../Constants/constants";

const PayAmountModal = React.memo((props)=>{
    const navigate = useNavigate();
    const modalContext = useContext(ModalContext);
    const open = modalContext.openPayAmountModal;
    const setOpen = modalContext.setOpenPayAmountModal;
    const paymentRequest = getPaymentRequest();
    const advisor = paymentRequest?.advisor;
    const chattimes = [5, 10, 20];
    const [selTime, setSelTime] = useState(10);
    const isAuthenticated = useSelector(IsAuthenticated);
    const authUser = useSelector(memoizedGetAuthUser);
    const [payAmount, setPayAmount] = useState(0);

    useEffect(()=>{
        if(authUser && open) {
            setPayAmount(floatV(selTime * advisor.rate_per_min_chat - authUser.balance));
        }
    }, [isAuthenticated, open])

    const closeModal = (e) => {
        destroyPaymentRequest();
        e.preventDefault();
        setOpen(false);
    }

    const onChatTimeSelected = (e, time) => {
        e.preventDefault();
        setSelTime(time);
        setPayAmount(floatV(time * advisor.rate_per_min_chat - authUser.balance));
    }

    const onCheckOutBtnClicked = (e) => {
        e.preventDefault();

        setOpen(false);
        setTimeout(()=>{
            navigate('/client/checkout/' + payAmount);
        },500);
    }

    const renderChatTime = () => {
        return chattimes.map((time, index) => (
            <MDBCol size='4' key={index} className="d-flex justify-content-center">
                <MDBCard className={`chat-duration-card ${time === selTime ? 'selected' : ''}`} onClick={(e) => onChatTimeSelected(e, time)}>
                    <MDBCardBody>
                        <div className="d-flex flex-column justify-content-center align-items-center duration-info">
                            <div className="chat-time">{time}</div>
                            <div className="unit-minute">minutes</div>
                            <div className="money mt-1">${floatV(time * advisor?.rate_per_min_chat)}</div>
                        </div>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        ))
    }

    return <MDBModal className="pay-amount-modal" open={open} setOpen={setOpen} closeOnEsc={false} staticBackdrop tabIndex='-1'>
        <MDBModalDialog>
            <MDBModalContent>
                <MDBModalHeader>
                    <div className="text-center w-100">
                        Your current balance:
                        <span className="current-balance ms-1">${authUser?.balance}</span>
                    </div>
                    <MDBBtn tabIndex='-1' className='btn-close' color='none' onClick={closeModal}></MDBBtn>
                </MDBModalHeader>

                <MDBModalBody className='d-flex p-4 pt-3 flex-column justify-content-center align-items-center modal-body'>
                    <div className="w-100 mt-3">
                        <div className="advisor-info-card d-flex justify-content-center align-items-center">
                            <div>
                                <img src={getAvatarUrl(advisor?.avatar)} className="advisor-avatar"/>
                            </div>
                            <div className="main-info">
                                <div className="username">{advisor?.username}</div>
                                <div className="service-name">{advisor?.service_name}</div>
                                <div className="rate-per-min-chat">${advisor?.rate_per_min_chat} / min</div>
                            </div>
                        </div>

                        <div className="amount-select-card mt-4" >
                            <MDBRow>
                                {renderChatTime()}
                            </MDBRow>
                        </div>
                        <div className="w-100 d-flex justify-content-center mt-4">
                            <MDBBtn className="w-100 check-out-btn" onClick={onCheckOutBtnClicked} >Check Out</MDBBtn>
                        </div>
                    </div>
                </MDBModalBody>
            </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
});

export default PayAmountModal;
import React, { useEffect, useState } from "react";
import AdminTable from "../../../Components/AdminTable/AdminTable";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { Link, useNavigate } from "react-router-dom";
import { MDBBtn, MDBCheckbox, MDBIcon, MDBInput, MDBSwitch } from "mdb-react-ui-kit";
import Paginator from "../../../Components/Paginator/Paginator";
import { getAvatarUrl, text2html } from "../../../Utils/utils";
import Moment from "react-moment";
import Select from "react-select";

const AdminActionList = React.memo((props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [actions, setActions] = useState(null);
    const [search, setSearch] = useState('');
    const [formData, setFormData] = useState({
        search: '',
        perPage: 10,
        pageNum: 1,
    });
    const [total, setTotal] = useState(0);
    const columns = [
        {name: 'createdAt', title: 'Date'},
        {name: 'username', title: 'Username'},
        {name: 'type', title: 'Type'},
        {name: 'comment', title: 'Comment'}
    ];
    const perPageOpts = [
        { value: 10,label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" }
    ];
    const [curPerPageOpt, setCurPerPageOpt] = useState(perPageOpts[0]);

    useEffect(()=>{
        getActions();
    }, [formData]);

    const getActions = () => {
        setLoading(true);

        ApiService.post('/admin/action/index', formData).then(result=>{
            setActions(result.data.actions);
            setTotal(result.data.total);
        })
        .finally(()=>{
            setLoading(false);
        })
    }
    const onSearchChange = (e) => {
        setSearch(e.target.value);
    }
    const onSearchBtnClicked = (e) => {
        e.preventDefault();
        setFormData({
            ...formData,
            search,
            pageNum: 1
        })
    }
    const onSearchKeyPress = (e) => {
        if(e.key == 'Enter') {
            e.preventDefault();
            setFormData({
                ...formData,
                pageNum: 1,
                search
            });
        }
    }
    const onPageChanged = (pageNum) => {
        setFormData({
            ...formData,
            pageNum
        })
    }
    const onPerPageChanged = (opt) => {
        setFormData({
            ...formData,
            perPage: opt.value,
            pageNum: 1
        })
        setCurPerPageOpt(opt);
    }

    const breadcrumb=[{title: 'Action'}, {title: 'List'}];
    return (<>
        <Breadcrumb breadcrumb={breadcrumb} />
        <div className="clients-table">
            <div className="d-flex">
                <div className="px-2">
                    <label>Count : </label>
                    <span>{total}</span>
                </div>
                <div className="flex-fluid d-flex justify-content-end mb-2">
                    <div className="me-2">
                        <Select className="per-page"
                                    options={perPageOpts} 
                                    onChange={(opt) => onPerPageChanged(opt)} 
                                    defaultValue={curPerPageOpt}
                        />
                    </div>
                    <div className="d-flex align-items-center">
                        <MDBInput   type='text' 
                                    label='Search' 
                                    value={search}
                                    onChange={onSearchChange}
                                    onKeyPress={onSearchKeyPress}
                        />
                        <span className='mx-2 cursor-pointer' onClick={onSearchBtnClicked}>
                            <MDBIcon fas icon='search' />
                        </span>
                    </div>
                </div>
            </div>

            <AdminTable columns={columns} rows={actions} style={{minWidth: 800}}>
                {(row, colname, rowIndex, colIndex) => {
                    switch(colname) {
                        case 'createdAt':
                            return <Moment format="YYYY-MM-DD HH:mm">{row['createdAt']}</Moment>
                        case 'comment':
                            return <div dangerouslySetInnerHTML={{ __html: text2html(row.comment)}}></div>
                    }
                    return null;
                }}
            </AdminTable>

            <div className='d-flex justify-content-center mt-4'>
                <Paginator total={total} perPage={formData.perPage} onPageChanged={onPageChanged} />
            </div>
            {loading && <LoadingIndicator />}
        </div>
        </>
    )
});

export default AdminActionList;

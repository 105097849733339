import React, { useEffect, useState } from "react";
import Select from "react-select";
import AdminTable from "../../../Components/AdminTable/AdminTable";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { Link } from "react-router-dom";
import { MDBCheckbox, MDBIcon, MDBInput, MDBSwitch } from "mdb-react-ui-kit";
import Paginator from "../../../Components/Paginator/Paginator";
import { TransactionType, UserStatus } from "../../../Constants/constants";
import { toast } from "react-toastify";
import Moment from "react-moment";
import styles from './Transaction.scss';

const AdminPaymentList = React.memo((props) => {
    const perPageOpts = [
        { value: 10,label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" }
    ];
    const [curPerPageOpt, setCurPerPageOpt] = useState(perPageOpts[0]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [total, setTotal] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [formData, setFormData] = useState({
        search: '',
        perPage: 10,
        pageNum: 1,
        transaction_type: TransactionType.CHARGE
    })

    useEffect(()=>{
        getTransactions();
    }, [formData]);

    const getTransactions = () => {
        setLoading(true);

        ApiService.post('/admin/transaction/index', formData).then(result=>{
            setTotal(result.data.total);
            setTransactions(result.data.transactions);
        })
        .catch(error=>{

        })
        .finally(()=>{
            setLoading(false);
        })
    }

    const onSearchKeyDown = (e) => {
        if(e.key == 'Enter') {
            e.preventDefault();
            setFormData({
                ...formData,
                pageNum: 1,
                search
            });
        }
    }
    const onPageChanged = (pageNum) => {
        setFormData({
            ...formData,
            pageNum
        })
    }
    const onPerPageChanged = (opt) => {
        setFormData({
            ...formData,
            perPage: opt.value,
            pageNum: 1
        })
        setCurPerPageOpt(opt);
    }
    const onSearchBtnClicked = (e) => {
        getTransactions();
    }

    const columns = [
        {name: 'createdAt', title: 'Date'},
        {name: 'username', title: 'Username'},
        {name: 'email', title: 'Email'},
        {name: 'amount', title: 'Amount'},
        {name: 'payment_id', title: 'Payment_Id(Order_Id)'},
        {name: 'payment_method', title: 'Payment Method'},
        {name: 'payment_succeedAt', title: 'Completed At'},
        {name: 'status', title: 'Status'}
    ]
    const breadcrumb=[{title: 'Transaction'}, {title: 'Payments'}];
    return (<>
        <Breadcrumb breadcrumb={breadcrumb} />
        <div className="admin-transaction-list">
            <div className="d-flex">
                <div className="px-2">
                    <label>Count : </label>
                    <span>{total}</span>
                </div>
                <div className="flex-fluid d-flex justify-content-end align-items-center">
                    <div>
                        <Select className="per-page me-2"
                                    options={perPageOpts} 
                                    onChange={(opt) => onPerPageChanged(opt)} 
                                    defaultValue={curPerPageOpt}
                        />
                    </div>
                    <div className="d-flex align-items-center">
                        <MDBInput   label='Search' 
                                    value={search} 
                                    onKeyDown={onSearchKeyDown}
                                    onChange={(e)=>setSearch(e.target.value)} 
                                    />
                        <div className="ms-1 cursor-pointer" onClick={onSearchBtnClicked}>
                            <MDBIcon fas icon='search' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="admin-payments-table mt-2">
                <AdminTable columns={columns} rows={transactions}>
                    {(row, colname, rowIndex, colIndex) => {
                        switch(colname) {
                            case 'createdAt':
                                return <Moment format="YYYY-MM-DD hh:mm">{row[colname]}</Moment>
                            case 'charge_succeedAt':
                                if(row[colname]) return <Moment format="YYYY-MM-DD">{row[colname]}</Moment>
                                else return <></>
                            case 'payment_method':
                                return (
                                    <div className="d-flex align-items-center">
                                        <MDBIcon fab icon='paypal' size="lg" color="primary" />
                                        <span className="ms-2">{row['payment_method']}</span>
                                    </div>
                                )
                            case 'payment_succeedAt':
                                return (
                                    <Moment format="YYYY-MM-DD YY-mm">{row['payment_succeedAt']}</Moment>
                                )
                            case 'amount':
                                return <div>$ {row[colname]}</div>
                            case 'status':
                                return <div className={`status ${row[colname]}`}>{row[colname]}</div>
                        }
                    }}
                </AdminTable>

                <div className='d-flex justify-content-center mt-4'>
                    <Paginator total={total} perPage={formData.perPage} onPageChanged={onPageChanged} />
                </div>

                {loading && <LoadingIndicator />}
            </div>
        </div>
    </>)
})

export default AdminPaymentList;
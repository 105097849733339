import React, { useEffect, useState } from "react";
import styles from './Payout.scss'
import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import AdvisorTransactions from "./MyTransactions";
import { useNavigate } from "react-router-dom";
import AdvisorRevenue from "./MyRevenue";
import AdvisorPayoutMethod from "./MyPayoutMethod";
import { useSelector } from "react-redux";
import { getAuthUser } from "../../../Store/Reducer/authReducer";
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";

const AdvisorPayout = React.memo(props=>{
    const navigate = useNavigate();
    const authUser = useSelector(getAuthUser);
    const [advisorDetail, setAdvisorDetail] = useState();
    const [loading, setLoading] = useState(false);

    const getAdvisorDetail = () => {
        setLoading(true);

        ApiService.get(`/advisor/auth/${authUser._id}`).then(response=>{
            setAdvisorDetail(response.data.advisor);
        })
        .finally(()=>{
            setLoading(false);
        })
    }

    const goBack = (e) => {
        e.preventDefault();
        navigate(-1);
    }

    useEffect(()=>{
        if(authUser) {
            getAdvisorDetail();
        }
    }, [authUser]);

    return <MDBContainer breakpoint="lg" className="advisor-payment mt-2 p-4">
        <AdvisorPayoutMethod />
        <AdvisorRevenue advisorDetail={advisorDetail} />
        <AdvisorTransactions />

        <div className="d-flex justify-content-end mt-4">
            <MDBBtn onClick={goBack} color="second">Back</MDBBtn>
        </div>

        {loading && <LoadingIndicator />}
    </MDBContainer>
})

export default AdvisorPayout;
import React, { useEffect, useRef } from "react";
import { useContext } from "react";
import ModalContext from "../../Context/ModalContext";
import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle } from "mdb-react-ui-kit";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import EventContext from "../../Context/EventContext";
import { useState } from "react";
import { toast } from "react-toastify";
import { TO_RADIANS } from "../../Constants/constants";

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth,mediaHeight,aspect) 
{
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

const canvasPreview = (image, canvas, crop, scale=1, rotate=0) => {
    const ctx = canvas.getContext('2d');
    if(!ctx) {
        throw new Error('No 2d context');
    }

    // const scaleX = image.naturalWidth / image.width;
    // const scaleY = image.naturalHeight / image.height;
    const scaleX = 1;
    const scaleY = 1;

    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio;
    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';

    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY
  
    const rotateRads = rotate * TO_RADIANS;
    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY)
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY)
    // 3) Rotate around the origin
    ctx.rotate(rotateRads)
    // 2) Scale the image
    ctx.scale(scale, scale)
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY)

    ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.width,
        image.height,
    )
    
    ctx.restore()
}

const CropImageModal = React.memo(props=>{
    const modalContext = useContext(ModalContext);
    const open = modalContext.openCropImageModal;
    const setOpen = modalContext.setOpenCropImageModal;
    const modalData = modalContext.modalData;
    const imgSrc = modalData?.imgSrc;

    const eventContext = useContext(EventContext);

    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();
    const [aspect, setAspect] = useState(1);
    const [scale, setScale] = useState(1);

    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);

    useEffect(()=>{
        if(completedCrop) setTimeout(drawPreview, 100)
    }, [completedCrop])

    useEffect(()=>{
        if(open) {
            setTimeout(drawPreview, 200);
        }
    }, [open]);

    const drawPreview = () => {
        if( completedCrop?.width && completedCrop?.height && 
            imgRef.current && 
            previewCanvasRef.current
        ) {
            canvasPreview(
                imgRef.current,
                previewCanvasRef.current,
                completedCrop,
                scale
            )
        }
    }

    const onImageLoad = (e) => {
        if(aspect) {
            const {width, height} = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    }
    const closeModal = () => {
        setOpen(false);
    }
    const applyCrop = () => {
        const ratio = completedCrop.height / completedCrop.width;
        if(ratio > 1.1 || ratio < 0.9) {
            toast.warning('Please select image as square');
            return;
        }

        const cropedImageUrl = previewCanvasRef.current.toDataURL('image/jpeg');
        eventContext.setEvtCompleteCropImage({
            time: new Date(),
            cropedImageUrl
        })

        setOpen(false);
    }

    if(open) {
        return (
        <MDBModal className="crop-image-modal"
            open={open} 
            setOpen={setOpen}
            closeOnEsc={false}
            staticBackdrop 
            tabIndex='-1'
        >
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>Crop Image</MDBModalTitle>
                        <MDBBtn tabIndex='-1' className='btn-close' color='none' onClick={closeModal}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <div className="d-flex flex-column align-items-center">
                            <div className="">
                                {!!imgSrc &&
                                    <ReactCrop
                                        crop={crop}                     
                                        onChange={setCrop}
                                        aspect={aspect}
                                        onComplete={setCompletedCrop}
                                    >
                                        <img src={imgSrc} onLoad={onImageLoad} ref={imgRef} />
                                    </ReactCrop>
                                }
                            </div>
                            {!!completedCrop && 
                                <div className="preview d-flex flex-column align-items-center">
                                    <canvas
                                        ref={previewCanvasRef}
                                        style={{
                                            border: '1px solid gray',
                                            borderRadius: '50%',
                                            objectFit: 'contain',
                                            width: 100,
                                            height: 100
                                        }}
                                    />
                                    <div>Preview</div>
                                </div>
                            }
                        </div>
                        <div className="mt-4 d-flex justify-content-center">
                            <MDBBtn color="second" onClick={closeModal}>Cancel</MDBBtn>
                            <MDBBtn className="ms-2 confideas-primary-btn" onClick={applyCrop}>Apply</MDBBtn> 
                        </div>
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
        )
    } else {
        return <></>
    }
});

export default CropImageModal;
import React, { useEffect, useRef, useState} from "react";
import { toast } from 'react-toastify';
import { 
    MDBModal,
    MDBModalDialog,
    MDBModalBody,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBBtn,
    MDBModalFooter,
    MDBInput,
    MDBTextArea,
    MDBSpinner,
} from "mdb-react-ui-kit";
import { validateForm } from "../../../Utils/utils";
import ApiService from "../../../Core/Service/ApiService";

const VerifyItemEditModal = React.memo((props) => {
    const [verifyItemInEditing, setVerifyItemInEditing] = useState();
    const {open, setOpen, callback} = props;
    const [formData, setFormData] = useState({
        label: '',
        comment: ''
    })
    const rules = {
        label: {
            required: 'string'
        }
    }
    const [inputErrors, setInputErrors] = useState({});
    const [saving, setSaving] = useState(false);

    useEffect(()=>{
        if(props.verifyItemInEditing) {
            setFormData({
                ...props.verifyItemInEditing
            });
        } else {
            setFormData({
                label: '',
                comment: ''
            })
        }
    }, [props.verifyItemInEditing]);

    const handleInputChanged = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const closeModal = (e) => {
        setOpen(false);
    }

    const handleSave = (e) => {
        let valid_ret = validateForm(formData, rules);
        if(valid_ret.result === true) {
            setInputErrors({});
            setSaving(true);
            ApiService.post('/admin/verifyitem/upsert', formData).then(response=>{
                callback && callback();
                setOpen(false);
            })
            .finally(()=>{
                setSaving(false);
            })
        } else {
            setInputErrors(valid_ret.errors);
        }
    }

    if(open) {
        return (
            <MDBModal
                className="decline-advisor-modal"
                open={open}
                setOpen={setOpen} 
                closeOnEsc={false}
                staticBackdrop 
                tabIndex='-1'
                appendToBody={true}
            >
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>
                                {props.verifyItemInEditing ? 'Edit ' : 'Add '} VerifyItem
                            </MDBModalTitle>
                            <MDBBtn tabIndex='-1' className='btn-close' color='none' onClick={closeModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div>
                                <label>Label:</label>
                                <MDBInput
                                    type="text"
                                    name='label'
                                    value={formData.label}
                                    onChange={handleInputChanged}
                                    tabIndex={1}
                                />
                            </div>
                            <div className="mt-2">
                                <label>Comment: </label>
                                <MDBTextArea
                                    name='comment'
                                    value={formData.comment}
                                    onChange={handleInputChanged}
                                    tabIndex={2}
                                    rows={4}
                                />
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="default" onClick={closeModal}>Cancel</MDBBtn>
                            <MDBBtn className="confideas-primary-btn ms-2 d-flex align-items-center" onClick={handleSave}>
                                {saving && <MDBSpinner size="sm me-1" />}
                                Save
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        )
    } else {
        return <></>
    }
});

export default VerifyItemEditModal;
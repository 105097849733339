import React, {useState, useRef, useEffect} from 'react';
import { 
    MDBContainer, 
    MDBNavbar, 
    MDBNavbarBrand, 
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBIcon
 } from 'mdb-react-ui-kit';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ApiService from '../../Core/Service/ApiService';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { purgeAuth, IsAuthenticated, getAdvisorServiceStatus, memoizedGetAuthUser } from '../../Store/Reducer/authReducer';
import { getAvatarUrl } from '../../Utils/utils';
import { UserRole } from '../../Constants/constants';
import ModalContext from '../../Context/ModalContext';
import { useContext } from 'react';
import { removeAlarms } from '../../Store/Reducer/alarmReducer';
import { removeChangedAdvisors } from '../../Store/Reducer/changedAdvisorReducer';
import SVG from 'react-inlinesvg';
import AdminContext from '../../Context/AdminContext';

const AdminTopBar = React.memo(() => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(IsAuthenticated);
    const authUser = useSelector(memoizedGetAuthUser);
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const [authUserAvatar, setAuthUserAvatar] = useState('');
    const modalContext = useContext(ModalContext);
    const adminContext = useContext(AdminContext);

    useEffect(()=>{
        if(authUser?.avatar) {
            setAuthUserAvatar(authUser.avatar);
        }
    }, [authUser?.avatar]);

    // log out
    const handleLogout = (e) => {
        e.preventDefault();

        if(!authUser) return;

        ApiService.get('/admin/auth/logout');
        dispatch(purgeAuth());
        dispatch(removeChangedAdvisors());
        dispatch(removeAlarms());
        
        navigate('/');
    }

    const onChangePassword = (e) => {
        e.preventDefault();
        modalContext.setOpenChangePasswdModal(true);
    }

    const gotoHomepage = () => {
        navigate('/');
    }

    const goBack = () => {
        navigate(-1);
    }

    return (
        <section className='topbar-container w-100 p-2'>
            <div className='d-flex align-items-center'>
                <div className='flex-fluid pe-2 d-flex align-items-center px-2'>
                    <MDBBtn tag='a' color='none' onClick={goBack} className='me-2'>
                        <MDBIcon fas icon="chevron-left" size="lg" style={{color: 'white'}} />
                    </MDBBtn>
                    <MDBBtn tag='a' color='none' onClick={(e)=>adminContext.setSidebarCollapsed(false)}>
                        <MDBIcon fas icon="bars" className='ms-2' style={{color: 'white', fontSize: '25px'}} />
                    </MDBBtn>
                </div>
                <div className=''>
                    <MDBDropdown>
                        <MDBDropdownToggle className='bg-transparent authuser-action' style={{boxShadow:'none'}}>
                            <img src={getAvatarUrl(authUserAvatar)} className='round-avatar-40' alt=''/>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu>
                            <MDBDropdownItem link onClick={gotoHomepage}>Go to Homepage</MDBDropdownItem>
                            <MDBDropdownItem link onClick={onChangePassword}>Change Password</MDBDropdownItem>
                            <MDBDropdownItem link onClick={handleLogout}>Log Out</MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                </div>
            </div>
        </section>
    )
});

export default AdminTopBar;


import { MDBContainer } from "mdb-react-ui-kit";
import React, {useContext, useEffect, useState} from "react";
import ModalContext from "../../Context/ModalContext";
import SVG from 'react-inlinesvg';

const ServiceMaintain = () => {
    const modalContext = useContext(ModalContext);

    useEffect(()=>{
        modalContext.hideAllModals();
    }, []);

    return (<MDBContainer breakpoint="lg" className="p-4 d-flex justify-content-center">
        <div className="d-flex flex-column align-items-center">
            <div className="mt-4">
                <img className="w-100" src='/img/others/service_maintain.png' style={{maxWidth: 400}}/>
            </div>
            <div className="mt-4" style={{fontSize: '150%', textAlign:'center'}}>
                <div className="d-flex align-items-center">
                    We are updating service now.
                </div>
                <div>We will get ready soon.</div>
            </div>
        </div>
    </MDBContainer>)
}

export default ServiceMaintain;
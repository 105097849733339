import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { memoizedGetChat } from '../../Store/Reducer/chatReducer'
import { ChatStatus } from '../../Constants/constants'
import {IsChatSocketConnected} from '../../Store/Reducer/socketReducer';
import DeviceContext from '../../Context/DeviceContext'
import ChatContext from './ChatContext'
import ModalContext from '../../Context/ModalContext'

let chatInputFocus = false;
const MAX_CHAT_BOX_HEIGHT = 100

const ChatInput = React.memo((props) => {
    const inputRef = useRef(null)
    const scrollRef = useRef(null)
    const chat = useSelector(memoizedGetChat);
    const isChatSockConnected = useSelector(IsChatSocketConnected);
    const deviceContext = useContext(DeviceContext);
    const modalContext = useContext(ModalContext);
    const chatContext = useContext(ChatContext);
    const [isOnFocus, setIsOnFocus] = useState(false);
    const [html, setHtml] = useState('')
    const [isChattable, setIsChattable] = useState(false);
    
    useEffect(()=>{
        setIsOnFocus((!chatContext.openChatEndModal && !chatContext.openLackBalanceModal && !modalContext.openAdvisorNoteModal) ? true : false);
    }, [chatContext.openChatEndModal, chatContext.openLackBalanceModal, modalContext.openAdvisorNoteModal])

    useEffect(()=>{
        setIsChattable(chat?.isChatting == true);
    }, [chat?.isChatting])

    const updateInputHeight = () => {
        if (inputRef.current && scrollRef.current) {
            scrollRef.current.style.overflow = 'hidden'
            scrollRef.current.style.height = `${inputRef.current.scrollHeight}px`
            if (inputRef.current.scrollHeight > MAX_CHAT_BOX_HEIGHT) {
                scrollRef.current.style.overflowY = 'scroll'
                scrollRef.current.style.height = `${MAX_CHAT_BOX_HEIGHT}px`
            }
        }
    }

    useEffect(() => {
        // reset input box
        if (inputRef.current && html !== inputRef.current.innerHTML) {
            inputRef.current.innerHTML = html
        }

        updateInputHeight();
    }, [html])

    const handleChange = (e) => {
        if(!isChattable) return;

        const { innerHTML, textContent } = e.currentTarget
        setHtml(innerHTML ?? '')
    }

    const handleKeyDown = (e) => {
        if(!isChattable) {
            e.preventDefault();
            return;
        }

        if ( (deviceContext.isMobile && (e.key === 'Enter' && e.shiftKey)) || 
             (!deviceContext.isMobile && (e.key === 'Enter' && !e.shiftKey) )
        ) {
            e.preventDefault()
            if(isChatSockConnected) {
                chatContext.sendMessage(html);
                setHtml('');
            }
            return;
        }

        props.onKeyDown && props.onKeyDown(e);    
    }

    const onChatInputBlur = () => {
        chatInputFocus = false;
        // when user click 'send' button, the message_input would lose focus.
        // But we want to keep message_input control focus when user sending message, so here we trigger timeout.
        setTimeout(() => {
            (chatInputFocus === false) && chatContext.setChatInputFocus(false);
        }, 200);

        if( chat?.isChatting && isOnFocus && !deviceContext.isMobile) {
            inputRef.current?.focus();
        }
    }
    const onChatInputFocus = () => {
        chatInputFocus = true;
        setTimeout(()=>{
            (chatInputFocus === true) && chatContext.setChatInputFocus(true);
        }, 100)
    }

    const onSendBtnClick = (e) => {
        e.preventDefault();

        if(!isChatSockConnected || chat?.isChatting != true) return;

        chatContext.sendMessage && chatContext.sendMessage(html);
        setHtml('');

        inputRef.current?.focus();
    }
    return (
        <div className="chat-input-container d-flex" style={{bottom: chatContext.inputBarBottom}}>
            <div className="flex-fluid">
                <div ref={scrollRef} className={`scrollbar`}>
                    <div
                        ref={inputRef}
                        className="inputContentEditable"
                        contentEditable
                        role="textbox"
                        aria-label="Message..."
                        onInput={handleChange}
                        onKeyDown={handleKeyDown}
                        onBlur={onChatInputBlur}
                        onFocus={onChatInputFocus}
                    />
                </div>
            </div>
            <div className="d-flex align-items-end mx-2">
                <MDBBtn className="rounded-circle send-btn cursor-pointer d-flex align-items-center justify-content-center" 
                    disabled={html.length == 0 || !isChattable}
                    onClick={onSendBtnClick}>
                    <MDBIcon icon='paper-plane' size='md'/>
                </MDBBtn>
            </div>
        </div>
    )
})

export default ChatInput

import React, { useState } from 'react'
import styles from './Message.scss'
import { MDBContainer, MDBTabs, MDBTabsContent, MDBTabsLink, MDBTabsPane } from 'mdb-react-ui-kit';
import ClientAllCoupons from './AllCoupons';
import ClientAllBuzzes from './AllBuzzes';

const ClientMessage = React.memo(props => {
    const [activeTab, setActiveTab] = useState('inbox');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    }

    return <MDBContainer className='py-4' breakpoint='lg'>
        <MDBTabs className='cursor-pointer'>
            <MDBTabsLink onClick={() => handleTabClick('inbox')} active={activeTab === 'inbox'}>
                Inbox
            </MDBTabsLink>
            <MDBTabsLink onClick={()=>handleTabClick('outbox')} active={activeTab == 'outbox'}>
                Outbox
            </MDBTabsLink>
        </MDBTabs>

        <MDBTabsContent>
            <MDBTabsPane open={activeTab === 'inbox'}>
                <ClientAllCoupons />            
            </MDBTabsPane>
            <MDBTabsPane open={activeTab === 'outbox'}>
                <ClientAllBuzzes />
            </MDBTabsPane>
        </MDBTabsContent>
    </MDBContainer>
});

export default ClientMessage;
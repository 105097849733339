import React from "react";
import SVG from 'react-inlinesvg';
import styles from './SearchBar.scss'
import { MDBPopover, MDBPopoverBody } from "mdb-react-ui-kit";

const SearchBar = React.memo(props=>{
    const {children} = props;
    const searchTermChildren = React.Children.map(children, child => {
        if (child?.props.className && child?.props.className.split(' ').includes('search-term')) {
            return child;
        }
        return null;
    });
    const otherChildren  = React.Children.map(children, child => {
        if (child?.props.className && !child?.props.className.split(' ').includes('search-term')) {
            return child;
        }
        return null;
    });

    if(searchTermChildren?.length + otherChildren?.length < 2) {
        return (
        <div className="flex-fluid d-flex justify-content-end align-items-center search-bar">
            {children}
        </div>)      
    } else {
        return <>
            <div className="flex-fluid d-none d-md-flex justify-content-end align-items-center search-bar">
                {children}
            </div>

            <div className="flex-fluid d-flex d-md-none justify-content-end align-items-center search-bar">
                {searchTermChildren?.length > 0 &&
                    <div className="d-flex">
                        {searchTermChildren}
                    </div>
                }

                {otherChildren?.length > 0 &&
                <div className="ms-2">
                    <MDBPopover 
                        color="default"
                        style={{paddingLeft: 5, paddingRight: 5, paddingTop: 5, paddingBottom: 5}}
                        placement="bottom" 
                        dismiss
                        btnChildren={
                            <SVG src='/img/icons/three.svg' 
                                height={22} 
                                fill="#333"
                            />
                        }
                    >
                        <MDBPopoverBody>
                            <div className="popup-searchbar">{otherChildren}</div>
                        </MDBPopoverBody>
                    </MDBPopover>
                </div>
                }
            </div>
        </>
    }
});

export default SearchBar;
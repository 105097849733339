import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IsAuthenticated, memoizedGetAuthUser, updateAuth } from '../../../Store/Reducer/authReducer';
import ApiService from '../../../Core/Service/ApiService';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBContainer, MDBInput, MDBProgress, MDBProgressBar, MDBRow, MDBSpinner, MDBTextArea } from 'mdb-react-ui-kit';
import LoadingIndicator from '../../../Components/LoadingIndicator/LoadingIndicator';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter, floatV, getAvatarUrl, getBackendAssetUrl, validateForm } from '../../../Utils/utils';
import ImageSelector from '../../../Components/ImageSelector/ImageSelector';
import VideoSelector from '../../../Components/VideoSelector/VideoSelector';

const AdvisorListingEdit = React.memo((props) => {
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const isAuthenticated = useSelector(IsAuthenticated);
    const authUser = useSelector(memoizedGetAuthUser);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({});
    const [advisorService, setAdvisorService] = useState();
    const [rules, setRules] = useState({
        avatar: {required: 'file'},
        aboutme: {required: 'string'},
        service_name: { required: 'string', maxlength: 30 },
        service_description: { required: 'string' },
    });
    const [inputErrors, setInputErrors] = useState({});
    const [uploadProgress, setUploadProgress] = useState(0);

    useEffect(()=>{
        (async () => {
            if(isAuthenticated && authUser) {
                setLoading(true);
                try {
                    let result = await ApiService.get('/advisor/auth/' + authUser._id);
                    setAdvisorService(result.data.advisor);
                    setFormData(result.data.advisor.mirror);
                } finally {
                    setLoading(false);
                }
            }
        })()
    }, [isAuthenticated])

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }
    const onPhotoSelected = (image, file) => {
        if(image.width < 200 || image.height < 200) {
            toast.error('Image size(width, height) should be larger than 200px');
            return false;
        }
        setFormData({
            ...formData,
            new_avatar: file
        })

        return true;
    }
    const onVideoSelected = (video) => {
        setFormData({
            ...formData,
            new_video: video
        })        
    }
    const onSave = (e) => {
        e.preventDefault();

        const valid_ret = validateForm(formData, rules);
        if(valid_ret.result) {
            (async () => {
                try {
                    setInputErrors({});
                    setSaving(true);

                    // To update advisor's listing information.
                    const postData = new FormData();
                    Object.keys(formData).forEach(k=>{
                        postData.append(k, formData[k]);
                    })
                    const headers = {'Content-Type': 'multipart/form-data'};
                    let result = await ApiService.post('/advisor/auth/' + authUser._id, postData, headers, onUploadProgress);
                    setFormData(result.data.advisor.mirror);

                    toast.success(result.data.message);
                } finally {
                    setSaving(false);
                }
            })()
        } else {
            setInputErrors(valid_ret.errors);
        }
    }
    const onUploadProgress = (evt) => {
        setUploadProgress(parseInt(evt.loaded * 100 / evt.total));
    }

    const goBack = () => {
        navigate(-1);
    }

    return <MDBContainer breakpoint="lg" className="advisor-edit-listing px-4">
        <div className='d-flex'>
            <div>
                <div className={`listing-status  ${advisorService?.listing ? 'show' : 'hide'}`}>
                    {advisorService?.listing ? 'Listing' : 'Not Listed'}
                </div>
            </div>
            <div className='flex-fluid d-flex justify-content-end'>
                <div className={`profile-status mb-4 ${formData?.pending_status ?? ''}`}>
                    {capitalizeFirstLetter(formData?.pending_status ? (formData.pending_status == 'waiting' ? 'pending' : formData.pending_status) :  '')}
                </div>
            </div>
        </div>

        <div className="advisor-skill-service-form">
            <div className="d-sm-flex main-info">
                <div className="avatar d-flex flex-column justify-content-center align-items-center me-4">
                    <div className="label mb-2">
                        My Photo: 
                    </div>
                    <ImageSelector  
                        cropImage={true}
                        defaultSrc={ getAvatarUrl(formData?.avatar) }
                        onImageSelected={onPhotoSelected} 
                    />
                    {inputErrors.avatar && <div className="error text-center">{ inputErrors.avatar }</div>}
                </div>
                <div className="stage-service-name">
                    <div className="stage-name w-100">
                        <div className='label mb-1'>Stage Name:</div>
                        <MDBInput   className="form-control"
                                    name='username'
                                    value={formData?.username ?? ''}
                                    readonly={true}
                        />
                    </div>
                    <div className="servicename mt-2 w-100">
                        <span className="label required ">Service Name: </span>
                        <MDBInput   className="form-control"
                                    name='service_name'
                                    value={formData?.service_name ?? ''}
                                    maxLength={30}
                                    tabIndex={3}
                                    onChange={handleInputChange} />
                        {inputErrors.service_name && <div className="error">{ inputErrors.service_name }</div>}
                    </div>
                </div>
            </div>

            <div className="aboutme mt-4">
                <span className="label required">About Me: </span>
                <MDBTextArea    
                    className="form-control" 
                    value={formData?.aboutme ?? ''}
                    name='aboutme' 
                    tabIndex={5}
                    rows={4} 
                    onChange={handleInputChange} 
                />
                {inputErrors.aboutme && <div className="error">{ inputErrors.aboutme }</div>}
            </div>

            <div className="serviceDesc mt-4">
                <span className="label required">Service Offered: </span>
                <MDBTextArea
                    className="form-control"
                    name='service_description'
                    value={formData?.service_description ?? ''}
                    tabIndex={6}
                    rows={6}
                    onChange={handleInputChange} 
                />
                {inputErrors.service_description && <div className="error">{ inputErrors.service_description }</div>}
            </div>

            <div className='video mt-4'>
                <div className="label">Introduction Video: </div>
                <MDBCard className='h-100 w-100 mt-1'>
                    <MDBCardBody className='d-flex justify-content-center align-items-center'>
                        <VideoSelector defaultSrc={getBackendAssetUrl(formData.video)} onVideoSelected={onVideoSelected} />
                    </MDBCardBody>
                </MDBCard>
            </div>
        </div>

        {saving && (
            <div className='w-100 mt-4'>
                <MDBProgress height={20} style={{borderRadius:5}}>
                    <MDBProgressBar bgColor='success' width={uploadProgress} valuemin={0} valuemax={100}>
                        {uploadProgress}%
                    </MDBProgressBar>
                </MDBProgress>
            </div>
        )}

        <div className='mt-4 px-2 d-flex justify-content-end'>
            <MDBBtn onClick={goBack} color='default'>Back</MDBBtn>
            <MDBBtn className="ms-4 confideas-primary-btn" tabIndex={-1} onClick={onSave}>
                {saving && <MDBSpinner size='sm'/>}
                Save
            </MDBBtn>
        </div>
        { loading && <LoadingIndicator/> }
    </MDBContainer>
})

export default AdvisorListingEdit;
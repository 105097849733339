import React, {useState, useEffect, useContext, useMemo} from "react";
import ReactHowler from 'react-howler';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { addDate, validateForm } from '../../Utils/utils';
import ApiService from "../../Core/Service/ApiService";
import Select from "react-select";
import { 
    MDBModal,
    MDBModalDialog,
    MDBModalBody,
    MDBModalContent,
    MDBInput,
    MDBBtn,
    MDBModalHeader,
    MDBModalTitle,
    MDBSpinner,
} from "mdb-react-ui-kit";
import ModalContext from "../../Context/ModalContext";
import TextAreaAutoHeight from "../TextAreaAutoHeight/TextAreaAutoHeight";

const CreditBackModal = React.memo((props) => {
    const modalContext = useContext(ModalContext);
    const open = modalContext.openCreditBackModal;
    const setOpen = modalContext.setOpenCreditBackModal;
    const modalData = modalContext.modalData;
    const [sending, setSending] = useState(false);
    const [inputErrors, setInputErrors] = useState({});
    const [formData, setFormData] = useState({
        uId: '',
        amount: 0,
        comment: ''
    });
    const rules = {
        amount: {
            required: 'number'
        },
        comment: {
            required: 'string'
        }
    }

    useEffect(()=>{
        if(modalData && open) {
            setFormData({
                uId: modalData.user._id,
                amount: 0,
                comment: ''
            })
            setInputErrors({});
        }
    }, [modalData, open])

    const closeModal = () => {
        setOpen(false);
    }

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleCreditBack = (e) => {
        e.preventDefault();
        
        const valid_ret = validateForm(formData, rules);
        if(valid_ret.result) {
            setInputErrors({});
            setSending(true);

            ApiService.post('/admin/transaction/creditback', formData).then(result => {
                toast.success("Operation success");
                closeModal();
            })
            .finally(()=>{
                setSending(false);
            });
        } else {
            setInputErrors(valid_ret.errors);
        }
    }

    if(open) {
        return (
            <MDBModal   className="credit-back-modal" 
                        open={open} 
                        setOpen={setOpen} 
                        closeOnEsc={false}
                        staticBackdrop 
                        tabIndex='-1' >
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader className="d-flex w-100">
                            <MDBModalTitle className="flex-fluid d-flex justify-content-center align-items-center">Credit Back</MDBModalTitle>
                            <MDBBtn tabIndex='-1' className='btn-close' color='none' onClick={closeModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody className='d-flex p-4 flex-column justify-content-center align-items-center'>
                            <div className="w-100">
                                <label>User : </label>
                                <div className="value"><MDBInput value={modalData?.user.username ?? ''} readOnly={true} /></div>
                            </div>
                            <div className="free-minutes mt-3 w-100">
                                <label>Amount ($): </label>
                                <div className="value">
                                    <MDBInput type="number" 
                                            value={formData.amount} 
                                            name="amount" 
                                            onChange={handleInputChange} 
                                            tabIndex={1}
                                    />
                                    {inputErrors.amount && <div className="error">{ inputErrors.amount }</div>}
                                </div>
                            </div>
                            <div className="message mt-3 w-100">
                                <label>Comment : </label>
                                <div className="value">
                                    <TextAreaAutoHeight 
                                            className='form-control'
                                            name='comment'
                                            value={formData.comment}
                                            onChange={handleInputChange}
                                            tabIndex={2}
                                    />
                                    {inputErrors.comment && <div className="error">{ inputErrors.comment }</div>}
                                </div>
                            </div>
                            <div className="action d-flex justify-content-end align-items-center mt-4 ">
                                <MDBBtn color="second" onClick={closeModal} disabled={sending}>Cancel</MDBBtn>
                                <MDBBtn className="ms-2" color="primary" onClick={handleCreditBack} disabled={sending}>
                                    {sending && <>
                                        <MDBSpinner size='sm' role='status' tag='span' className='me-2' />
                                        Sending
                                    </>}
                                    {!sending && <span>Send</span>}
                                </MDBBtn>
                            </div>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        )
    } else {
        return <></>
    }
});

export default CreditBackModal;
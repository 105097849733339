import React, {useState, useEffect, useContext, useMemo, useRef} from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { memoizedGetAuthUser, setAuth } from '../../Store/Reducer/authReducer';
import { getAvatarUrl, validateForm } from '../../Utils/utils';
import ApiService from "../../Core/Service/ApiService";
import { 
    MDBModal,
    MDBModalDialog,
    MDBModalBody,
    MDBModalContent,
    MDBBtn,
} from "mdb-react-ui-kit";
import { IsChatSocketConnected, getChatSocket } from "../../Store/Reducer/socketReducer";
import { CHAT_WAIT_TIMEOUT, ChatStatus, RecvMessages, SendMessages, UserRole } from "../../Constants/constants";
import { IsChatCreated, memoizedGetChat, removeChat } from "../../Store/Reducer/chatReducer";
import { format_time_mm_ss } from "../../Utils/utils";
import ModalContext from "../../Context/ModalContext";

const AcceptChatModal = React.memo((props) => {
    const sockMsgHandlerKey = 'accept_chat_modal';
    const navigate = useNavigate();
    const modalContext = useContext(ModalContext);
    const setOpen = modalContext.setOpenAcceptChatModal;
    const isChatCreated = useSelector(IsChatCreated);
    const chat = useSelector(memoizedGetChat);
    const chatRef = useRef(chat);
    const authUser = useSelector(memoizedGetAuthUser);
    const [client, setClient] = useState(null);
    const chatSocket = useSelector(getChatSocket);
    const [remainTime, setRemainTime] = useState(CHAT_WAIT_TIMEOUT);
    const [errorMsg, setErrorMsg] = useState('');
    const dispatch = useDispatch();
    const timerRef = useRef(null);
    const openTimerRef = useRef(null);

    useEffect(()=>{
        chatRef.current = chat;
    }, [chat]);

    useEffect(()=>{
        const open = isChatCreated && chat?.status === ChatStatus.WAITING && authUser?.role === UserRole.ADVISOR;
        if(open) {
            setClient(chat.client);

            if(openTimerRef.current) {
                clearTimeout(openTimerRef.current);
                openTimerRef.current = null;
            }
            openTimerRef.current = setTimeout(()=>{
                modalContext.hideAllModals();
                setOpen(true);
                navigate('/advisor/dashboard');
            }, 500);
        } else {
            if(openTimerRef.current)             {
                clearTimeout(openTimerRef.current);
                openTimerRef.current = null;
            }
            openTimerRef.current = setTimeout(()=>{
                setClient(null);
                setOpen(false);
                setErrorMsg('');
                setRemainTime(CHAT_WAIT_TIMEOUT);
            }, 500);
        }
    }, [isChatCreated, chat?.status, authUser]);

    useEffect(() => {
        if(modalContext.openAcceptChatModal) {
            if(timerRef.current) clearInterval(timerRef.current);

            setRemainTime(Math.max(0, CHAT_WAIT_TIMEOUT - Math.floor((new Date().getTime() - chatRef.current?.createdAt) / 1000)));
            
            timerRef.current = setInterval(() => {
                const now = new Date().getTime();
                const remainTime = Math.max(0, CHAT_WAIT_TIMEOUT - Math.floor((now - chatRef.current?.createdAt) / 1000));
                setRemainTime(remainTime);

                if(remainTime == 0) {
                    clearInterval(timerRef.current);
                    timerRef.current = null;
                    dispatch(removeChat());
                } 
            }, 1000);

            if(chatSocket) {
                chatSocket.on(RecvMessages.CHAT_INFO, sockMsgHandlerKey, (chatInfo) => {
                    if(!chatInfo.client_isOnline) {
                        setErrorMsg(`Connection issue on the client's side`)
                    } else {
                        setErrorMsg('');
                    }
                });
                chatSocket.on(RecvMessages.PARTNER_UNAVAILABLE, sockMsgHandlerKey, () => {
                    setErrorMsg(`Connection issue on the client's side`)
                })
           
                return () => {
                    chatSocket.off(RecvMessages.CHAT_INFO, sockMsgHandlerKey);
                    chatSocket.off(RecvMessages.PARTNER_UNAVAILABLE, sockMsgHandlerKey);
                    if(timerRef.current) {
                        clearInterval(timerRef.current);
                        timerRef.current = null;
                    }
                };
            }
        }
    }, [modalContext.openAcceptChatModal, chatSocket]);

    const acceptChat = (e) => {
        e.preventDefault();
        chatSocket?.emit(SendMessages.ACCEPT_CHAT, ({
            chatId: chatRef.current?.chatId,
            advisor_time: new Date().getTime()
        }));

        // setOpen(false);      // don't close modal here, it would disappear when entering chat room.
    }

    const rejectChat = (e) => {
        e.preventDefault();
        
        if(window.confirm("Are you sure to reject this chat?") == false) return;

        chatSocket?.emit(SendMessages.REJECT_CHAT, ({
            chatId: chatRef.current?.chatId
        }));

        // dispatch(removeChat());      // we would remove chat upon receiving REJECT_CHAT message from the server
        // setOpen(false);
    }

    return (
        <MDBModal   className="accept-chat-modal" 
                    open={modalContext.openAcceptChatModal} 
                    setOpen={setOpen} 
                    closeOnEsc={false}
                    staticBackdrop 
                    tabIndex='-1' >
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalBody className='d-flex p-4 flex-column justify-content-center align-items-center'>
                        { client && <>
                            <img src={getAvatarUrl(client?.avatar)} className="client-avatar" />
                            <h5 className="mt-2">{ client?.username }</h5>
                            { !errorMsg && <p className="d-flex justify-content-center align-items-center">
                                Client is calling you...
                            </p>
                            }
                            { errorMsg && <p className="d-flex justify-content-center align-items-center error">
                                {errorMsg}
                            </p>
                            }

                            <div className="spent-time">{format_time_mm_ss(remainTime)}</div>
                            <div className="mt-4 action-bar">
                                <MDBBtn onClick={rejectChat} className="p-0" color="none" tag="a">
                                    <img src='/img/icons/decline.svg' alt='Reject Chat Request' title='Reject'/>
                                </MDBBtn>
                                <span className="mx-3"></span>
                                <MDBBtn onClick={acceptChat} className="p-0" color="none" tag="a">
                                    <img src='/img/icons/accept.svg' alt='Accept Chat Request' title='Accept'/>
                                </MDBBtn>
                            </div>

                        </>
                        }

                        {chatRef.current?.freeMinutes > 0 &&
                        <div className="coupon d-flex justify-content-center align-items-center" title="Free minutes">
                            {chatRef.current?.freeMinutes}
                        </div>
                        }
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
});

export default AcceptChatModal;
import React, { useEffect, useState } from "react";
import AdminTable from "../../../Components/AdminTable/AdminTable";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from './Opinion.scss';
import { MDBBtn, MDBCheckbox, MDBIcon, MDBInput, MDBSpinner, MDBSwitch, MDBTextArea } from "mdb-react-ui-kit";
import { getAvatarUrl, text2html } from "../../../Utils/utils";
import Moment from "react-moment";

const AdminOpinionEdit = React.memo((props) => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [opinion, setOpinion] = useState(null);

    useEffect(()=>{
        setLoading(true);

        ApiService.get('/admin/opinion/' + params._id).then(result=>{
            setOpinion(result.data.opinion);
        })
        .finally(()=>{
            setLoading(false);
        })
    }, [params._id]);

    const onReplyChanged = (e) => {
        setOpinion({
            ...opinion,
            reply: e.target.value
        })
    }

    const handleSave = (e) => {
        e.preventDefault();
        setSaving(true);

        const data = {
            _id: params._id,
            reply: opinion.reply
        }
        ApiService.post('/admin/opinion/reply', data).then(result=>{
            navigate(-1);
        })
        .finally(()=>{
            setSaving(false);
        })
    }

    const breadcrumb=[{title: 'Opinion', url: '/admin/opinion/list'}, {title: 'Edit'}];
    return (<>
        <Breadcrumb breadcrumb={breadcrumb} />
        <div className="d-flex justify-content-center">
            <div style={{width: 800, minWidth: '80%'}} className="admin-edit-opinion p-4">
                <div className="d-flex align-items-center">
                    <label>Received At</label>
                    <span className="mx-2">:</span>
                    <Moment format="YYYY-MM-DD HH:mm">{opinion?.createdAt}</Moment>
                </div>
                <div className="mt-4">
                    <label>Role</label>
                    <span className="mx-2">:</span>
                    <span>{opinion?.uId.role}</span>
                </div>
                <div className="d-flex align-items-center mt-4">
                    <label>User</label>
                    <span className="mx-2">:</span>
                    <img src={getAvatarUrl(opinion?.uId.avatar)} className="round-avatar-50 me-2" />
                    <span>{opinion?.uId.username}</span>
                </div>
                <div className="d-flex align-items-center mt-4">
                    <label>User Email</label>
                    <span className="mx-2">:</span>
                    <span>{opinion?.userEmail}</span>
                </div>
                <div className="mt-4 d-flex">
                    <label>Message</label>
                    <span className="mx-2">:</span>
                    <div dangerouslySetInnerHTML={{ __html: text2html(opinion?.content)}}></div>
                </div>
                <div className="mt-4">
                    <label>Reply :</label>
                    <MDBTextArea name='reply'
                        className='w-100 p-2'
                        value={opinion?.reply}
                        onChange={onReplyChanged}
                        rows={4}
                    />
                </div>
                <div className="d-flex justify-content-end mt-4">
                    <MDBBtn onClick={handleSave} disabled={saving}>
                        {saving && <>
                            <MDBSpinner role='status' size="sm" />
                            <span className="ms-1">Saving</span>
                        </>}
                        {!saving && <span>Save</span>}
                    </MDBBtn>
                    <MDBBtn color="default" className="ms-2" onClick={e=>navigate(-1)}>Back</MDBBtn>
                </div>
            </div>
            {loading && <LoadingIndicator /> }
        </div>
    </>)
});

export default AdminOpinionEdit;
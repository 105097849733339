import React from "react";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import { toast } from "react-toastify";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { useState } from "react";
import { useEffect } from "react";
import ApiService from "../../../Core/Service/ApiService";
import { MDBBtn, MDBCol, MDBInput, MDBRow, MDBSpinner } from "mdb-react-ui-kit";
import { validateForm } from "../../../Utils/utils";

const AdminSettingPayment = React.memo(props=>{
    const [inputErrors, setInputErrors] = useState({})
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [settings, setSettings] = useState([]);
    const [formData, setFormData] = useState({
        payment_confirm_days: 15,
        withdraw_min: 600,
        addfee_insufficient_withdraw: 5
    });
    const rules = {
        payment_confirm_days: {
            required: "number",
            min: 10,
            max: 20
        },
        withdraw_min: {
            required: 'number'
        },
        addfee_insufficient_withdraw: {
            required: 'number'
        }
    }

    useEffect(()=>{
        setLoading(true);
        ApiService.get('/admin/setting/index').then(result=>{
            const settings = result.data;
            if(settings.length > 0) {
                settings.forEach(setting=>{
                    const i = Object.keys(formData).findIndex(dk => dk == setting.key);
                    if(i >= 0) {
                        setFormData({
                            ...formData,
                            [setting.key] : parseInt(setting.value)
                        })
                    }
                })
            }
        })
        .finally(()=>{
            setLoading(false);
        })
    }, []);

    const onInputChanged = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const onSaveBtnClicked = (e) => {
        e.preventDefault();

        const valid_ret = validateForm(formData, rules);
        if(valid_ret.result) {
            setInputErrors({});

            setSaving(true);
            const data = {setting: formData}
            ApiService.post('/admin/setting/payment/save', data).then(result=>{
                toast.success("Payment method was saved successfully")
            })
            .finally(()=>{
                setSaving(false);
            })
        } else {
            setInputErrors(valid_ret.errors);
        }
    }

    const breadcrumb=[{title: 'Setting'}, {title: 'Payment'}];
    return (<>
        <Breadcrumb breadcrumb = {breadcrumb} />
        <div className="admin-payment-setting">
            <MDBCol lg={2} md={4}>
                <div>
                    <label>Payment Confirm Days</label>
                    <MDBInput className="mt-2"
                            name="payment_confirm_days"
                            type="number" 
                            min={10}
                            max={20}
                            value={formData.payment_confirm_days} 
                            onChange={onInputChanged} />
                    {inputErrors.payment_confirm_days && <div className="error">{ inputErrors.payment_confirm_days }</div> }
                </div>
                <div className="mt-3">
                    <label>Mininum withdraw</label>
                    <MDBInput className="mt-2"
                        name="withdraw_min"
                        type="number"
                        value={formData.withdraw_min}
                        onChange={onInputChanged}
                    />
                    {inputErrors.withdraw_min && <div className="error">{ inputErrors.withdraw_min }</div> }
                </div>
                <div className="mt-3">
                    <label>Additional fee for insufficient withdraw</label>
                    <MDBInput className="mt-2"
                        name="addfee_insufficient_withdraw"
                        type="number"
                        value={formData.addfee_insufficient_withdraw}
                        onChange={onInputChanged}
                    />
                    {inputErrors.addfee_insufficient_withdraw && <div className="error">{ inputErrors.addfee_insufficient_withdraw }</div> }
                </div>
            </MDBCol>

            <div className="d-flex justify-content-end mt-4">
                <MDBBtn onClick={onSaveBtnClicked}>
                    {!saving && <span>Save</span>}
                    {saving && <>
                        <MDBSpinner role='status' size="sm"/>
                        <span className="ms-1">Saving</span>
                    </>}
                </MDBBtn>
            </div>

            {loading && <LoadingIndicator /> }
        </div>
    </>
    )
});

export default AdminSettingPayment;